import { FC, useContext } from 'react';
import { Select, theme } from 'antd';
import { DownOutlined, EyeOutlined } from '@ant-design/icons';
import { FieldViewVisibilityDescription } from '../../../types/System.Parameters.types';
import { FormContext } from '../../../providers/artifacts/FormProvider';

const { useToken } = theme;

/** Select component for choosing form visibility mode */
const FormVisibilityModeSelect: FC = () => {
  const { token } = useToken();
  const { visibilityMode, setVisibilityMode } = useContext(FormContext);

  return (
    <Select
      value={visibilityMode}
      onChange={setVisibilityMode}
      style={{ 
        width: 150
      }}
      options={[
        {
          value: 'external',
          label: FieldViewVisibilityDescription.external.shortLabel,
          title: FieldViewVisibilityDescription.external.longDescription
        },
        {
          value: 'contractor',
          label: FieldViewVisibilityDescription.contractor.shortLabel,
          title: FieldViewVisibilityDescription.contractor.longDescription
        },
        {
          value: 'internal',
          label: FieldViewVisibilityDescription.internal.shortLabel,
          title: FieldViewVisibilityDescription.internal.longDescription
        },
        {
          value: 'designInput',
          label: FieldViewVisibilityDescription.designInput.shortLabel,
          title: FieldViewVisibilityDescription.designInput.longDescription
        },
        {
          value: 'designAlt1',
          label: FieldViewVisibilityDescription.designAlt1.shortLabel,
          title: FieldViewVisibilityDescription.designAlt1.longDescription
        },
        {
          value: 'designAlt2',
          label: FieldViewVisibilityDescription.designAlt2.shortLabel,
          title: FieldViewVisibilityDescription.designAlt2.longDescription
        },
        {
          value: 'designAlt3',
          label: FieldViewVisibilityDescription.designAlt3.shortLabel,
          title: FieldViewVisibilityDescription.designAlt3.longDescription
        }
      ]}
      labelRender={(props) => (
        <span style={{ pointerEvents: 'none' }}>
          {props.label}
        </span>
      )}
      prefix={<EyeOutlined style={{ pointerEvents: 'none' }} />}
      suffixIcon={<DownOutlined style={{ pointerEvents: 'none' }} />}
    />
  );
};

export default FormVisibilityModeSelect; 