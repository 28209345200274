import { useState, useCallback, useEffect } from 'react';
import { useBoundCollection } from './data/useBoundCollection';
import { Entity, EntityRecord, RecordGroup } from '../types/System.types';
import { useGetEntityGroupRecords } from './useGetEntityGroupRecords';

interface SelectOption {
    label: string;
    value: string;
    description?: string;
}

interface GroupOption {
    label: string;
    options: SelectOption[];
}

interface EntityGroupSelection {
    entityId: Entity['docId'];
    groupId: RecordGroup['groupId'] | null;
}

interface UseSelectEntityGroupProps {
    limit?: number;
}

interface UseSelectEntityGroupReturn {
    selection: EntityGroupSelection | null;
    setSelection: (value: EntityGroupSelection | null) => void;
    entities: Entity[] | null;
    loading: boolean;
    error: Error | null;
    groupOptions: GroupOption[];
    records: EntityRecord[] | null;
    recordsLoading: boolean;
}

export const useGetEntityGroups = ({
    limit: recordLimit = 30
}: UseSelectEntityGroupProps = {}): UseSelectEntityGroupReturn => {
    const [selection, setSelection] = useState<EntityGroupSelection | null>(null);
    const [records, setRecords] = useState<EntityRecord[] | null>(null);
    const [recordsLoading, setRecordsLoading] = useState(false);
    const { data: entities, loading, error } = useBoundCollection<Entity>({ path: 'entities' });
    const fetchRecords = useGetEntityGroupRecords({ entities, recordLimit });

    const getGroupOptions = useCallback((entities: Entity[] | null): GroupOption[] => {
        if (!entities) return [];

        // Create entity options
        const entityOptions = entities
            .map(entity => ({
                label: `${entity.description?.shortLabel || entity.docId || ''}`,
                value: JSON.stringify({ entityId: entity.docId, groupId: null }),
                description: entity.description?.shortDescription
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

        // Create group options organized by entity type
        const groupOptionsByEntity = entities
            .sort((a, b) => (a.description?.shortLabel || '').localeCompare(b.description?.shortLabel || ''))
            .reduce<GroupOption[]>((acc, entity) => {
                const entityGroups = Object.entries(entity.recordGroups || {})
                    .sort(([, groupA], [, groupB]) =>
                        (groupA.description.shortLabel || '').localeCompare(groupB.description.shortLabel || '')
                    )
                    .map(([groupId, group]) => ({
                        label: `${group.description.shortLabel}`,
                        value: JSON.stringify({ entityId: entity.docId, groupId }),
                        description: group.description.shortDescription
                    }));

                if (entityGroups.length === 0) return acc;

                acc.push({
                    label: `${entity.description?.shortLabel || entity.docId} Groups`,
                    options: entityGroups
                });
                return acc;
            }, []);

        return [
            {
                label: 'Entities',
                options: entityOptions
            },
            ...groupOptionsByEntity
        ];
    }, []);

    useEffect(() => {
        if (selection) {
            setRecordsLoading(true);
            fetchRecords(selection)
                .then(fetchedRecords => {
                    setRecords(fetchedRecords);
                })
                .catch(error => {
                    console.error('Error fetching records:', error);
                    setRecords(null);
                })
                .finally(() => {
                    setRecordsLoading(false);
                });
        } else {
            setRecords(null);
        }
    }, [selection, fetchRecords]);

    return {
        selection,
        setSelection,
        entities,
        loading,
        error,
        groupOptions: getGroupOptions(entities),
        records,
        recordsLoading
    };
}; 