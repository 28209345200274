import { FC, useState, useCallback } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TableColumnType } from '../../../types/System.types';
import FlexCol from '../../atoms/FlexCol';
import FlexBox from '../../atoms/FlexBox';
import { EmailRecord } from './email/emailParser';
import { EmailForm } from './email/EmailForm';
import { EmailTable } from './email/EmailTable';
import { EmailUploader } from './email/EmailUploader';
import { Timestamp } from 'firebase/firestore';

interface EmailListUIProps {
    /** Current value of the email list */
    value: EmailRecord[];
    /** Callback when email list changes */
    onChange: (value: EmailRecord[]) => void;
    /** Whether the field is read-only */
    disabled?: boolean;
    /** Table columns configuration */
    columns?: TableColumnType[];
    /** Additional props passed to the control */
    [key: string]: any;
}

const EmailListUI: FC<EmailListUIProps> = ({
    value,
    onChange,
    disabled = false,
    columns,
    ...props
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingRecord, setEditingRecord] = useState<EmailRecord | null>(null);

    const handleAddEmail = useCallback(() => {
        setEditingRecord(null);
        setIsModalOpen(true);
    }, []);

    const handleEditEmail = useCallback((record: EmailRecord) => {
        setEditingRecord(record);
        setIsModalOpen(true);
    }, []);

    const handleDeleteEmail = useCallback((record: EmailRecord) => {
        const newData = (value || []).filter(item => item.id !== record.id);
        onChange(newData);
    }, [value, onChange]);

    const handleEmailParsed = useCallback((emailData: EmailRecord) => {
        onChange([...(value || []), emailData]);
    }, [value, onChange]);

    const handleAttachmentsChange = useCallback((email: EmailRecord, attachments: string[]) => {
        const newData = [...(value || [])] as EmailRecord[];
        const index = newData.findIndex(item => item.id === email.id);
        if (index > -1) {
            newData[index] = {
                ...newData[index],
                attachments,
                hasAttachments: attachments?.length > 0
            };
            onChange(newData);
        }
    }, [value, onChange]);

    return (
        <FlexCol gap={10}>
            <EmailTable
                {...props}
                emails={value || []}
                columns={columns || []}
                disabled={disabled}
                onEdit={handleEditEmail}
                onDelete={handleDeleteEmail}
                onAttachmentsChange={handleAttachmentsChange}
            />
            {!disabled && (
                <FlexBox stretch gap={5}>
                    <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={handleAddEmail}
                        style={{ flex: 'none' }}
                    >
                        Add Email
                    </Button>
                    <div style={{ flex: 1 }}>
                        <EmailUploader
                            disabled={disabled}
                            onEmailParsed={handleEmailParsed}
                        />
                    </div>
                </FlexBox>
            )}
            <EmailForm
                open={isModalOpen}
                initialValues={editingRecord || undefined}
                onCancel={() => setIsModalOpen(false)}
                onSave={(formData) => {
                    const now = Timestamp.now();
                    const emailData: EmailRecord = {
                        id: editingRecord?.id || crypto.randomUUID(),
                        from: formData.from || '',
                        to: formData.to || [],
                        cc: formData.cc,
                        bcc: formData.bcc,
                        subject: formData.subject || '',
                        body: formData.body,
                        timestamp: editingRecord?.timestamp || now,
                        hasAttachments: editingRecord?.hasAttachments || false,
                        attachments: editingRecord?.attachments || [],
                        headers: editingRecord?.headers,
                        createdAt: editingRecord?.createdAt || now,
                        updatedAt: now
                    };

                    const newData = [...(value || [])] as EmailRecord[];
                    if (editingRecord) {
                        const index = newData.findIndex(item => item.id === editingRecord.id);
                        if (index > -1) {
                            newData[index] = emailData;
                        }
                    } else {
                        newData.push(emailData);
                    }

                    onChange(newData);
                    setIsModalOpen(false);
                }}
            />
        </FlexCol>
    );
};

export default EmailListUI; 