import { FC } from 'react';
import {
    Upload,
    Button,
    Modal,
    Divider,
    Typography,
    Progress,
    theme,
    message
} from 'antd';
import {
    UploadOutlined,
    DeleteOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import type { UploadProps } from 'antd/es/upload/interface';
import FlexCard from './FlexCard';
import FlexBox from './FlexBox';
import { useAttachments } from '../../use/useAttachments';
import { MAX_LIMITED_WIDTH_VIEWPORT_NARROW } from '../../types/System.Parameters.types';

const { Text } = Typography;
const { useToken } = theme;

/**
 * Props for the AttachmentUI component
 */
interface AttachmentUIProps {
    /** Array of attachment IDs */
    value?: string[];
    /** Callback triggered when attachments change */
    onChange?: (value: string[]) => void;
    /** Whether attachment operations are disabled */
    disabled?: boolean;
    /** Storage folder path */
    folder?: string;
    /** Maximum number of files that can be uploaded */
    maxCount?: number;
}

/**
 * Component for managing file attachments with Firebase storage integration.
 * Provides a UI for uploading, downloading, and deleting files with drag-and-drop support.
 */
export const AttachmentUI: FC<AttachmentUIProps> = ({
    value = [],
    onChange,
    disabled = false,
    folder = 'attachments',
}) => {
    const { token } = useToken();
    const [messageApi, contextHolder] = message.useMessage();

    /**
     * Displays success message
     * @param successMessage Message to display
     */
    const handleSuccess = (successMessage: string) => {
        messageApi.success(successMessage);
    };

    /**
     * Displays error message
     * @param error Error to display
     */
    const handleError = (error: Error) => {
        messageApi.error(error.message);
    };

    const {
        fileList,
        uploading,
        uploadProgress,
        isOverwriteModalOpen,
        isDeleteModalOpen,
        pendingUpload,
        pendingDelete,
        handleOverwriteConfirm,
        handleOverwriteCancel,
        handleDeleteConfirm,
        handleDeleteCancel,
        handleDelete,
        handleDownload,
        handleBatchFiles,
    } = useAttachments({
        value,
        onChange,
        disabled,
        folder,
        onSuccess: handleSuccess,
        onError: handleError
    });

    /**
     * Upload props for the Ant Design Upload.Dragger component
     */
    const uploadProps: UploadProps = {
        customRequest: () => { },
        fileList,
        multiple: true,
        disabled: disabled || uploading,
        listType: 'text',
        onRemove: () => false,
        directory: false,
        beforeUpload: (file, fileList) => {
            if (fileList.length === 1) {
                handleBatchFiles([file]);
            } else if (file === fileList[fileList.length - 1]) {
                handleBatchFiles(fileList);
            }
            return false;
        },
        progress: {
            strokeColor: {
                '0%': token.colorPrimary,
                '100%': token.colorSuccess,
            },
            strokeWidth: 2,
            format: (percent?: number) => `${parseFloat((percent || 0).toFixed(2))}%`,
            style: {
                width: '100%',
                top: 0,
                padding: '4px 0'
            }
        },
        style: {
            borderRadius: token.borderRadiusLG,
            width: '100%',
        },
        showUploadList: false
    };

    return (
        <FlexCard
            stretch
            growCard
            gap={0}
            style={{
                width: '100%',
                maxWidth: MAX_LIMITED_WIDTH_VIEWPORT_NARROW
            }}
            bodyStyle={{
                padding: 10
            }}
        >
            {contextHolder}
            <Upload.Dragger {...uploadProps}>
                <FlexBox
                    column
                    justifyCenter
                    gap={2}
                    style={{
                        margin: '-10px -5px'
                    }}
                >
                    <UploadOutlined style={{
                        fontSize: 30,
                        color: token.colorTextSecondary
                    }} />
                    <Text>
                        Click or drag file to this area to upload
                    </Text>
                    <Text type="secondary">
                        Support for single or bulk upload.
                    </Text>
                    {uploading && (
                        <Progress
                            percent={uploadProgress}
                            status="active"
                            strokeColor={{
                                '0%': token.colorPrimary,
                                '100%': token.colorSuccess,
                            }}
                        />
                    )}
                </FlexBox>
            </Upload.Dragger>
            {fileList.length > 0 && (
                <FlexBox column gap={0}>
                    <Divider style={{ margin: '12px 0' }} />
                    {fileList.map((file) => (
                        <FlexBox
                            key={file.uid}
                            stretch
                            style={{
                                padding: 5,
                                borderBottom: `1px solid ${token.colorBorderSecondary}`,
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            <FlexBox style={{ width: '100%' }}>
                                <Button
                                    icon={<DownloadOutlined />}
                                    type="link"
                                    onClick={() => file.uid && handleDownload(file.uid, file.name)}
                                    disabled={disabled || !file.uid}
                                    size="small"
                                >
                                    {file.name}
                                </Button>
                                <Button
                                    icon={<DeleteOutlined />}
                                    type="link"
                                    onClick={() => file.uid && handleDelete(file.uid, file.name)}
                                    disabled={disabled || !file.uid}
                                    danger
                                    size="small"
                                />
                            </FlexBox>
                        </FlexBox>
                    ))}
                </FlexBox>
            )}

            <Modal
                title="Files Already Exist"
                open={isOverwriteModalOpen}
                onOk={handleOverwriteConfirm}
                onCancel={handleOverwriteCancel}
                okText="Overwrite All"
                cancelText="Cancel Upload"
                confirmLoading={uploading}
            >
                <p>The following files already exist:</p>
                <div style={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    margin: '10px 0',
                    padding: 10,
                    backgroundColor: token.colorFillSecondary,
                    borderRadius: token.borderRadiusLG
                }}>
                    {pendingUpload?.existingFiles.map(({ newFile, existingFile }) => (
                        <div
                            key={newFile.name}
                            style={{
                                padding: '5px 0',
                                borderBottom: `1px solid ${token.colorBorderSecondary}`,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                            }}
                        >
                            <Text style={{ flex: 1 }}>{newFile.name}</Text>
                        </div>
                    ))}
                </div>
                <Text type="warning">
                    Would you like to overwrite these files with the new versions? This action cannot be undone.
                </Text>
            </Modal>

            <Modal
                title="Confirm Delete"
                open={isDeleteModalOpen}
                onOk={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                okText="Delete"
                cancelText="Cancel"
            >
                <p>Are you sure you want to delete "{pendingDelete?.fileName}"?</p>
                <p>This action cannot be undone.</p>
            </Modal>
        </FlexCard>
    );
}; 