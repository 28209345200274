import { FC, useState } from 'react';
import { Button, Dropdown, MenuProps, Tooltip, message } from 'antd';
import Icon from '../../../components/atoms/Icon';
import { useNavigate } from 'react-router-dom';
import { useFormTypes } from '../../../use/useFormTypes';
import { ALLOWED_ADD_MENU_FORM_TYPES } from '../../../types/System.Parameters.types';
import { createForm } from '../../../services/artifactFunctions';
import { devFlag } from '../../../config';

export interface FormCreateMenuProps {
  formTypes: ReturnType<typeof useFormTypes>;
  className?: string;
  style?: React.CSSProperties;
}

export const useFormCreateMenu = (formTypes: ReturnType<typeof useFormTypes>) => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const handleCreateForm = async (formTypeId: string) => {
    setIsCreating(true);
    try {
      const selectedFormType = Object.values(formTypes.data).find(
        (type) => type.docId === formTypeId
      );
      const newFormId = await createForm({
        formType: formTypeId,
        title: `New ${selectedFormType?.description?.shortLabel || 'Form'}`,
      });
      messageApi.success('New form created successfully');
      navigate(`/forms/${newFormId}`);
    } catch (error) {
      console.error('Error creating new form:', error);
      messageApi.error('Failed to create new form');
    } finally {
      setIsCreating(false);
    }
  };

  const createItems: MenuProps['items'] = [
    {
      type: 'group',
      label: `Create Form${devFlag && '✶'}`,
      children: Object.values(formTypes.data)
        .filter(formType => {
          // #### TODO REMOVE WHEN FORM ENTRY/EXIT RULES ARE IMPLEMENTED SYD-459
          if (devFlag) return true;
          return formType.docId && ALLOWED_ADD_MENU_FORM_TYPES.includes(formType.docId as any)
        }
        )
        .map(formType => ({
          key: formType.docId,
          label: (
            <Tooltip title={formType.description?.longDescription}>
              {formType.description?.shortLabel || 'Unnamed Form Type'}
            </Tooltip>
          ),
          onClick: () => handleCreateForm(formType.docId as any)
        } as Required<MenuProps>['items'][number]))
    },
  ];

  return {
    createItems,
    isCreating,
    handleCreateForm,
    contextHolder
  };
};

export const FormCreateMenu: FC<FormCreateMenuProps> = ({ formTypes, className, style }) => {
  const { createItems, isCreating, contextHolder } = useFormCreateMenu(formTypes);

  return (
    <>
      {contextHolder}
      <Dropdown
        menu={{ items: createItems }}
        trigger={['click']}
        disabled={formTypes.loading || formTypes?.data.length === 0 || isCreating}
        overlayStyle={{ width: 220 }}
      >
        <Button
          disabled={formTypes.loading || formTypes?.data.length === 0 || isCreating}
          type="primary"
          loading={isCreating}
          className={className}
          icon={<Icon.ChevronsDown />}
        >
          Workflow
        </Button>
      </Dropdown>
    </>
  );
};
