import { useState } from 'react';
import type { UploadFile, UploadFileStatus, RcFile } from 'antd/es/upload/interface';
import { useContextPaths } from '../useContextPaths';
import { attachmentStorageService } from './attachmentStorageService';
import { attachmentDbService } from './attachmentDbService';

/**
 * Represents a file upload pending user confirmation to overwrite existing files
 */
interface PendingUpload {
    /** New files to be uploaded */
    files: RcFile[];
    /** Files that already exist with the same name */
    existingFiles: {
        /** The new file being uploaded */
        newFile: RcFile;
        /** The existing file with the same name */
        existingFile: UploadFile;
    }[];
}

/**
 * Props for the useAttachmentUpload hook
 */
interface UseAttachmentUploadProps {
    /** Array of attachment IDs */
    value?: string[];
    /** Callback triggered when attachments change */
    onChange?: (value: string[]) => void;
    /** Storage folder path */
    folder?: string;
    /** Callback triggered on error */
    onError?: (error: Error) => void;
    /** Callback triggered on successful operation */
    onSuccess?: (message: string) => void;
}

/**
 * Hook for managing file uploads to Firebase Storage with attachment metadata in Firestore
 */
export const useAttachmentUpload = ({
    value = [],
    onChange,
    folder = 'attachments',
    onError,
    onSuccess
}: UseAttachmentUploadProps) => {
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isOverwriteModalOpen, setIsOverwriteModalOpen] = useState(false);
    const [pendingUpload, setPendingUpload] = useState<PendingUpload | null>(null);
    const [batchFiles, setBatchFiles] = useState<RcFile[]>([]);

    const { formVersionPath, entityRecordPath } = useContextPaths();

    /**
     * Uploads files to Firebase Storage and creates/updates Firestore documents
     * @param files Files to upload
     * @returns Array of upload results with file details
     */
    const performUpload = async (files: File[]) => {
        try {
            setUploading(true);
            setUploadProgress(0);
            const totalFiles = files.length;
            let completedFiles = 0;

            const uploadPromises = files.map(async (file) => {
                const docId = await attachmentDbService.createAttachmentDoc(file, formVersionPath, entityRecordPath);
                const { downloadURL, storagePath } = await attachmentStorageService.uploadToStorage(file, docId, folder);
                await attachmentDbService.updateAttachmentUrls(docId, downloadURL, storagePath);

                completedFiles++;
                setUploadProgress((completedFiles / totalFiles) * 100);

                return {
                    id: docId,
                    name: file.name,
                    type: file.type
                };
            });

            const results = await Promise.all(uploadPromises);
            const newAttachmentIds = results.map(result => result.id);
            const newUrls = [...value, ...newAttachmentIds];

            if (newUrls.length > 0) {
                onChange?.(newUrls);
            }

            onSuccess?.(`${totalFiles} file${totalFiles > 1 ? 's' : ''} uploaded successfully`);
            
            return results.map(({ id, name, type }) => ({
                uid: id,
                name: name,
                status: 'done' as UploadFileStatus,
                url: id,
                type: type
            }));
        } catch (error) {
            console.error('Upload failed:', error);
            onError?.(error instanceof Error ? error : new Error('Upload failed'));
            throw error;
        } finally {
            setUploading(false);
            setUploadProgress(0);
        }
    };

    /**
     * Checks for existing files with same filenames
     * @param filesToCheck New files to check
     * @param fileList Existing file list to check against
     * @returns Array of matches between new files and existing files
     */
    const checkExistingFiles = (filesToCheck: RcFile[], fileList: UploadFile[]) => {
        const existingFiles = filesToCheck
            .map(file => {
                const existing = fileList.find(f => f.name === file.name);
                return existing ? { newFile: file, existingFile: existing } : null;
            })
            .filter((item): item is { newFile: RcFile; existingFile: UploadFile } => item !== null);

        return existingFiles;
    };

    /**
     * Handles batch file upload, checking for existing files first
     * @param files Files to upload
     * @param fileList Current file list
     * @returns Upload results or null if upload didn't complete
     */
    const handleBatchUpload = async (files: RcFile[], fileList: UploadFile[]) => {
        try {
            const existingFiles = checkExistingFiles(files, fileList);

            if (existingFiles.length > 0) {
                setPendingUpload({
                    files,
                    existingFiles
                });
                setIsOverwriteModalOpen(true);
                return null;
            }

            return await performUpload(files);
        } catch (error) {
            console.error('Batch upload failed:', error);
            onError?.(error instanceof Error ? error : new Error('Failed to process upload'));
            return null;
        }
    };

    /**
     * Handles confirmation of overwriting existing files
     * @param fileList Current file list
     * @returns Object with new files and removed IDs, or null if operation failed
     */
    const handleOverwriteConfirm = async (fileList: UploadFile[]) => {
        setIsOverwriteModalOpen(false);
        if (!pendingUpload) return null;

        const { files, existingFiles } = pendingUpload;

        try {
            setUploading(true);

            const idsToRemove = existingFiles.map(item => item.existingFile.uid);

            for (const { existingFile } of existingFiles) {
                try {
                    const attachmentData = await attachmentDbService.getAttachmentDoc(existingFile.uid);
                    if (attachmentData.storagePath) {
                        await attachmentStorageService.deleteFromStorage(attachmentData.storagePath);
                    }
                    await attachmentDbService.deleteAttachmentDoc(existingFile.uid);
                } catch (error) {
                    console.error('Error deleting existing file:', error);
                }
            }

            const uploadResults = await performUpload(files);
            
            if (uploadResults) {
                const finalIds = value
                    .filter(id => !idsToRemove.includes(id))
                    .concat(uploadResults.map(item => item.uid));

                onChange?.(finalIds);

                return {
                    newFiles: uploadResults,
                    removedIds: idsToRemove
                };
            }
            
            return null;
        } catch (error) {
            console.error('Error handling overwrite:', error);
            onError?.(error instanceof Error ? error : new Error('Failed to overwrite files'));
            return null;
        } finally {
            setUploading(false);
            setPendingUpload(null);
        }
    };

    /**
     * Handles cancellation of overwrite operation
     */
    const handleOverwriteCancel = () => {
        setIsOverwriteModalOpen(false);
        setPendingUpload(null);
    };

    return {
        uploading,
        uploadProgress,
        isOverwriteModalOpen,
        pendingUpload,
        handleOverwriteConfirm,
        handleOverwriteCancel,
        handleBatchUpload,
        handleBatchFiles: setBatchFiles,
        batchFiles
    };
}; 