import { FC } from 'react';
import { Typography, Divider } from 'antd';
import dayjs from 'dayjs';
import { EmailRecord } from './emailParser';
import FlexCol from '../../../atoms/FlexCol';

const { Text } = Typography;

interface EmailDetailsProps {
    /** Email record to display */
    email: EmailRecord;
    /** Whether the component is disabled */
    disabled?: boolean;
    /** Callback when attachments are changed */
    onAttachmentsChange?: (attachments: string[]) => void;
}

export const EmailDetails: FC<EmailDetailsProps> = ({
    email,
    disabled = false,
    onAttachmentsChange
}) => {
    return (
        <FlexCol style={{ padding: 10 }} gap={0}>
            <Text>
                <strong>From: </strong>{email.from}
            </Text>
            <Text>
                <strong>To: </strong>{email.to.join(', ')}
            </Text>
            {email.cc && email.cc.length > 0 && (
                <Text>
                    <strong>CC: </strong>{email.cc.join(', ')}
                </Text>
            )}
            {email.bcc && email.bcc.length > 0 && (
                <Text>
                    <strong>BCC: </strong>{email.bcc.join(', ')}
                </Text>
            )}
            <Text>
                <strong>Subject: </strong>{email.subject}
            </Text>
            <Text>
                <strong>Date: </strong>{dayjs(email.timestamp.toDate()).format('YYYY-MM-DD HH:mm')}
            </Text>
            {email.body && (
                <>
                    <Divider style={{ margin: '10px 0' }} />
                    <Typography.Paragraph
                        style={{
                            whiteSpace: 'pre-wrap',
                            fontFamily: 'monospace',
                        }}
                    >
                        <small>
                            {email.body}
                        </small>
                    </Typography.Paragraph>
                </>
            )}
        </FlexCol>
    );
}; 