import React, { CSSProperties, FC, ReactNode, HTMLAttributes } from 'react';

export interface FlexBoxProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  style?: CSSProperties;
  column?: boolean;
  wrap?: boolean;
  justifyStart?: boolean;
  justifyEnd?: boolean;
  justifyCenter?: boolean;
  alignStart?: boolean;
  alignEnd?: boolean;
  noGrow?: boolean;
  stretch?: boolean;
  gap?: number;
  padding?: string | number;
}

const FlexBox: FC<FlexBoxProps> = ({
  children,
  style,
  column = false,
  wrap = false,
  justifyStart = false,
  justifyEnd = false,
  justifyCenter = false,
  alignStart = false,
  alignEnd = false,
  noGrow = false,
  stretch = false,
  gap,
  padding,
  ...props
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: column ? 'column' : 'row',
        justifyContent: justifyStart
          ? 'flex-start'
          : justifyEnd
          ? 'flex-end'
          : justifyCenter
          ? 'center'
          : 'space-between',
        alignItems: alignStart ? 'flex-start' : alignEnd ? 'flex-end' : 'center', 
        flexWrap: wrap ? 'wrap' : 'nowrap',
        flexGrow: noGrow ? 0 : 1,
        alignSelf: stretch ? 'stretch' : undefined,
        gap: gap === 0 ? 0 : gap || 10,
        padding: padding,
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default FlexBox;