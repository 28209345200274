import React, { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import { ConfigProvider, Segmented, theme as antdTheme } from 'antd';

import { UserContext, UserContextType } from './UserProvider'; 

const COLORS = {
  PRIMARY: {
    LIGHT: '#F25921',
    DARK: '#F25921',
  },
  PRIMARY_L: {
    LIGHT: '#FFE3D9',
    DARK: '#310E02',
  },
  PRIMARY_XL: {
    LIGHT: '#FFEBE0',
    DARK: '#00150C',
  },
  BLACK_ALPHA: {
    P85: 'rgba(0, 0, 0, 0.85)',
    P68: 'rgba(0, 0, 0, 0.68)',
    P60: 'rgba(0, 0, 0, 0.6)',
  },
  WHITE_ALPHA: {
    P95: 'rgba(255, 255, 255, 0.95)',
    P85: 'rgba(255, 255, 255, 0.85)',
    P68: 'rgba(255, 255, 255, 0.68)',
  },
  TRANSPARENT_BG: {
    LIGHT: 'rgba(252,252,252,0.75)',
    DARK: 'rgba(50,50,50,0.75)',
  },
  FIELD_BG: {
    LIGHT: '#FFFFFF',
    DARK: '#212121',
  },
  FIELD_SELECT: {
    LIGHT: '#EBEBEB',
    DARK: '#3A3A3A',
  },
  SOLID: {
    RED: '#FF1010',
  }
};

export interface ThemeContextType {
  darkMode: boolean;
}

export const ThemeContext = createContext<ThemeContextType>({
  darkMode: false,
});

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { settings } = useContext<UserContextType>(UserContext);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const preferDarkMode = settings?.preferDarkMode || 'system';

  useEffect(() => {
    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    const onChange = (e: MediaQueryListEvent) => {
      if (preferDarkMode === 'system') {
        setDarkMode(e.matches);
      }
    };

    matcher.addEventListener('change', onChange);

    if (preferDarkMode === 'dark') {
      setDarkMode(true);
    } else if (preferDarkMode === 'light') {
      setDarkMode(false);
    } else if (preferDarkMode === 'system') {
      setDarkMode(matcher.matches);
    }

    return () => matcher.removeEventListener('change', onChange);
  }, [preferDarkMode]);

  const currentTheme = {
    ...(darkMode ? { algorithm: antdTheme.darkAlgorithm } : {}),
    token: {
      fontFamily: "'Work Sans', sans-serif",
      colorPrimary: darkMode ? COLORS.PRIMARY.DARK : COLORS.PRIMARY.LIGHT,
      colorPrimaryBorder: darkMode ? COLORS.PRIMARY.DARK : COLORS.PRIMARY.LIGHT,
      colorInfo: darkMode ? COLORS.PRIMARY.DARK : COLORS.PRIMARY.LIGHT,
      colorFillTertiary: darkMode ? COLORS.FIELD_BG.DARK : COLORS.FIELD_BG.LIGHT,
      fontSize: 16,
      fontSizeHEading3: 28,
      fontSizeHeading4: 24,
      fontSizeHeading5: 20,
      lineHeightHeading5: 1,
      borderRadius: 4,
      borderRadiusLG: 4,
      fontWeightStrong: 500,
      customBgTransparent: darkMode ? COLORS.TRANSPARENT_BG.DARK : COLORS.TRANSPARENT_BG.LIGHT,
      colorTextDisabled: darkMode ? COLORS.WHITE_ALPHA.P68 : COLORS.BLACK_ALPHA.P68,
      colorLinkBgHover: darkMode ? COLORS.PRIMARY.LIGHT : COLORS.PRIMARY.DARK,
      colorBgHover: darkMode ? COLORS.PRIMARY.LIGHT : COLORS.PRIMARY.DARK,
      colorPrimaryBgHover: darkMode ? COLORS.PRIMARY.LIGHT : COLORS.PRIMARY.DARK,
      colorError: COLORS.SOLID.RED,
    },
    components: {
      Button: {
        controlTmpOutline: COLORS.SOLID.RED,
        controlOutlineWidth: 0,
        boxShadowTertiary: "none",
        colorBgContainer: darkMode ? COLORS.PRIMARY_L.DARK : COLORS.PRIMARY_L.LIGHT,
        defaultColor: darkMode ? COLORS.PRIMARY.DARK : COLORS.PRIMARY.LIGHT,
        colorText: darkMode ? COLORS.WHITE_ALPHA.P85 : COLORS.BLACK_ALPHA.P85,
        colorBorder: darkMode ? COLORS.PRIMARY.DARK : COLORS.PRIMARY.LIGHT,
        borderColorDisabled: darkMode ? COLORS.FIELD_SELECT.DARK : COLORS.FIELD_SELECT.LIGHT,
        linkHoverBg: darkMode ? COLORS.PRIMARY_L.DARK : COLORS.PRIMARY_L.LIGHT,
        colorTextLightSolid: darkMode ? COLORS.BLACK_ALPHA.P60 : COLORS.WHITE_ALPHA.P95,
        colorTextDefault: darkMode ? COLORS.WHITE_ALPHA.P95 : COLORS.BLACK_ALPHA.P85,
        fontSizeSM: 12,
      },
      Segmented: {
        itemSelectedBg: darkMode ? COLORS.FIELD_SELECT.DARK : COLORS.FIELD_SELECT.LIGHT,
      },
      Radio: {
        buttonCheckedBg: darkMode ? COLORS.PRIMARY_XL.DARK : COLORS.PRIMARY_XL.LIGHT,
      },
      Flex: {
        wrap: 'flex-wrap',
      },
      Form: {
        verticalLabelPadding: 0,
        itemMarginBottom: 10
      },
      Cascader: {
        dropdownHeight: 400
      },
    },
  };

  return (
    <ThemeContext.Provider value={{ darkMode }}>
      <ConfigProvider theme={ currentTheme }>
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};


        
      