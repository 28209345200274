import { FC, useContext, useRef } from 'react';
import { Result, Button, Skeleton, Divider } from 'antd';

import FlexBox from '../components/atoms/FlexBox';
import FormUI from '../components/rendering/FormUI';
import FormControl from '../components/artifacts/FormControl';

import { FormContext } from '../providers/artifacts/FormProvider';
import { SearchWrapper } from '../components/SearchWrapper';

export const FormPageContent: FC = () => {
	const contentRef = useRef<HTMLDivElement>(null);

	const {
		selectedForm,
	} = useContext(FormContext);

	if (!selectedForm.data) {
		return <Skeleton active />;
	}

	if (selectedForm.error) {
		return (
			<Result
				status="404"
				title="Form Not Found"
				subTitle="The requested form does not exist or you don't have permission to view it."
				extra={<Button type="primary" onClick={() => window.history.back()}>Go Back</Button>}
			/>
		);
	}
	
	return (
		<FlexBox column alignStart justifyStart noGrow stretch>
			<FormControl
				contentRef={contentRef}
			/>
			<Divider style={{ margin: 0 }} />
			<SearchWrapper index="entityRecord">
				<FormUI contentRef={contentRef} />
			</SearchWrapper>
		</FlexBox>
	);
};