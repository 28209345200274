import { useContext, useMemo, useCallback, useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Timestamp, getFirestore, doc, setDoc } from 'firebase/firestore';
import { UserContext } from '../providers/UserProvider';
import { FormContext } from '../providers/artifacts/FormProvider';
import { FormVersionContext } from '../providers/artifacts/FormVersionProvider';
import { EntityContext } from '../providers/artifacts/EntityProvider';
import { EntityRecordContext } from '../providers/artifacts/EntityRecordProvider';
import { AgentType, UIField, fieldType, ValidationStatus } from '../types/System.types';

/**
 * Options interface for the useFieldUI hook
 * @interface UseFieldUIOptions
 * @template T - The type of value managed by the field
 */
interface UseFieldUIOptions<T> {
  /** The UI field configuration object containing field properties and metadata */
  uiField: UIField;
}

/**
 * Hook for managing field UI state, validation, and persistence in forms and entity records.
 * Provides real-time field value management with debounced saving to Firestore.
 *
 * @template T - The type of value managed by the field
 * @param {UseFieldUIOptions<T>} options - Configuration options for the field UI
 * @returns {Object} Field state and management functions
 * @property {boolean} hasContext - Whether the field has valid form/entity context
 * @property {boolean} isReadOnly - Whether the field is in read-only mode
 * @property {Object} fieldData - Current field data from Firestore
 * @property {T | null} value - Current field value
 * @property {T | null} persistedValue - Last successfully persisted value
 * @property {(newValue: T) => void} setValue - Function to update field value
 * @property {boolean} isSaving - Whether the field is currently saving
 * @property {ValidationStatus} validationStatus - Current validation status
 * @property {Object} props - Field type specific properties
 * @property {string} errorMessage - Error message for validation failures
 * @property {string} visibilityMode - Current visibility mode
 * @property {() => boolean} shouldShowField - Function determining if field should be visible
 * @property {string} label - Field display label
 * @property {string} description - Field description
 */
export function useFieldUI<T>({ uiField }: UseFieldUIOptions<T>) {
  const { user } = useContext(UserContext);
  const formContext = useContext(FormContext);
  const formVersionContext = useContext(FormVersionContext);
  const entityContext = useContext(EntityContext);
  const entityRecordContext = useContext(EntityRecordContext);

  const [value, setValueInternal] = useState<T | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>(undefined);

  const dirtyRef = useRef<boolean>(false);
  const persistedValueRef = useRef<T | null>(null);
  const lastExternalValue = useRef<T | null>(null);

  const insideForm = formContext?.insideFormContext ?? false;
  const insideEntity = entityContext?.insideEntityContext ?? false;

  const isReadOnly = insideEntity
    ? !entityContext?.hasEntityAccess || entityContext?.entityLocked
    : !formVersionContext?.formVersionAccess?.canWrite;

  const fieldData = insideEntity
    ? entityRecordContext?.selectedEntityRecord?.fields?.[uiField.fieldId]
    : formVersionContext?.selectedFormVersion?.data?.fields?.[uiField.fieldId];

  const { visibilityMode } = formContext ?? {};
  const ft = fieldType[uiField.fieldTypeId];

  const label = ft?.description?.shortLabel || uiField.fieldId;
  const description = ft?.description?.longDescription ||
    ft?.description?.shortDescription ||
    ft?.description?.longLabel ||
    ft?.description?.shortLabel;

  const externalValue = fieldData?.value ?? null;

  const entityId = entityContext?.selectedEntity?.docId;
  const recordId = entityRecordContext?.selectedEntityRecord?.docId;
  const formId = formContext?.selectedForm?.data?.docId;
  const versionId = formVersionContext?.selectedFormVersion?.data?.docId;

  const debouncedSave = useMemo(() =>
    debounce(async (newValue: T) => {
      try {
        const db = getFirestore();
        const now = Timestamp.now();

        const docRef = insideForm && formId && versionId
          ? doc(db, 'forms', formId, 'formVersions', versionId)
          : insideEntity && entityId && recordId
            ? doc(db, 'entities', entityId, 'records', recordId)
            : null;

        if (!docRef) {
          throw new Error('Missing required context for saving field');
        }

        const updateData = {
          fields: {
            [uiField.fieldId]: {
              value: newValue,
              meta: {
                lastModified: now,
                userId: user?.uid,
                agentType: AgentType.User,
              },
            },
          },
          meta: {
            lastModified: now,
            userId: user?.uid,
            agentType: AgentType.User,
          },
        };

        await setDoc(
            docRef, 
            updateData, 
            { merge: true }
        );
        persistedValueRef.current = newValue;
        setIsSaving(false);
        dirtyRef.current = false;
      } catch (error) {
        console.error('Error saving field:', error);
        setValidationStatus('error');
        setIsSaving(false);
      }
    }, 600),
  [insideEntity, insideForm, entityId, recordId, formId, versionId, uiField.fieldId, user?.uid]);

  useEffect(() => {
    if (!dirtyRef.current && externalValue !== lastExternalValue.current) {
      lastExternalValue.current = externalValue;
      setValueInternal(externalValue);
      setIsSaving(false);
    }
  }, [externalValue]);

  useEffect(() => {
    return () => { debouncedSave.cancel(); };
  }, [debouncedSave]);

  const setValue = useCallback((newValue: T) => {
    setValueInternal(newValue);
    dirtyRef.current = true;
    setIsSaving(true);
    const isValid = ft?.validation ? ft.validation(newValue) : true;
    setValidationStatus(isValid ? undefined : 'error');
    if (!isValid) {
        debouncedSave.cancel();
        setIsSaving(false);
        return;
    }
    
    debouncedSave(newValue);
  }, [debouncedSave, ft]);

  const shouldShowField = () => {
    if (!ft?.visibility || ft.visibility.length === 0) return true;
    if (visibilityMode === 'internal' || visibilityMode === undefined) return true;
    return ft?.visibility?.includes(visibilityMode) ?? true;
  };

  if (!insideForm && !insideEntity) {
    return {
      hasContext: false,
      fieldData: "Neither FormContext nor EntityContext is available.",
      value: null,
      setValue: () => {},
    };
  }

  return {
    hasContext: insideEntity || insideForm,
    isReadOnly,
    fieldData,
    value,
    persistedValue: persistedValueRef.current,
    setValue,
    isSaving,
    validationStatus,
    props: ft?.props ?? {},
    errorMessage: ft?.errorMessage ?? '',
    visibilityMode,
    shouldShowField,
    label,
    description,
  };
}
