import { FC } from 'react';
import { Select, Segmented, Space, theme, Grid } from 'antd';
import { CheckSquareOutlined, ClearOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import EntityGroupSelector from '../EntityGroupSelector';
import FlexBox from '../../atoms/FlexBox';
import FlexCol from '../../atoms/FlexCol';
import { FormCreateMenu } from '../form/FormCreateMenu';
import { DatePickerWithPresets } from '../../atoms/DatePickerWithPresets';
import { useFormTypes } from '../../../use/useFormTypes';
import { FormViewMode } from '../../../use/useFormviews';
import { formStateDescription } from '../../../types/System.Parameters.types';

const { useToken } = theme;

interface FormviewsControlsProps {
  filters: {
    entitySelection: any;
    dateRange: [dayjs.Dayjs, dayjs.Dayjs];
    selectedFormTypes: string[];
    selectedFormStates: string[];
    viewMode: FormViewMode;
  };
  filterActions: {
    handleEntitySelectionChange: (selection: any) => void;
    handleDateFilterChange: (dates: any) => void;
    handleTypeFilterChange: (values: string[]) => void;
    handleStateFilterChange: (values: string[]) => void;
    handleViewModeChange: (mode: FormViewMode) => void;
  };
  isLoading: boolean;
  utils: {
    isAllFormsSelected: boolean;
    isAllStatesSelected: boolean;
  };
}

/**
 * Controls component for filtering form views
 */
export const FormviewsControls: FC<FormviewsControlsProps> = ({
  filters,
  filterActions,
  isLoading,
  utils
}) => {
  const { token } = useToken();
  const { useBreakpoint } = Grid;
  const screen = useBreakpoint();
  const formTypes = useFormTypes();

  return (
    <FlexBox noGrow alignStart style={{ width: '100%' }}>
      <FlexBox noGrow justifyStart wrap>
        <EntityGroupSelector
          value={filters.entitySelection}
          onChange={filterActions.handleEntitySelectionChange}
          style={{ width: 150 }}
        />
        <Segmented
          value={filters.viewMode}
          onChange={(value) => filterActions.handleViewModeChange(value as FormViewMode)}
          options={[
            {
              label: `Assigned`,
              value: 'assigned'
            },
            {
              label: `Subscribed`,
              value: 'subscribed'
            },
            {
              label: `All`,
              value: 'all'
            },
          ]}
          disabled={isLoading}
        />
        <DatePickerWithPresets
          value={filters.dateRange}
          onChange={filterActions.handleDateFilterChange}
          style={{ width: 245 }}
          size="middle"
          format={screen.sm ? 'YY-MMM-DD' : 'YY-MMM-DD'}
        />
        <Space.Compact>
          <Select
            placeholder="Form Types"
            mode="multiple"
            style={{ width: 150, maxHeight: 32 }}
            dropdownStyle={{ width: 320 }}
            options={[
              {
                label: (
                  <Space style={{ color: token.colorPrimary }}>
                    <CheckSquareOutlined />
                    Select All
                  </Space>
                ),
                value: '__select_all__'
              },
              {
                label: (
                  <Space style={{ color: token.colorPrimary }}>
                    <ClearOutlined />
                    Clear All
                  </Space>
                ),
                value: '__clear_all__'
              },
              ...formTypes.data?.map((formType) => ({
                label: formType.description?.shortLabel || formType.docId || '',
                value: formType.docId || '',
              })) || []
            ]}
            value={filters.selectedFormTypes}
            maxTagCount={0}
            maxTagPlaceholder={utils.isAllFormsSelected ? 'All Types' : `${filters.selectedFormTypes.length} types`}
            onChange={filterActions.handleTypeFilterChange}
            loading={formTypes.loading}
            disabled={isLoading}
            allowClear
          />
          <Select
            placeholder="Form States"
            mode="multiple"
            style={{ width: 150, maxHeight: 32 }}
            dropdownStyle={{ width: 320 }}
            options={[
              {
                label: (
                  <Space style={{ color: token.colorPrimary }}>
                    <CheckSquareOutlined />
                    Select All
                  </Space>
                ),
                value: '__select_all__'
              },
              {
                label: (
                  <Space style={{ color: token.colorPrimary }}>
                    <ClearOutlined />
                    Clear All
                  </Space>
                ),
                value: '__clear_all__'
              },
              ...Object.entries(formStateDescription).map(
                ([state, desc]) => ({
                  label: desc.shortLabel || state,
                  value: state,
                  description: desc.longDescription,
                })
              )
            ]}
            value={filters.selectedFormStates}
            maxTagCount={0}
            maxTagPlaceholder={utils.isAllStatesSelected ? 'All States' : `${filters.selectedFormStates.length} states`}
            onChange={filterActions.handleStateFilterChange}
            loading={isLoading}
            disabled={isLoading}
            allowClear
          />
        </Space.Compact>
      </FlexBox>
      <FlexCol alignEnd>
        <FormCreateMenu formTypes={formTypes} />
      </FlexCol>
    </FlexBox>
  );
}; 