import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { UserContext } from './UserProvider';
import { callRunLanguageModel } from '../services/cloudRun';
import { 
    LanguageModelRequest, 
    LanguageModelTask,
    LanguageModelTaskResult 
} from '../types/LanguageModel.types';

interface ChatMessage {
    id: string;
    sender: string;
    content: string | null;
    timestamp: Date | null;
}

export interface AIContextType {
    messages: ChatMessage[];
    addMessage: (message: ChatMessage) => void;
    clearMessages: () => void;
    callModel: (inputValue: string) => Promise<void>;
    streamModel: (inputValue: string) => Promise<void>;
    inputPrompt: string;
    setInputPrompt: React.Dispatch<React.SetStateAction<string>>;
    activeMessageContent: string | null;
    setActiveMessageContent: React.Dispatch<React.SetStateAction<string | null>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    formId?: string;
    versionId?: string;
    lastTaskResult?: LanguageModelTaskResult;
}

const defaultMessage: ChatMessage = {
    id: 'welcome_1',
    sender: 'model',
    content: 'Hello. How can I help you today?',
    timestamp: new Date(),
};

export const AIContext = createContext<AIContextType>({
    messages: [],
    addMessage: () => {},
    clearMessages: () => {},
    callModel: async () => {},
    streamModel: async () => {},
    inputPrompt: '',
    setInputPrompt: () => {},
    activeMessageContent: null,
    setActiveMessageContent: () => {},
    isLoading: false,
    setIsLoading: () => {},
});

export const AIProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { settings } = useContext(UserContext);
    const {
      formId, 
      versionId
    } = useParams<{ formId: string; versionId: string }>();

    const [messages, setMessages] = useState<ChatMessage[]>([defaultMessage]);
    const [inputPrompt, setInputPrompt] = useState('');
    const [activeMessageContent, setActiveMessageContent] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [lastTaskResult, setLastTaskResult] = useState<LanguageModelTaskResult>();

    const addMessage = (message: ChatMessage) => {
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    const clearMessages = () => {
        setMessages([defaultMessage]);
    };

    const handleTaskResponse = (result?: LanguageModelTaskResult) => {
        if (!result) return;
        
        setLastTaskResult(result);
        addMessage({
            id: `task_${new Date().toISOString()}`,
            sender: 'model',
            content: result.success ? 
                'Form updated successfully' : 
                `Error updating form: ${result.error}`,
            timestamp: new Date(),
        });
    };

    const callFormUpdate = async (prompt: string): Promise<void> => {
        if (!formId) {
            throw new Error('No form ID available');
        }

        const task: LanguageModelTask = {
            taskType: 'updateFormVersion',
            target: {
                formId,
                formVersionId: versionId
            },
            meta: {
                description: {
                    shortDescription: prompt
                }
            }
        };
        await callRunLanguageModel(
            task,
            undefined,
            (error) => {
                console.error('Form update error:', error);
                addMessage({
                    id: `error_${new Date().toISOString()}`,
                    sender: 'model',
                    content: `Error: ${error.message}`,
                    timestamp: new Date(),
                });
            },
            handleTaskResponse
        );
    };

    const streamModel = async (inputValue: string) => {
        try {
            setActiveMessageContent('');
            let response = '';

            addMessage({
                id: `user_${new Date().toISOString()}`,
                sender: 'user',
                content: inputValue,
                timestamp: new Date(),
            });

            if (formId) {
                await callFormUpdate(inputValue);
                return;
            }

            // Regular streaming chat
            const request: LanguageModelRequest = {
                prompt: inputValue
            };

            await callRunLanguageModel(
                request,
                (chunk) => {
                    response += chunk;
                    setActiveMessageContent(response);
                },
                (error) => {
                    console.error('Error streaming from the language model:', error);
                    addMessage({
                        id: `error_${new Date().toISOString()}`,
                        sender: 'model',
                        content: `Error: ${error.message}`,
                        timestamp: new Date(),
                    });
                },
                () => {
                    addMessage({
                        id: `stream_end_${new Date().toISOString()}`,
                        sender: 'model',
                        content: response,
                        timestamp: new Date(),
                    });
                    setActiveMessageContent('');
                }
            );
        } catch (error) {
            console.error('Error in streamModel:', error);
            throw error;
        }
    };

    return (
        <AIContext.Provider value={{
            messages,
            addMessage,
            clearMessages,
            callModel: streamModel,
            streamModel,
            inputPrompt,
            setInputPrompt,
            activeMessageContent,
            setActiveMessageContent,
            isLoading,
            setIsLoading,
            formId,
            versionId,
            lastTaskResult
        }}>
            {children}
        </AIContext.Provider>
    );
};
