/*
  This file defines the service for analyzing an email using a Firebase callable function. 
  We define TypeScript interfaces for the request and response to ensure type safety.
*/

import { httpsCallable } from 'firebase/functions';
import { functions } from './firebaseFunctions';

// Define the allowed Form Types
export type FormType = 'Design' | 'Quote' | 'Buy' | 'HFR' | 'Order' | 'Reconciliation';

// Request interface for email analysis
export interface EmailAnalysisRequest {
  emailText: string;
  messageId?: string;          // Raw Message-ID header
  inReplyToId?: string;       // Raw In-Reply-To header
  referenceIds?: string[];    // Raw References header as array
}

// Interface for intent information returned by the LLM
export interface IntentInfo {
  value: string;
  confidence: number;
  formType: FormType;
}

// Placeholder for email thread structure (should conform to email-structure.json)
export interface EmailThread {
  // Define properties as needed; using any for now until schema is finalized
  [key: string]: any;
}

// Interface for the parsed email analysis response
export interface ParsedEmailAnalysis {
  parsedEmail: EmailThread; // The structured email matching our schema
  intent: IntentInfo;       // Detected intent with confidence and mapped FormType
  actionItems?: any[];      // Optional action items identified in the email
}

// The full response structure from the analyzeEmail Firebase callable function
export interface EmailAnalysisResponse {
  parsedAnalysis: ParsedEmailAnalysis;
  // Additional usage details can be added here
}

// Main function to call the analyzeEmail Firebase callable function
export async function callAnalyzeEmail(request: EmailAnalysisRequest): Promise<EmailAnalysisResponse> {
  try {
    const analyzeEmailCallable = httpsCallable<EmailAnalysisRequest, EmailAnalysisResponse>(functions, 'analyzeEmail');
    const result = await analyzeEmailCallable(request);
    // Return the data from the callable function
    return result.data;
  } catch (error: any) {
    console.error('Error calling analyzeEmail:', error);
    // Propagate a meaningful error message
    throw new Error(error.message || 'Error analyzing email');
  }
} 