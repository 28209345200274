import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate 
} from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import ErrorPage from './pages/ErrorPage';
import PasswordResetPage from './pages/PasswordResetPage';
import FormsPage from './pages/FormsPage';
import SearchPage from './pages/SearchPage';
import EntitiesPage from './pages/EntitiesPage';
import FormPage from './pages/FormPage'; 
import AnalyticsPage from './pages/AnalyticsPage';
import SettingsPage from './pages/SettingsPage';
import NotificationsPage from './pages/NotificationsPage';
import HomePage from './pages/HomePage';
import EmailAnalyzerPage from './pages/EmailAnalyzerPage';

import ProtectedRoute from './components/ProtectedRoute';

/**
 * Main application component
 * 
 * This component is the root of the application and contains the main routes.
 * It uses the Router component from react-router-dom to manage the routes.
 * 
 */
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<LoginPage />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/password-reset"
          element={<PasswordResetPage />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/forms"
          element={
            <ProtectedRoute>
              <FormsPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/forms/:formId"
          element={
            <ProtectedRoute>
              <FormPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/entities"
          element={
            <ProtectedRoute>
              <EntitiesPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/forms/:formId/versions/:versionId"
          element={
            <ProtectedRoute>
              <FormPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/entities/:entityId"
          element={
            <ProtectedRoute>
              <EntitiesPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/entities/:entityId/records/:recordId"
          element={
            <ProtectedRoute>
              <EntitiesPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/email-analyzer"
          element={
            <ProtectedRoute>
              <EmailAnalyzerPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/search"
          element={
            <ProtectedRoute>
              <SearchPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/analytics"
          element={
            <ProtectedRoute>
              <AnalyticsPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <NotificationsPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="/settings/*"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="*"
          element={
            <Navigate to="/" />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;