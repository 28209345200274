import { FC, useState, useContext, useEffect } from 'react';
import { Select, message, Segmented, Grid } from 'antd';
import { WhereFilterOp } from 'firebase/firestore';
import FlexBox from '../atoms/FlexBox';
import FlexCol from '../atoms/FlexCol';
import { UseTableResult } from '../../use/useTable';
import { Form } from '../../types/System.types';
import { useFormTypes } from '../../use/useFormTypes';
import { UserContext } from '../../providers/UserProvider';
import { FormFilterMode } from '../../use/views/useViewFormsByEntity';
import dayjs from 'dayjs';
import { FormCreateMenu } from './form/FormCreateMenu';
import { DatePickerWithPresets } from '../atoms/DatePickerWithPresets';
import { defaultMetricStartTimeWindow } from '../../types/System.Parameters.types';

interface FormsTableControlsProps {
  tableProps: UseTableResult<Form>;
}

const FormsTableControls: FC<FormsTableControlsProps> = ({ tableProps }) => {
  const [, contextHolder] = message.useMessage();
  const formTypes = useFormTypes();
  const { user } = useContext(UserContext);

  const [selectedFormType, setSelectedFormType] = useState<string>('');
  const [filterMode, setFilterMode] = useState<FormFilterMode>('all');
  const defaultDateRange = [dayjs(defaultMetricStartTimeWindow), dayjs()] as [
    dayjs.Dayjs,
    dayjs.Dayjs
  ];
  const [selectedDateRange, setSelectedDateRange] = useState<
    [dayjs.Dayjs, dayjs.Dayjs] | null
  >(defaultDateRange);

  const { useBreakpoint } = Grid;
  const screen = useBreakpoint();

  useEffect(() => {
    updateFilters(selectedFormType, filterMode, defaultDateRange);
  }, []);

  /**
   * Updates filters based on current form type and mode
   * @param newFormType The new form type filter
   * @param newMode The new filter mode
   * @param newDateRange The new date range filter
   */
  const updateFilters = (
    newFormType: string,
    newMode: FormFilterMode,
    newDateRange: [dayjs.Dayjs, dayjs.Dayjs] | null
  ) => {
    const baseFilters = [];

    const formTypeFilter = newFormType
      ? [
          {
            field: 'formType',
            operator: '==' as WhereFilterOp,
            value: newFormType,
          },
        ]
      : [];
    baseFilters.push(...formTypeFilter);

    const userFilter = user?.uid
      ? newMode === 'subscribed'
        ? [
            {
              field: 'notificationsSubscribers',
              operator: 'array-contains' as WhereFilterOp,
              value: user.uid,
            },
          ]
        : newMode === 'assigned'
        ? [
            {
              field: 'assignedTo',
              operator: '==' as WhereFilterOp,
              value: user.uid,
            },
          ]
        : []
      : [];
    baseFilters.push(...userFilter);

    if (newDateRange && newDateRange[0] && newDateRange[1]) {
      baseFilters.push({
        field: 'meta.lastModified',
        operator: '>=' as WhereFilterOp,
        value: newDateRange[0].toDate(),
      });
      baseFilters.push({
        field: 'meta.lastModified',
        operator: '<=' as WhereFilterOp,
        value: newDateRange[1].toDate(),
      });
    }

    tableProps.setFilters(baseFilters);
    tableProps.returnToStart();
  };

  const handleFormTypeChange = (value: string) => {
    setSelectedFormType(value);
    updateFilters(value, filterMode, selectedDateRange);
  };

  const handleModeChange = (mode: FormFilterMode) => {
    setFilterMode(mode);
    updateFilters(selectedFormType, mode, selectedDateRange);
  };

  const handleDateRangeChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  ) => {
    setSelectedDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs] | null);
    updateFilters(
      selectedFormType,
      filterMode,
      dates as [dayjs.Dayjs, dayjs.Dayjs] | null
    );
  };

  return (
    <FlexBox stretch noGrow wrap>
      {contextHolder}
      <FlexBox gap={0} noGrow alignStart wrap style={{ width: '100%' }} >
        <FlexBox gap={20} noGrow alignStart>
          <Segmented
            value={filterMode}
            onChange={(value) => handleModeChange(value as FormFilterMode)}
            options={[
              { label: screen.sm ? 'Assigned' : 'Assigned', value: 'assigned' },
              { label: screen.sm ? 'Subscribed' : 'Sub', value: 'subscribed' },
              { label: 'All', value: 'all' },
            ]}
          />
          <Select
            value={selectedFormType}
            onChange={handleFormTypeChange}
            style={{ width: 120 }}
            dropdownStyle={{ width: 320 }}
            options={[
              { value: '', label: <em>All types</em> },
              ...(Object.values(formTypes.data).map((t) => ({
                value: t.docId || '',
                label:
                  t.description?.longLabel ||
                  t.description?.shortLabel ||
                  'Unnamed Form Type',
              })) || []),
            ]}
            variant="filled"
          />
          <DatePickerWithPresets
            value={selectedDateRange}
            onChange={handleDateRangeChange}
            style={{ width: 265, justifySelf: 'flex-end' }}
            size="middle"
          />
        </FlexBox>
        <FlexCol gap={0} noGrow alignEnd justifyEnd>
          <FormCreateMenu formTypes={formTypes} />
        </FlexCol>
      </FlexBox>
    </FlexBox>
  );
};

export default FormsTableControls;
