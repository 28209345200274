import { FC, useMemo, lazy, Suspense, useCallback, useContext, useState } from 'react';
import {
    Grid,
    Spin,
    message,
} from 'antd';
import type { TableProps } from 'antd/es/table';

import {
    TableColumnType,
    TableAggregationType,
    EntityRecord,
    fieldType,
} from '../../../types/System.types';
import { FieldViewVisibility } from '../../../types/System.Parameters.types';
import { FormContext } from '../../../providers/artifacts/FormProvider';
import {
    CalculateOptimizationRequest,
    OptimizationResult,
} from '../../../use/orgs/useClwTableOptimizer';
import { calculateColumnAggregations, formatAggregation } from './columnSumaryStats';
import { calculateRow } from './formulaCalculator';
import { useFormulaParser } from '../../../use/useFormulaParser';
import { useTableOptimizer } from '../../../use/orgs/useClwTableOptimizer';
import OptimizationModal from './atoms/OptimizationModal';
import FlexCol from '../../atoms/FlexCol';

const MobileTableView = lazy(() => import('./atoms/MobileTableView'));
const DesktopTableView = lazy(() => import('./atoms/DesktopTableView'));

const { useBreakpoint } = Grid;

interface TableUIProps {
    dataSource?: any[];
    columns: TableColumnType[];
    onChange?: (updatedData: any[]) => void;
    readOnly?: boolean;
    id?: string;
    showColumnAggregations?: boolean;
    columnAggregations?: {
        [columnKey: string]: TableAggregationType | ColumnAggregationConfig;
    };
    pagination?: TableProps<any>['pagination'];
    scroll?: TableProps<any>['scroll'];
    style?: TableProps<any>['style'];
    className?: TableProps<any>['className'];
    optimizationConfig?: CalculateOptimizationRequest;
    onOptimize?: (result: OptimizationResult) => void;
    userId?: string;
    isBOMTable?: boolean;
    onClearRow?: (index: number) => void;
}

interface ColumnAggregationConfig {
    aggregationType?: TableAggregationType;
    customCalculation?: (values: readonly any[]) => number;
}

const shouldShowColumn = (column: TableColumnType, visibilityMode: FieldViewVisibility): boolean => {
    // If it's an entity reference, show it (NOTE: add this functinality in future)
    if (column.uiElementType === 'entityReference') return true;
    
    const ft = fieldType[column.dataIndex as keyof typeof fieldType];
    if (!ft?.visibility || ft.visibility.length === 0) return true;
    if (visibilityMode === 'internal' || visibilityMode === undefined) return true;
    
    return ft.visibility.includes(visibilityMode);
};

const hasDistMarkdownColumn = (columns: TableColumnType[]): boolean => {
    return columns.some(col => col.dataIndex === 'distMarkdown');
};

const TableUI: FC<TableUIProps> = ({
    dataSource,
    columns,
    onChange,
    readOnly,
    id,
    showColumnAggregations = true,
    columnAggregations = {},
    onOptimize: onOptimizeCallback,
    isBOMTable = false,
    ...props
}) => {
    const screens = useBreakpoint();
    const { evaluateFormula } = useFormulaParser();
    const [messageApi, contextHolder] = message.useMessage();
    const { visibilityMode } = useContext(FormContext);
    const {
        optimizeTableData,
        processOptimizedRow,
        isOptimizing,
        clearMarkdown,
        calculateMaxReduction,
        calculateMaxObaseReduction,
    } = useTableOptimizer();

    const [isOptimizeModalOpen, setIsOptimizeModalOpen] = useState(false);

    const onCellChange = (rowIndex: number, columnKey: string, value: any, entityRecord?: EntityRecord | null) => {
        if (onChange && !readOnly && dataSource) {
            const updatedData = [...dataSource];
            const currentRow = { ...updatedData[rowIndex] };

            currentRow[columnKey] = value;

            if (entityRecord?.fields) {
                columns.forEach(column => {
                    const field = entityRecord.fields[column.dataIndex];
                    if (field?.value !== undefined) {
                        currentRow[column.dataIndex] = field.value;
                    }
                });
            }

            updatedData[rowIndex] = calculateRow(currentRow, columns, evaluateFormula);
            onChange(updatedData);
        }
    };

    const onAddRow = () => {
        if (onChange && !readOnly) {
            const newRow = columns.reduce<Record<string, any>>((acc, col) => ({
                ...acc,
                [col.dataIndex]: null
            }), {});
            onChange([...(dataSource ?? []), newRow]);
        }
    };

    const onRemoveRow = (index: number) => {
        if (onChange && !readOnly && dataSource) {
            const updatedData = [...dataSource];
            updatedData.splice(index, 1);
            onChange(updatedData);
        }
    };

    const columnAggregationResults = useMemo(() =>
        calculateColumnAggregations(dataSource, columns, showColumnAggregations),
        [dataSource, columns, showColumnAggregations]
    );

    const onOptimize = useCallback(async (targetReduction: number) => {
        if (!dataSource || !columns) {
            messageApi.error('Missing required data or columns');
            return;
        }

        try {
            const result = await optimizeTableData(
                dataSource,
                columns,
                {
                    data: dataSource,
                    targetReduction
                }
            );

            if (result.modelMeta?.success && onChange) {
                const updatedData = result.markdownPercents.map((markdown, index) =>
                    processOptimizedRow(dataSource[index], markdown, columns, evaluateFormula)
                );
                onChange(updatedData);

                if (onOptimizeCallback) {
                    onOptimizeCallback(result);
                }

                messageApi.success(result.modelMeta?.message || 'Optimization complete');
            } else {
                messageApi.error(result.modelMeta?.message || 'Optimization failed');
            }
        } catch (error) {
            messageApi.error('Failed to optimize table');
        }
    }, [dataSource, columns, optimizeTableData, processOptimizedRow, evaluateFormula, onChange, onOptimizeCallback, messageApi]);

    const onClearMarkdown = () => {
        if (onChange && dataSource) {
            clearMarkdown(dataSource, columns, evaluateFormula, onChange);
        }
    };

    const onOptimizeClick = () => {
        setIsOptimizeModalOpen(true);
    };

    const visibleColumns = useMemo(() =>
        columns.filter(column => shouldShowColumn(column, visibilityMode)),
        [columns, visibilityMode]
    );

    const showOptimization = isBOMTable && hasDistMarkdownColumn(columns);

    const onClearRow = useCallback((index: number) => {
        if (!onChange || !dataSource) return;
        const newData = [...dataSource];
        const clearedRow = { ...newData[index] };
        newData[index] = clearedRow.lineId ? { lineId: clearedRow.lineId } : {};
        
        onChange(newData);
        messageApi.success('Row cleared');
    }, [dataSource, onChange, messageApi]);

    const tableProps = {
        dataSource: dataSource ?? [],
        columns: visibleColumns,
        onCellChange,
        readOnly,
        id,
        showColumnAggregations,
        columnAggregationResults,
        formatAggregation,
        onOptimize,
        onClearMarkdown,
        isOptimizing,
        optimizeProps: {
            onOptimizeClick,
            isOptimizing,
        },
        onAddRow,
        onRemoveRow,
        onClearRow,
        ...props
    };

    return (
        <FlexCol>
            {contextHolder}
            <Suspense fallback={<Spin size="large" />}>
                {!screens.md ? (
                    <MobileTableView {...tableProps} showOptimization={showOptimization} />
                ) : (
                    <DesktopTableView {...tableProps} showOptimization={showOptimization} />
                )}
            </Suspense>
            {showOptimization && (
                <OptimizationModal
                    isOpen={isOptimizeModalOpen}
                    onClose={() => setIsOptimizeModalOpen(false)}
                    onConfirm={async (amount) => {
                        await onOptimize(amount);
                        setIsOptimizeModalOpen(false);
                    }}
                    isOptimizing={isOptimizing}
                    maxOBaseReduction={dataSource ? calculateMaxObaseReduction(dataSource) : { amount: 0, percentage: 0 }}
                    maxCutPriceReduction={dataSource ? calculateMaxReduction(dataSource) : { amount: 0, percentage: 0 }}
                />
            )}
        </FlexCol>
    );
};

export default TableUI;
