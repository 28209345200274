import { FormVersion, EntityRecord } from '../../types/System.types';

/**
 * Groups filtered form versions by entity
 */
export function groupFormviews(
  viewModeFilteredVersions: FormVersion[],
  entitySelection: { entityId: string; groupId: string | null } | null,
  entityRecordMap: Map<string, EntityRecord>
): Record<string, { entityName: string, items: any[] }> {
  const result: Record<string, { entityName: string, items: any[] }> = {};
  
  viewModeFilteredVersions.forEach(version => {
    const entityRefs = version.entityReferences || {};
    
    const entityRef = entitySelection?.entityId ? 
      entityRefs[entitySelection.entityId] : 
      Object.values(entityRefs)[0];
    
    if (!entityRef) return;
    
    const entityRecord = entitySelection?.entityId 
      ? entityRecordMap.get(entityRef) 
      : null;
    
    const entityId = entityRef;
    const entityName = entityRecord?.name || 'Unknown';
    
    if (!result[entityId]) {
      result[entityId] = {
        entityName,
        items: []
      };
    }
    
    result[entityId].items.push({
      version: {
        state: version.state,
        parentFormType: version.parentFormType,
        docId: version.docId || '',
        formNumber: version.parentFormData?.formNumber || '',
        formVersionNumber: version.formVersionNumber,
        meta: {
          lastModified: version.meta?.lastModified,
        }
      },
      form: {
        title: version.parentFormData?.title || '',
        docId: version.parentFormData?.docId || '',
        formNumber: version.parentFormData?.formNumber || '',
        lastFormVersionNumber: version.parentFormData?.lastFormVersionNumber
      },
      formId: version.parentFormData?.docId || '',
      formVersionId: version.docId || ''
    });
  });
  
  return result;
} 