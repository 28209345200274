import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

interface PdfExportParams {
    contentRef: React.RefObject<HTMLDivElement>;
    formData: { formNumber?: string } | null;
    versionTitle: string | undefined;
    returnBlob?: boolean;
}

export const pdfExport = async ({ contentRef, formData, versionTitle, returnBlob = false }: PdfExportParams): Promise<Blob | void> => {
    if (contentRef.current) {
        const PAGE_WIDTH = 215.9; // 8.5 inches in mm
        const PAGE_HEIGHT = 279.4; // 11 inches in mm
        const MARGIN = 12.7; // 0.5 inch margin in mm (standard for many forms)

        try {
            const canvas = await html2canvas(contentRef.current, { 
                scale: 2,
                windowWidth: 1024,
                windowHeight: 1448,
            });

            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: [PAGE_WIDTH, PAGE_HEIGHT]
            });

            const contentWidth = PAGE_WIDTH - (2 * MARGIN);
            const contentHeight = PAGE_HEIGHT - (2 * MARGIN);
            const aspectRatio = canvas.width / canvas.height;

            let imgWidth = contentWidth;
            let imgHeight = imgWidth / aspectRatio;

            if (imgHeight > contentHeight) {
                imgHeight = contentHeight;
                imgWidth = imgHeight * aspectRatio;
            }

            const x = MARGIN;
            const y = MARGIN;

            let remainingHeight = canvas.height;
            let currentPosition = 0;

            while (remainingHeight > 0) {
                const canvasSlice = document.createElement('canvas');
                canvasSlice.width = canvas.width;
                canvasSlice.height = Math.min(canvas.height, (imgHeight / imgWidth) * canvas.width);

                const ctx = canvasSlice.getContext('2d');
                if (ctx) {
                    ctx.drawImage(canvas, 0, currentPosition, canvas.width, canvasSlice.height, 0, 0, canvas.width, canvasSlice.height);
                }

                const imgData = canvasSlice.toDataURL('image/png');
                if (currentPosition > 0) pdf.addPage();
                pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);

                remainingHeight -= canvasSlice.height;
                currentPosition += canvasSlice.height;
            }

            if (returnBlob) {
                return pdf.output('blob');
            }

            const formNumber = formData?.formNumber || '';
            const formName = versionTitle || 'form';
            const sanitizedFormName = `${formNumber}-${formName}`.replace(/[^a-z0-9]/gi, '-').toLowerCase();
            pdf.save(`${sanitizedFormName}.pdf`);
        } catch (error) {
            console.error('Error generating PDF:', error);
            throw error;
        }
    } else {
        console.warn('Content reference is null or undefined.');
        throw new Error('Content reference is null or undefined');
    }
};