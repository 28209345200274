import { httpsCallable } from 'firebase/functions';
import { functions } from './firebaseFunctions';

/**
 * Request object for sending an email.
 */
export interface SendEmailRequest {
    to: string | string[];
    subject: string;
    html: string;
    text?: string;
    formId?: string;
    attachments?: {
        content?: string | Buffer;
        filename?: string | false | undefined;
        path?: string;
        contentType?: string;
    }[];
    bcc?: string | string[];
    cc?: string | string[];
    headers?: Record<string, string>;
    replyTo?: string | string[];
    tags?: {
        name: string;
        value: string;
    }[];
    scheduledAt?: string;
}

/**
 * Response object from sending an email.
 */
export interface SendEmailResponse {
    messageId: string;
    success: boolean;
}

/**
 * Sends an email using Firebase Cloud Functions.
 * @param {SendEmailRequest} requestData - The data required to send the email.
 * @returns {Promise<SendEmailResponse>} A promise that resolves to the send result.
 * @throws {Error} If sending the email fails.
 */
export const sendEmail = async (requestData: SendEmailRequest): Promise<SendEmailResponse> => {
    const callSendEmail = httpsCallable<SendEmailRequest, SendEmailResponse>(functions, 'sendEmail');

    try {
        const result = await callSendEmail(requestData);
        return result.data;
    } catch (error) {
        const errorMessage = `Failed to send email: ${error}`;
        console.error('Error calling sendEmail function:', error);
        throw new Error(errorMessage);
    }
}; 