import { FC, useContext, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Spin } from 'antd';

import { UserContext } from '../providers/UserProvider';
import { AIProvider } from '../providers/AIProvider';
import { devFlag } from '../config';
import Agent from './agents/Agent';

/**
 * Component that protects routes requiring authentication
 */
const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  
  if (userContext.loading) {
    return <Spin size="large" fullscreen />;
  }
  
  if (userContext.isLoggedIn) {
    return (
      <AIProvider>
        {children}
        {devFlag && <Agent />}
      </AIProvider>
    );
  }
  
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoute; 