import { FC } from 'react';
import { Empty } from 'antd';
import { FormStepCard } from '../form/FormStepCard';
import FlexCol from '../../atoms/FlexCol';

interface FormviewsListProps {
  groupedResults: Record<string, { entityName: string, items: any[] }>;
  onEntityClick: (recordId: string) => void;
}

/**
 * Component for displaying grouped form results
 */
export const FormviewsList: FC<FormviewsListProps> = ({
  groupedResults,
  onEntityClick
}) => {
  return (
    <FlexCol noGrow>
      {Object.keys(groupedResults).length === 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"No forms meet your criteria..."}
        />
      ) : (
        Object.entries(groupedResults).map(([entityRef, group]) => (
          <FormStepCard
            key={entityRef}
            entityId={entityRef}
            entityName={group.entityName}
            items={group.items}
            onEntityClick={onEntityClick}
          />
        ))
      )}
    </FlexCol>
  );
}; 