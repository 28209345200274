import React, { useState, useContext } from 'react';
import { 
    Typography,
    Select,
    Button,
    Alert,
    Tag,
    Space,
    message,
    Modal,
    Input,
    Form
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { createForm } from '../../services/artifactFunctions';
import { DataContext } from '../../providers/DataProvider';

import FlexCard from '../atoms/FlexCard';
import FlexCol from '../atoms/FlexCol';
import FlexBox from '../atoms/FlexBox';
import { Notification, FormType, EmailAnalysisNotificationData } from '../../types/System.types';
import { UserContext } from '../../providers/UserProvider';

const { Text, Title } = Typography;
const { TextArea } = Input;

const MAX_REASON_LENGTH = 256;

interface EmailAnalysisNotificationCardProps {
    notification: Notification;
    isApproved?: boolean;
}

const FORM_TYPE_OPTIONS = [
    { value: 'Design', label: 'Design' },
    { value: 'Quote', label: 'Quote' },
    { value: 'Buy', label: 'Buy' },
    { value: 'HFR', label: 'HFR' },
    { value: 'Order', label: 'Order' },
    { value: 'Reconciliation', label: 'Reconciliation' }
];

const EmailAnalysisNotificationCard: React.FC<EmailAnalysisNotificationCardProps> = ({
    notification,
    isApproved = false
}) => {
    const navigate = useNavigate();
    const { firestore } = useContext(DataContext);
    const { 
        user, 
        updateNotificationDataAndMarkAsRead,
        updateNotificationDataAndMarkAsUnread,
        users 
    } = useContext(UserContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedFormType, setSelectedFormType] = useState<FormType>(
        (notification.notificationEvent.data?.suggestedFormType ?? 'Design') as FormType
    );
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showDismissModal, setShowDismissModal] = useState(false);
    const [dismissReason, setDismissReason] = useState('');

    // Get the dismissing user's name
    const getDismissingUserName = (userId: string) => {
        const dismissingUser = users.find(u => u.docId === userId);
        return dismissingUser?.name || userId;
    };

    const handleDismiss = async () => {
        setIsLoading(true);
        setError(null);
        try {
            await updateNotificationDataAndMarkAsRead(notification, {
                dismissedBy: {
                    userId: user?.uid,
                    timestamp: new Date(),
                    reason: dismissReason.trim() || undefined
                }
            });
            messageApi.info('Email Analysis was dismissed');
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Failed to dismiss notification';
            setError(errorMessage);
            messageApi.error('Failed to dismiss notification');
        } finally {
            setIsLoading(false);
            setShowDismissModal(false);
            setDismissReason('');
        }
    };

    const handleUndismiss = async () => {
        setIsLoading(true);
        setError(null);
        try {
            await updateNotificationDataAndMarkAsUnread(notification, {
                dismissedBy: null
            });
            
            messageApi.success('Notification restored');
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Failed to restore notification';
            setError(errorMessage);
            messageApi.error('Failed to restore notification');
        } finally {
            setIsLoading(false);
        }
    };

    const handleApprove = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const emailData = notification.notificationEvent.data as EmailAnalysisNotificationData;
            const newFormId = await createForm({
                formType: String(selectedFormType),
                title: `${selectedFormType} for ${emailData.project}`,
                setFormData: {
                    notificationsSubscribers: [emailData.assignedToUserId]
                }
            });

            // Get the form to access its form number
            const formRef = doc(firestore, 'forms', newFormId);
            const formDoc = await getDoc(formRef);
            const formNumber = formDoc.data()?.formNumber;

            const updatedMessage = `Form ${formNumber} created from email '${emailData.emailSubject}'`;

            // Update notification data with approval info and form ID
            await updateNotificationDataAndMarkAsRead(notification, {
                approvedBy: {
                    userId: user?.uid,
                    timestamp: new Date()
                },
                formId: newFormId
            }, updatedMessage);

            messageApi.success('New form created successfully');
            navigate(`/forms/${newFormId}`);
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Failed to create form';
            setError(errorMessage);
            messageApi.error('Failed to create new form');
        } finally {
            setIsLoading(false);
        }
    };

    const { data } = notification.notificationEvent;
    if (!data) return null;

    const emailData = data as EmailAnalysisNotificationData;
    const isDismissed = !!emailData.dismissedBy;

    return (
        <FlexCard
            stretch
            gap={16}
            style={{
                opacity: isApproved || isDismissed ? 0.7 : 1
            }}
        >
            {contextHolder}
            <FlexCol gap={8}>
                <Title level={5} style={{ margin: 0 }}>
                    {notification.notificationEvent.message}
                </Title>
                
                {emailData.project && (
                    <Text>
                        Project: <Tag color="blue">{emailData.project}</Tag>
                    </Text>
                )}

                {emailData.actionItems && emailData.actionItems.length > 0 && (
                    <FlexCol gap={4}>
                        <Text strong>Action Items:</Text>
                        {emailData.actionItems.map((item: string, index: number) => (
                            <Text key={index}>• {item}</Text>
                        ))}
                    </FlexCol>
                )}

                {isDismissed ? (
                    <>
                        <Alert
                            type="info"
                            message={`Email Analysis was dismissed by ${getDismissingUserName(emailData.dismissedBy?.userId || '')}`}
                            description={emailData.dismissedBy?.reason}
                            style={{ backgroundColor: '#fff7e6' }}
                        />
                        <Button 
                            onClick={handleUndismiss}
                            loading={isLoading}
                        >
                            Restore Notification
                        </Button>
                    </>
                ) : isApproved ? (
                    <Alert
                        type="success"
                        message="Form has been created successfully"
                        showIcon
                    />
                ) : (
                    <>
                        <FlexCol gap={8}>
                            <Text strong>Form Details:</Text>
                            <FlexBox gap={16}>
                                <FlexCol gap={4}>
                                    <Text>Form Type:</Text>
                                    <Select
                                        value={selectedFormType}
                                        onChange={setSelectedFormType}
                                        options={FORM_TYPE_OPTIONS}
                                        style={{ width: 200 }}
                                        disabled={isApproved || isLoading}
                                    />
                                </FlexCol>

                                <FlexCol gap={4}>
                                    <Text>
                                        <Space>
                                            <UserOutlined />
                                            Assigned To:
                                        </Space>
                                    </Text>
                                    <FlexBox gap={8}>
                                        <Tag>{emailData.assignedToUser}</Tag>
                                        <Text type="secondary">{emailData.assignedToEmail}</Text>
                                    </FlexBox>
                                </FlexCol>
                            </FlexBox>
                        </FlexCol>

                        {error && (
                            <Alert
                                type="error"
                                message={error}
                                showIcon
                            />
                        )}

                        <Space>
                            <Button
                                type="primary"
                                onClick={handleApprove}
                                loading={isLoading}
                            >
                                Approve
                            </Button>
                            <Button
                                onClick={() => setShowDismissModal(true)}
                                loading={isLoading}
                            >
                                Dismiss
                            </Button>
                        </Space>
                    </>
                )}
            </FlexCol>

            <Modal
                title="Dismiss Email Analysis"
                open={showDismissModal}
                onCancel={() => {
                    setShowDismissModal(false);
                    setDismissReason('');
                }}
                onOk={handleDismiss}
                confirmLoading={isLoading}
            >
                <Form>
                    <Form.Item label="Reason (Optional)">
                        <TextArea
                            value={dismissReason}
                            onChange={e => setDismissReason(e.target.value)}
                            placeholder="Why are you dismissing this notification?"
                            maxLength={MAX_REASON_LENGTH}
                            showCount
                            rows={4}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </FlexCard>
    );
};

export default EmailAnalysisNotificationCard; 