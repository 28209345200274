import { liteClient } from 'algoliasearch/lite';
import { algoliaConfig } from '../config';
import { formVersionNumberPrefix, formStateDescription, RecordStatus } from '../types/System.Parameters.types';

/**
 * Configuration interface for Algolia search settings
 */
export interface SearchAlgoliaConfig {
  appId: string;
  searchApiKey: string;
  indexName: string;
}

/**
 * Initialize Algolia search client
 */
export const searchClient = liteClient(
  algoliaConfig.appId, 
  algoliaConfig.searchApiKey
);

/**
 * Standard interface for search result items
 */
export interface SearchResultItem {
  id: string;
  url: string;
  title: string;
  subtitle?: string;
}

/**
 * Transforms raw search results into standardized format
 * @param rawItem - Raw item from search results
 * @returns Standardized search result item
 */
export const normalizeSearchResult = (rawItem: any): SearchResultItem => ({
  id: rawItem.objectID,
  url: rawItem.searchResultURL || `/${rawItem.collectionName}/${rawItem.objectID}`,
  title: [
    rawItem.formNumberParent && (
      rawItem?.formVersionNumber 
        ? `${rawItem.formNumberParent} ${formVersionNumberPrefix}${rawItem.formVersionNumber}`
        : rawItem.formNumberParent
    ) || rawItem.formNumber || rawItem.name || rawItem.objectID,
    rawItem?.formType || rawItem?.formType_parentFormData,
    rawItem?.state && formStateDescription[rawItem.state as keyof typeof formStateDescription]?.shortLabel || rawItem?.status && rawItem.status
  ].filter(Boolean).join(' | '),
  subtitle: [
    ...Object.entries(rawItem || {})
      .filter(([key]) => key.startsWith('value_fields_'))
      .slice(0, 7)
      .map(([key, value]) => value && `${key.replace('value_fields_', '')}: ${value}`)
  ].filter(Boolean).join(' | ')
});
