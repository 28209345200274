import React, { FC, useContext } from 'react';
import { Typography, Tooltip } from 'antd';
import { 
    UIFormProps, 
    FormVersion,
} from '../../types/System.types';
import { FormPropsUIDescription, formVersionNumberPrefix } from '../../types/System.Parameters.types';
import FlexCol from '../atoms/FlexCol';
import FieldLabel from '../atoms/FieldLabel';
import { useFormAndEntityContexts } from '../../use/useFormAndEntityContexts';
import { FormContext } from '../../providers/artifacts/FormProvider';

const { Title } = Typography;

interface FormPropsUIProps {
    /** Configuration object containing form property */
    uiFormProperty: UIFormProps;
}

type FormPropId = keyof typeof FormPropsUIDescription;
const isValidFormPropId = (id: string): id is FormPropId => {
    return id in FormPropsUIDescription;
};

/**
 * A read-only component that displays form properties.
 * 
 * Renders form-level properties in a standardized format with labels and tooltips.
 * Currently supports:
 * - formNumber: Shows the form's unique identifier
 * - formType: Shows the form type's description
 * - formVersionNumber: Shows the form's version number
 * 
 * @component
 * @param {FormPropsUIProps} props - Component props
 * @param {UIFormProps} props.uiFormProperty - Configuration object for the form property UI element
 * @returns {JSX.Element} A read-only form property display
 */
const FormPropsUI: FC<FormPropsUIProps> = React.memo(({
    uiFormProperty,
}) => {
    const { data, insideFormContext } = useFormAndEntityContexts();
    const formContext = useContext(FormContext);
    const { id } = uiFormProperty;
    
    const formTypeObj = formContext?.formTypeOfSelectedForm?.data;
    const formVersion = insideFormContext && data ? data as FormVersion : null;
    
    let displayValue = '';
    
    switch (id as string) {
        case 'formNumber':
            displayValue = formVersion?.parentFormData?.formNumber || '';
            break;
        case 'formType':
            displayValue = formTypeObj?.description?.shortLabel || formTypeObj?.description?.longLabel || '';
            break;
        case 'formVersionNumber':
            displayValue = formVersion?.formVersionNumber 
                ? formVersionNumberPrefix + formVersion.formVersionNumber.toString() 
                : '-';
            break;
        default:
            displayValue = '';
    }
    
    const valueConfig = {
        displayValue,
        label: isValidFormPropId(id) ? FormPropsUIDescription[id].shortLabel : id,
        tooltip: isValidFormPropId(id) ? FormPropsUIDescription[id].longDescription : `${id} value`
    };
    
    return (
        <FlexCol 
            gap={0} 
            noGrow
            style={{
                marginRight: 10,
            }}
            {...uiFormProperty.props}
        >
            <FieldLabel
                label={valueConfig.label}
                tooltip={valueConfig.tooltip}
            />
            <Title 
                level={2}
                style={{
                    marginTop: -5,
                    marginBottom: 0,
                }}
            >
                {valueConfig.displayValue}
            </Title>
        </FlexCol>
    );
});

FormPropsUI.displayName = 'FormPropsUI';

export default FormPropsUI; 