import { FC } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { Entity, RecordGroup } from '../../types/System.types';
import { useGetEntityGroups } from '../../use/useGetEntityGroups';

interface EntityGroupSelection {
  entityId: Entity['docId'];
  groupId: RecordGroup['groupId'] | null;
}

interface EntityGroupSelectorProps {
  value: EntityGroupSelection | null;
  onChange: (value: EntityGroupSelection | null) => void;
  style?: React.CSSProperties;
  limit?: number;
}

/**
 * Component for selecting an entity or entity group
 */
const EntityGroupSelector: FC<EntityGroupSelectorProps> = ({
  value,
  onChange,
  style,
  limit = 1000
}) => {
  const { groupOptions, loading } = useGetEntityGroups({ limit });

  const filterOption: SelectProps['filterOption'] = (input, option) => {
    if (!option?.label) return false;
    return option.label.toString().toLowerCase().includes(input.toLowerCase());
  };

  const handleChange = (selectedValue: string | null) => {
    if (!selectedValue) {
      onChange(null);
      return;
    }
    try {
      const parsed = JSON.parse(selectedValue) as EntityGroupSelection;
      onChange(parsed);
    } catch (e) {
      console.error('Error parsing selection value:', e);
      onChange(null);
    }
  };

  const stringValue = value ? JSON.stringify(value) : null;

  return (
    <Select
      variant="filled"
      placeholder="Select an Entity Group"
      style={{ ...style }}
      dropdownStyle={{ 
        width: 320
      }}
      value={stringValue}
      onChange={handleChange}
      options={groupOptions}
      loading={loading}
      showSearch
      filterOption={filterOption}
    />
  );
};

export default EntityGroupSelector;
