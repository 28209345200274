import { FC, useContext } from 'react';
import { Select, Typography, Tooltip, Button, Divider } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FormContext } from '../../../providers/artifacts/FormProvider';
import { FormVersionContext } from '../../../providers/artifacts/FormVersionProvider';
import { formStateDescription, formVersionNumberPrefix } from '../../../types/System.Parameters.types';
import { TimeType } from '../../../types/System.types';
import FlexBox from '../../atoms/FlexBox';
import SelectCurrentVersion from './SelectCurrentVersion';
import { PlusOutlined } from '@ant-design/icons';
import FlexCol from '../../atoms/FlexCol';

dayjs.extend(relativeTime);

const { Text } = Typography;

/** 
 * Breadcrumb component for selecting form versions 
 * @param formId - The ID of the form
 */
const FormVersionSelect: FC = () => {
  const {
    selectedForm,
    formTypeOfSelectedForm
  } = useContext(FormContext);

  const {
    selectedFormVersion,
    formVersions,
    navigateToVersion
  } = useContext(FormVersionContext);

  const formatDate = (time: TimeType | undefined) => {
    if (time && 'toDate' in time) {
      const date = time.toDate();
      return {
        relative: dayjs(date).fromNow(),
        full: date.toLocaleString()
      };
    }
    return {
      relative: '',
      full: ''
    };
  };

  const handleVersionChange = (versionId: string) => {
    if (selectedForm.data?.docId) {
      navigateToVersion(selectedForm.data.docId, versionId);
    }
  };

  const handleCreateVersion = async () => {
    if (!selectedFormVersion.data || !selectedForm.data?.docId) return;

    try {
      const { createFormVersion } = await import('../../../services/artifactFunctions');
      const newVersionId = await createFormVersion(
        selectedForm.data.docId,
        'draft',
        selectedFormVersion.data
      );
      navigateToVersion(selectedForm.data.docId, newVersionId);
    } catch (err) {
      console.error(`Error creating new version: ${err instanceof Error ? err.message : String(err)}`);
    }
  };

  const currentVersionState = selectedFormVersion.data?.state;
  const possibleStates = formTypeOfSelectedForm.data?.possibleStates;
  const allowedNextStates = currentVersionState
    ? possibleStates?.[currentVersionState]?.allowedNextStates
    : undefined;
  const canCreateNewVersion = allowedNextStates?.includes('draft') ?? false;

  const versionOptions = formVersions.data?.map(version => {
    const modified = formatDate(version?.meta?.lastModified);
    const versionNumber = version.formVersionNumber ? formVersionNumberPrefix + version.formVersionNumber.toString() + ' ' : '- ';
    const formState = formStateDescription[version.state]?.shortLabel;
    const isCurrentVersion = selectedForm.data?.currentVersionId === version.docId;

    return {
      value: version.docId || '',
      label: (
        <Tooltip title={formStateDescription[version.state]?.longDescription || ''}>
          <Text>
            {versionNumber}
          </Text>
          <Text>
            &nbsp;{formState}&nbsp;
          </Text>
          <Text type="secondary">
            {modified.relative}
          </Text>
          {isCurrentVersion && (
            <Text type="success" style={{ marginLeft: 10 }}>
              current
            </Text>
          )}
        </Tooltip>
      ),
      title: `${versionNumber} | ${formState}${isCurrentVersion ? '—current' : ''}`
    };
  }) || [];

  return (
    <FlexBox>
      <Text>{selectedForm.data?.formNumber}</Text>
      <Select
        value={selectedFormVersion.data?.docId}
        optionLabelProp='title'
        onChange={handleVersionChange}
        options={versionOptions}
        style={{ minWidth: 120 }}
        dropdownStyle={{ width: 320 }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <FlexCol style={{ marginTop: 5 }}>
              <Divider style={{ margin: 0 }} />
              <Button
                type="text"
                icon={<PlusOutlined />}
                disabled={!canCreateNewVersion}
                onClick={handleCreateVersion}
                size="small"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                New Version
              </Button>
              <Divider style={{ margin: 0 }} />
              <SelectCurrentVersion />
            </FlexCol>
          </>
        )}
      />
    </FlexBox>
  );
};

export default FormVersionSelect; 