import { useState, useEffect, useCallback } from 'react';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  Auth,
  User,
  OAuthProvider,
  signInWithRedirect,
  linkWithCredential,
  UserCredential,
  AuthCredential,
  getRedirectResult
} from 'firebase/auth';
import { initializeApp, FirebaseApp } from 'firebase/app';

import { firebaseConfig } from '../config';

/**
 * Interface representing authentication error information
 * @interface AuthErrorInfo
 */
interface AuthErrorInfo {
  /** Email address associated with the error */
  email: string;
  /** ID of the provider that caused the error */
  providerId: string;
  /** Firebase error code */
  errorCode: string;
  /** Authentication credential that can be used for account linking */
  credential?: AuthCredential;
}

/**
 * Interface for the Firebase authentication hook
 * @interface UseFirebaseAuth
 */
interface UseFirebaseAuth {
  /** Loading state */
  loading: boolean;
  /** Current authenticated user or null if not authenticated */
  user: User | null;
  /** Function to log in with email and password */
  login: (email: string, password: string) => Promise<UserCredential>;
  /** Function to create a new user with email and password */
  signup: (email: string, password: string) => Promise<void>;
  /** Function to log out the current user */
  logout: () => Promise<void>;
  /** Function to sign in with Google using redirect */
  signInWithGoogle: () => Promise<void>;
  /** Function to sign in with Microsoft using redirect */
  signInWithMicrosoft: () => Promise<void>;
  /** Function to link accounts using email/password and a credential */
  linkAccounts: (email: string, password: string, credential: AuthCredential) => Promise<UserCredential>;
  /** Function to get the result of a redirect sign-in */
  getAuthRedirectResult: () => Promise<UserCredential | null>;
}

/**
 * Custom hook for Firebase authentication with account linking support
 * 
 * This hook provides a comprehensive interface for handling Firebase authentication,
 * including standard email/password authentication, social provider authentication,
 * and account linking functionality.
 * 
 * @returns {UseFirebaseAuth} Authentication state and functions
 */
export default function useFirebaseAuth(): UseFirebaseAuth {
  const app: FirebaseApp = initializeApp(firebaseConfig);
  const auth: Auth = getAuth(app);
  const [loading, setLoading] = useState<boolean>(true);
  
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  /**
   * Authenticates a user with email and password
   * 
   * @param {string} email - User's email address
   * @param {string} password - User's password
   * @returns {Promise<UserCredential>} Firebase user credential
   * @throws Will throw an error if authentication fails
   */
  const login = async (email: string, password: string): Promise<UserCredential> => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      return result;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Links accounts by first signing in with email/password and then linking with the provided credential
   * 
   * @param {string} email - Email address for the account to link with
   * @param {string} password - Password for the account to link with
   * @param {AuthCredential} credential - Credential from the authentication provider to link
   * @returns {Promise<UserCredential>} Firebase user credential after linking
   * @throws Will throw an error if linking fails
   */
  const linkAccounts = async (email: string, password: string, credential: AuthCredential): Promise<UserCredential> => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const linkResult = await linkWithCredential(userCredential.user, credential);
      return linkResult;
    } catch (error) {
      throw error;
    }
  };

  /**
   * Creates a new user account with email and password
   * 
   * @param {string} email - Email address for the new account
   * @param {string} password - Password for the new account
   * @returns {Promise<void>}
   * @throws Will throw an error if signup fails
   */
  const signup = async (email: string, password: string): Promise<void> => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      throw error;
    }
  };

  /**
   * Initiates Google sign-in using redirect
   * 
   * @returns {Promise<void>}
   */
  const signInWithGoogle = async (): Promise<void> => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithRedirect(auth, provider);
    } catch (error) {
      throw error;
    }
  };

  /**
   * Handles authentication errors and extracts relevant information
   * 
   * @param {any} error - The error object from Firebase authentication
   * @returns {AuthErrorInfo | null} Extracted error information or null if not a handled error type
   */
  const handleAuthError = useCallback((error: any): AuthErrorInfo | null => {
    if (error?.code === 'auth/account-exists-with-different-credential') {
      const email = error.customData?.email;
      const credential = OAuthProvider.credentialFromError(error);
      
      if (email && credential) {
        return {
          email,
          providerId: credential.providerId,
          errorCode: error.code,
          credential: credential
        };
      }
    }
    return null;
  }, []);

  /**
   * Creates a configured Microsoft OAuthProvider with appropriate scopes
   * 
   * @returns {OAuthProvider} Configured Microsoft authentication provider
   */
  const createMicrosoftProvider = useCallback((): OAuthProvider => {
    const provider = new OAuthProvider('microsoft.com');
    
    provider.addScope('user.read');
    provider.addScope('openid');
    provider.addScope('profile');
    provider.addScope('email');
    
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    
    return provider;
  }, []);

  /**
   * Initiates Microsoft sign-in using redirect
   * 
   * @returns {Promise<void>}
   */
  const signInWithMicrosoft = async (): Promise<void> => {
    try {
      const provider = createMicrosoftProvider();
      await signInWithRedirect(auth, provider);
    } catch (error) {
      throw error;
    }
  };

  /**
   * Gets the result of a redirect sign-in
   * 
   * @returns {Promise<UserCredential | null>} The redirect result or null if no redirect occurred
   * @throws Will throw an error if getting the redirect result fails, with additional errorInfo if available
   */
  const getAuthRedirectResult = async (): Promise<UserCredential | null> => {
    try {
      const result = await getRedirectResult(auth);
      return result;
    } catch (error: any) {
      const errorInfo = handleAuthError(error);
      if (errorInfo) {
        throw { ...error, errorInfo };
      }
      throw error;
    }
  };

  /**
   * Signs out the current user
   * 
   * @returns {Promise<void>}
   * @throws Will throw an error if logout fails
   */
  const logout = async (): Promise<void> => {
    try {
      await signOut(auth);
    } catch (error) {
      throw error;
    }
  };

  return { 
    loading,
    user, 
    login, 
    signup, 
    logout, 
    signInWithGoogle, 
    signInWithMicrosoft,
    linkAccounts,
    getAuthRedirectResult
  };
}