import { ref, uploadBytes, getDownloadURL, deleteObject, getBlob } from 'firebase/storage';
import { storage } from '../../services/startStorage';

/**
 * Services for managing attachment file storage operations
 * Handles upload, download, and deletion of files in Firebase Storage
 */
export const attachmentStorageService = {
  /**
   * Uploads a file to Firebase Storage
   * 
   * @param file 
   *  The file to upload
   * @param docId 
   *  The document ID associated with this file
   * @param folder 
   *  The storage folder path
   * @returns 
   *  Object containing download URL and storage path
   */
  uploadToStorage: async (
    file: File, 
    docId: string, 
    folder = 'attachments'
  ) => {
    const storageRef = ref(storage, `${folder}/${docId}_${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return { downloadURL, storagePath: storageRef.fullPath };
  },
  
  /**
   * Deletes a file from Firebase Storage
   * 
   * @param storagePath 
   *  The full storage path of the file to delete
   */
  deleteFromStorage: async (
    storagePath: string
  ) => {
    const fileRef = ref(storage, storagePath);
    await deleteObject(fileRef);
  },
  
  /**
   * Downloads a file from Firebase Storage
   * 
   * @param storagePath 
   *  The full storage path of the file to download
   * @returns 
   *  Blob of the downloaded file
   */
  downloadFromStorage: async (
    storagePath: string
  ) => {
    const fileRef = ref(storage, storagePath);
    const blob = await getBlob(fileRef);
    return blob;
  }
}; 