import { FC } from 'react';
import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import Navb from '../components/Navb';
import { Formviews } from '../components/artifacts/Formviews';

const HomePage: FC = () => {
  return (
    <FlexPage>
      <Navb />
      <FlexContent>
        <Formviews />
      </FlexContent>
    </FlexPage>
  );
};

export default HomePage;
