import { getAuth } from 'firebase/auth';
import { environmentType, cloudRunURLs } from '../config';
import { 
    LanguageModelRequest,
    LanguageModelTask,
    LanguageModelTaskResult,
} from '../types/LanguageModel.types';

/** Checks if the Cloud Run emulator is running and returns the appropriate URL */
const getCloudRunURL = async (): Promise<string> => {
    // if (environmentType === 'development') {
    //     try {
    //         const response = await fetch('http://localhost:8081/runlanguagemodel?token=1', {
    //             method: 'POST'
    //         });
    //         if (response.status === 401) {
    //             console.log('Connected to Cloud Run emulator ^^^ 401 Unauthorized is expected');
    //             return 'http://localhost:8081/runlanguagemodel';
    //         }
    //     } catch (error) {
    //         console.log('Cloud Run emulator not connected');
    //     }
    // }
    return cloudRunURLs.runLanguageModel as string;
};

/**
 * Calls the language model service with support for both streaming and task-based responses
 */
export const callRunLanguageModel = async (
    /** The type of request to make */
    requestData: LanguageModelRequest | LanguageModelTask,
    /** Optional callback for streaming data */
    onData?: (chunk: string) => void,
    /** Optional callback for errors */
    onError?: (error: Error) => void,
    /** Optional callback for when the request is complete */
    onComplete?: (result?: LanguageModelTaskResult) => void
) => {
    const url = await getCloudRunURL();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        const error = new Error('User not logged in');
        if (onError) onError(error);
        throw error;
    }

    try {
        const idToken = await user.getIdToken();
        const bodyData = {
            data: requestData,
            idToken: idToken,
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bodyData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Handle task-based responses
        if (requestData.taskType) {
            const result = await response.json() as LanguageModelTaskResult;
            if (onComplete) onComplete(result);
            return result;
        }

        // Handle streaming responses
        if (!response.body) {
            throw new Error('Failed to get readable stream');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const read = async () => {
            try {
                const { done, value } = await reader.read();
                
                if (done) {
                    if (onComplete) onComplete();
                    return;
                }

                const chunk = decoder.decode(value, { stream: true });
                if (onData) onData(chunk);
                read();
            } catch (error) {
                console.error('Stream reading failed:', error);
                if (onError) onError(new Error('Stream reading failed: ' + error));
            }
        };

        read();
    } catch (error) {
        console.error('Error calling the language model service:', error);
        if (onError) onError(error as Error);
        throw error;
    }
};
