import { useState, useCallback } from 'react';
import { useBoundCollection } from './data/useBoundCollection';
import { ListOrderBy, ListFilters } from '../providers/DataProvider';
import { DocDataWithId } from '../types/System.types';
import { DEFAULT_TABLE_CHUNK_SIZE, DEFAULT_PAGES_BEFORE_CURSOR } from '../types/System.Parameters.types';

/** Result object returned by useTable hook containing table state and control functions */
export interface UseTableResult<T extends DocDataWithId> {
    /** Current table data array */
    tableData: T[];
    /** Loading state indicator */
    isLoading: boolean;
    /** Error object if any occurred */
    error: Error | null;
    /** Function to load more data */
    loadMore: () => void;
    /** Function to reset to initial state */
    returnToStart: () => void;
    /** Function to refresh current data */
    refresh: () => void;
    /** Function to update filters */
    setFilters: (filters: ListFilters) => void;
    /** Function to update sort order */
    setOrderBy: (orderBy: ListOrderBy) => void;
    /** Indicates if more data is available */
    hasMore: boolean;
    /** Current sort order configuration */
    currentOrderBy?: ListOrderBy;
    /** Total number of records loaded */
    totalCount: number;
    /** Number of records currently loaded */
    loadedCount: number;
    /** Indicates if page limit is reached */
    isPageLimit: boolean;
}

/** Configuration parameters for useTable hook */
interface UseTableParams {
    /** Collection path in database */
    path: string;
    /** Number of items to load per chunk */
    chunkSize?: number;
    /** Maximum number of items to load */
    maxItems?: number;
    /** Initial sort order configuration */
    initialOrderBy?: ListOrderBy;
    /** Fields that can be sorted */
    sortableFields?: string[];
}

/** Internal pagination state */
interface PaginationState {
    /** Current page number */
    currentPage: number;
    /** Number of times loadMore has been called on current page */
    loadMoreCount: number;
    /** Maximum number of loadMore calls before forcing a new page */
    readonly maxLoadMorePerPage: number;
}

/**
 * Hook for managing paginated table data with sorting and filtering
 * @template T - Document data type extending DocDataWithId
 * @param params - Configuration parameters for the table
 * @returns Table state and control functions
 */
export const useTable = <T extends DocDataWithId>({
    path,
    chunkSize = DEFAULT_TABLE_CHUNK_SIZE,
    initialOrderBy = [{ field: 'meta.lastModified', direction: 'desc' }],
}: UseTableParams): UseTableResult<T> => {
    const [currentOrderBy, setCurrentOrderBy] = useState<ListOrderBy>(initialOrderBy);
    const [pagination, setPagination] = useState<PaginationState>({
        currentPage: 1,
        loadMoreCount: 0,
        maxLoadMorePerPage: DEFAULT_PAGES_BEFORE_CURSOR
    });
    
    const {
        data,
        error,
        loading: isLoading,
        refresh,
        setStartAt,
        setFilters: setBoundFilters,
        setOrderBy: setBoundOrderBy,
        setLimit
    } = useBoundCollection<T>({
        path,
        initialLimit: chunkSize,
        initialOrderBy,
    });

    const hasMore = data.length >= chunkSize;

    const loadMore = useCallback(() => {
        if (isLoading || !hasMore) return;

        if (pagination.loadMoreCount >= pagination.maxLoadMorePerPage) {
            setPagination(prev => ({
                ...prev,
                currentPage: prev.currentPage + 1,
                loadMoreCount: 0
            }));
            setStartAt(data[data.length - 1]);
            setLimit(chunkSize);
        } else {
            setPagination(prev => ({
                ...prev,
                loadMoreCount: prev.loadMoreCount + 1
            }));
            const newLimit = chunkSize * (pagination.loadMoreCount + 2);
            setLimit(newLimit);
        }
    }, [isLoading, hasMore, pagination, chunkSize, data, setStartAt, setLimit]);

    const returnToStart = useCallback(() => {
        setPagination({
            currentPage: 1,
            loadMoreCount: 0,
            maxLoadMorePerPage: DEFAULT_PAGES_BEFORE_CURSOR
        });
        setStartAt(undefined);
        setLimit(chunkSize);
    }, [setStartAt, setLimit, chunkSize]);

    const setFilters = useCallback((filters: ListFilters) => {
        setBoundFilters(filters);
        setPagination({
            currentPage: 1,
            loadMoreCount: 0,
            maxLoadMorePerPage: DEFAULT_PAGES_BEFORE_CURSOR
        });
        setStartAt(undefined);
        setLimit(chunkSize);
    }, [setBoundFilters, setStartAt, setLimit, chunkSize]);

    const setOrderBy = useCallback((orderBy: ListOrderBy) => {
        setCurrentOrderBy(orderBy);
        setBoundOrderBy(orderBy);
        setPagination({
            currentPage: 1,
            loadMoreCount: 0,
            maxLoadMorePerPage: DEFAULT_PAGES_BEFORE_CURSOR
        });
        setStartAt(undefined);
        setLimit(chunkSize);
    }, [setBoundOrderBy, setStartAt, setLimit, chunkSize]);

    const isPageLimit = pagination.loadMoreCount >= pagination.maxLoadMorePerPage;

    return {
        tableData: data,
        isLoading,
        error,
        loadMore,
        returnToStart,
        refresh,
        setFilters,
        setOrderBy,
        hasMore,
        currentOrderBy,
        totalCount: data.length,
        loadedCount: data.length,
        isPageLimit
    };
};