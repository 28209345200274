import { FC } from 'react';
import {
    Table,
    Typography,
    List,
    Grid,
    Button,
} from 'antd';
import {
    VerticalAlignTopOutlined,
    ArrowDownOutlined,
    ArrowRightOutlined
} from '@ant-design/icons';
import { Timestamp } from 'firebase/firestore';
import type { ColumnsType } from 'antd/es/table';
import type { TableProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Form, FormType } from '../../types/System.types';
import FlexCard from '../atoms/FlexCard';
import { UseTableResult } from '../../use/useTable';
import FlexBox from '../atoms/FlexBox';
import { FormPropsUIDescription, formVersionNumberPrefix } from '../../types/System.Parameters.types';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

/** Props for the FormsTable component */
interface FormsTableProps {
    /** Table state and control functions from useTable hook */
    tableProps: UseTableResult<Form>;
    /** Available form types for rendering form type labels */
    formTypes?: FormType[];
}

/**
 * Displays a responsive table of forms with sorting and pagination
 * Renders as a card list on mobile and a full table on desktop
 */
const FormsTable: FC<FormsTableProps> = ({
    tableProps,
    formTypes = []
}) => {
    const navigate = useNavigate();
    const { md } = useBreakpoint();

    const versionLabel = FormPropsUIDescription.formVersionNumber.shortLabel;

    /** Column definitions for the forms table */
    const columns: ColumnsType<Form> = [
        {
            title: 'Form Number',
            dataIndex: 'formNumber',
            key: 'formNumber',
            sorter: true,
            render: (formNumber?: string) => formNumber || '-'
        },
        {
            title: 'Form Type',
            dataIndex: 'formType',
            key: 'formType',
            render: (formTypeId?: string) => {
                if (!formTypeId) return '-';
                const formType = formTypes?.find(ft => ft.docId === formTypeId);
                return formType?.description?.shortLabel || formTypeId;
            },
        },
        {
            title: 'Created',
            dataIndex: ['meta', 'created'],
            key: 'created',
            sorter: true,
            render: (timestamp?: Timestamp) => {
                if (!timestamp) return '-';
                if (timestamp instanceof Timestamp) {
                    return timestamp.toDate().toLocaleString();
                }
                return 'Invalid Date';
            },
        },
        {
            title: 'Last Updated',
            dataIndex: ['meta', 'lastModified'],
            key: 'lastModified',
            sorter: true,
            render: (timestamp?: Timestamp) => {
                if (!timestamp) return '-';
                if (timestamp instanceof Timestamp) {
                    return timestamp.toDate().toLocaleString();
                }
                return 'Invalid Date';
            },
        },
    ];

    const {
        tableData,
        isLoading,
        error,
        loadMore,
        returnToStart,
        hasMore,
        setOrderBy,
        isPageLimit
    } = tableProps;

    /**
     * Handles table sort changes and updates the sort order
     */
    const handleTableChange: TableProps<Form>['onChange'] = (_, __, sorter) => {
        if (sorter && !Array.isArray(sorter) && sorter.order) {
            const direction = sorter.order === 'ascend' ? 'asc' : 'desc';
            const field = Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field as string;
            setOrderBy([{ field, direction }]);
        }
    };

    if (error) {
        console.error('FormsTable: Error:', error);
        return (
            <FlexBox column noGrow>
                <Text type="danger">Error: {error.message}</Text>
                <Button 
                    type="primary" 
                    onClick={() => window.location.reload()}
                >
                    Reload Page
                </Button>
            </FlexBox>
        );
    }

    /** Renders pagination controls for both mobile and desktop views */
    const tableControls = (
        <FlexBox stretch noGrow>
            <Button
                disabled={!hasMore}
                type={isPageLimit ? 'default' : 'link'}
                onClick={loadMore}
                loading={isLoading}
            >
                {isPageLimit ? 'Load Next Page' : 'Load More'} {isPageLimit ? <ArrowRightOutlined /> : <ArrowDownOutlined />}
            </Button>
            <Button
                onClick={returnToStart}
                type="link"
                icon={<VerticalAlignTopOutlined />}
            >
                Return to Start
            </Button>
        </FlexBox>
    );

    if (!md) {
        return (
            <>
                <List
                    dataSource={tableData}
                    loading={isLoading}
                    style={{ width: '100%' }}
                    renderItem={(item: Form) => (
                        <FlexCard
                            key={item.docId}
                            alignStart
                            hoverable
                            onClick={() => navigate(`/forms/${item.docId}`)}
                            style={{
                                marginBottom: 16,
                                cursor: 'pointer',
                                width: '100%'
                            }}
                        >
                            <Title level={5} style={{ margin: 0 }}>
                                {item.title}
                            </Title>
                            <Text>Form Number: {item.formNumber}</Text>
                            <Text>Form Type: {
                                formTypes?.find(ft => ft.docId === item.formType)?.description?.shortLabel
                                || item.formType
                            }</Text>
                            <Text>
                                Created: {
                                    item.meta?.created instanceof Timestamp
                                        ? item.meta.created.toDate().toLocaleString()
                                        : 'Invalid Date'
                                }
                            </Text>
                        </FlexCard>
                    )}
                />
                {tableControls}
            </>
        );
    }

    return (
        <>
            <Table
                style={{ width: '100%' }}
                dataSource={tableData}
                columns={columns}
                loading={isLoading}
                pagination={false}
                size='small'
                onChange={handleTableChange}
                onRow={(record) => ({
                    onClick: () => navigate(`/forms/${record.docId}`),
                    style: { cursor: 'pointer' },
                })}
                scroll={{ x: 'max-content' }}
                showSorterTooltip={false}
                rowKey="docId"
            />
            {tableControls}
        </>
    );
};

export default FormsTable;