import { addDoc, collection, getDoc, deleteDoc, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { firestore } from '../../services/startFirestore';
import { Attachment } from '../../types/System.types';

/**
 * Services for managing attachment document operations in Firestore
 * Handles creating, updating, retrieving, and deleting attachment metadata
 */
export const attachmentDbService = {
  /**
   * Creates a new attachment document in Firestore
   * 
   * @param file 
   *  The file being uploaded
   * @param formVersionPath 
   *  Path to the form version associated with this attachment
   * @param entityRecordPath 
   *  Path to the entity record associated with this attachment
   * @returns 
   *  The ID of the created document
   */
  createAttachmentDoc: async (
    file: File, 
    formVersionPath: string, 
    entityRecordPath: string
  ) => {
    const attachmentData: Omit<Attachment, 'docId' | 'storageRef'> = {
      name: file.name,
      size: file.size,
      type: file.type,
      storageUrl: '',
      storagePath: '',
      formVersionPath,
      entityRecordPath,
      meta: {
        created: Timestamp.now(),
        lastModified: Timestamp.now(),
      }
    };
    
    const attachmentDoc = await addDoc(collection(firestore, 'attachments'), attachmentData);
    return attachmentDoc.id;
  },
  
  /**
   * Updates an attachment document with storage URLs after upload
   * 
   * @param docId 
   *  The document ID to update
   * @param storageUrl 
   *  The public download URL for the file
   * @param storagePath 
   *  The storage path where the file is located
   */
  updateAttachmentUrls: async (
    docId: string, 
    storageUrl: string, 
    storagePath: string
  ) => {
    const attachmentRef = doc(firestore, 'attachments', docId);
    await updateDoc(attachmentRef, {
      storageUrl,
      storagePath
    });
  },
  
  /**
   * Retrieves an attachment document by ID
   * 
   * @param attachmentId 
   *  The ID of the attachment to retrieve
   * @returns 
   *  The attachment document data
   * @throws 
   *  Error if the document doesn't exist
   */
  getAttachmentDoc: async (
    attachmentId: string
  ) => {
    const attachmentRef = doc(firestore, 'attachments', attachmentId);
    const attachmentDoc = await getDoc(attachmentRef);
    if (!attachmentDoc.exists()) {
      throw new Error('Attachment document not found');
    }
    return attachmentDoc.data() as Attachment;
  },
  
  /**
   * Deletes an attachment document from Firestore
   * 
   * @param attachmentId 
   *  The ID of the attachment to delete
   */
  deleteAttachmentDoc: async (
    attachmentId: string
  ) => {
    const attachmentRef = doc(firestore, 'attachments', attachmentId);
    await deleteDoc(attachmentRef);
  }
}; 