import React, { useContext } from 'react';
import { List, Typography, theme } from 'antd';
import { UserOutlined, DeploymentUnitOutlined, LoadingOutlined } from '@ant-design/icons';
import ReactMarkdown, { Components } from 'react-markdown';

import { AIContext } from '../../providers/AIProvider';
import FlexBox from '../atoms/FlexBox';
import FlexCol from '../atoms/FlexCol';

const { useToken } = theme;
const { Text } = Typography;

const MessagesList: React.FC = () => {
  const {
    messages,
    activeMessageContent,
    isLoading,
  } = useContext(AIContext);
  const { token } = useToken();

  const getIcon = (sender: string) => {
    return (
      <FlexBox
        noGrow
        style={{
          color: token.colorPrimary,
          minHeight: 28
        }}
      >
        {sender === 'user' ? <UserOutlined /> : <DeploymentUnitOutlined />}
      </FlexBox>
    );
  };

  const markdownComponents: Components = {
    p: ({ node, children, ...props }: React.PropsWithChildren<{ node?: any }> & React.HTMLAttributes<HTMLParagraphElement>) => (
      <p style={{ margin: 0 }} {...props}>
        {children}
      </p>
    )
  };

  return (
    <FlexCol
      stretch
      justifyEnd
      alignStart
      gap={0}
      style={{
        minWidth: 240,
      }}
    >
      <List
        style={{
          width: '100%',
          marginBottom: 0,
          paddingBottom: 0,
          flexGrow: 1,
        }}
        id='messages-list'
        dataSource={messages}
        rowKey="id"
        renderItem={(item) => (
          <FlexBox alignStart style={{ marginTop: 10 }}>
            {getIcon(item.sender)}
            <FlexBox stretch>
              <Text>
                {item.sender === 'user' ?
                  <b>You</b>
                  :
                  <b>Agent</b>
                }
                <ReactMarkdown
                  components={markdownComponents}
                >
                  {item.content}
                </ReactMarkdown>
              </Text>
            </FlexBox>
          </FlexBox>
        )}
      />
      <FlexBox alignStart style={{ marginTop: 10 }}>
        {(isLoading || activeMessageContent) && (
          <>
            {getIcon('agent')}
            {activeMessageContent ? '' : '...'}
          </>
        )}
        <FlexBox stretch>
          <Text type='secondary'>
            <ReactMarkdown
              components={markdownComponents}
            >
              {activeMessageContent}
            </ReactMarkdown>
          </Text>
        </FlexBox>
      </FlexBox>
    </FlexCol>
  );
};

export default MessagesList;