import { FC, useState } from 'react';
import { Modal, Form, Input, message, Spin } from 'antd';
import { sendEmail } from '../../services/sendEmail';
import { pdfExport } from '../../services/pdfExport';

const { TextArea } = Input;

interface FormEmailModalProps {
    open: boolean;
    onCancel: () => void;
    formId: string;
    formTitle?: string;
    formNumber?: string;
    contentRef: React.RefObject<HTMLDivElement>;
}

/**
 * Modal component for sending forms via email
 */
const FormEmailModal: FC<FormEmailModalProps> = ({
    open,
    onCancel,
    formId,
    formTitle = 'Form',
    formNumber,
    contentRef
}) => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);

    const handleSend = async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();
            
            const pdfBlob = await pdfExport({ 
                contentRef, 
                formData: { formNumber }, 
                versionTitle: formTitle,
                returnBlob: true 
            });

            if (!pdfBlob) {
                throw new Error('Failed to generate PDF');
            }

            const base64pdf = await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = reject;
                reader.readAsDataURL(pdfBlob);
            });

            await sendEmail({
                to: values.to.split(',').map((email: string) => email.trim()),
                subject: values.subject,
                text: values.message || 'Please find the attached form.',
                html: values.message?.replace(/\n/g, '<br/>') || 'Please find the attached form.',
                formId,
                attachments: [{
                    filename: `${formNumber}-${formTitle}.pdf`,
                    content: base64pdf.split('base64,')[1],
                    contentType: 'application/pdf'
                }]
            });

            messageApi.success('Email sent successfully');
            onCancel();
            form.resetFields();
        } catch (error) {
            if (error instanceof Error) {
                messageApi.error(`Failed to send email: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {contextHolder}
            <Modal
                title="Send Form via Email"
                open={open}
                onOk={handleSend}
                onCancel={() => {
                    onCancel();
                    form.resetFields();
                }}
                okText="Send"
                width={600}
                okButtonProps={{ loading }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                >
                    <Form.Item
                        name="to"
                        label="Recipients"
                        rules={[
                            { required: true, message: 'Please enter at least one email address' },
                            { 
                                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+(,\s*[^\s@]+@[^\s@]+\.[^\s@]+)*$/,
                                message: 'Please enter valid email addresses separated by commas'
                            }
                        ]}
                    >
                        <Input placeholder="email@example.com, another@example.com" />
                    </Form.Item>
                    <Form.Item
                        name="subject"
                        label="Subject"
                        initialValue={`${formNumber} ${formTitle}`}
                        rules={[{ required: true, message: 'Please enter a subject' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="message"
                        label="Additional Message"
                    >
                        <TextArea rows={4} placeholder="Optional message to include with the form" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default FormEmailModal; 