import { FC } from 'react';
import { Modal, Typography, Space } from 'antd';
import { GroupMemberItem } from '../../use/useGroupAccess';

const { Text } = Typography;

interface RemoveMemberConfirmModalProps {
    member: GroupMemberItem;
    groupName: string;
    open: boolean;
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    loading?: boolean;
}

export const RemoveMemberConfirmModal: FC<RemoveMemberConfirmModalProps> = ({
    member,
    groupName,
    open,
    onConfirm,
    onCancel,
    loading
}) => {
    return (
        <Modal
            title="Remove Member"
            open={open}
            onOk={onConfirm}
            onCancel={onCancel}
            okText="Remove"
            cancelText="Cancel"
            okButtonProps={{
                danger: true,
                loading
            }}
        >
            <Space direction="vertical">
                <Typography.Text>
                    Are you sure you want to remove{' '}
                    <Text strong>
                        {member.name || member.email || member.docId}
                    </Text>{' '}
                    from{' '}
                    <Text strong>{groupName}</Text>?
                </Typography.Text>
                <Text type="secondary">
                    This action cannot be undone. The user will lose all access to this group.
                </Text>
            </Space>
        </Modal>
    );
};

export default RemoveMemberConfirmModal; 