import { FC, useState } from 'react';
import { Steps, Tag, Badge, Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { ExpandOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import FlexBox from '../../atoms/FlexBox';
import FlexCard from '../../atoms/FlexCard';
import {
    FormStateId,
    formStateDescription,
    formVersionNumberPrefix
} from '../../../types/System.Parameters.types';
import { Form, FormVersion, Meta } from '../../../types/System.types';

const { Text, Title } = Typography;
const FORMS_PER_GROUP = 3;

export interface FormStepItem {
    version: FormVersion;
    form: Form;
    notificationStatus?: 'read' | 'unread';
    formId: string;
    formVersionId: string;
}

const getFormattedTime = (time: Timestamp | undefined) => {
    if (!time || !(time instanceof Timestamp)) return '';
    return dayjs(time.toDate()).fromNow();
};

/**
 * Maps form state to tag color
 */
export const getStateTagColor = (state?: FormStateId): string => {
    switch (state) {
        case 'draft':
            return 'blue';

        case 'underReview':
        case 'inTransit':
            return 'cyan';

        case 'sent':
        case 'approved':
        case 'closed':
        case 'delivered':
        case 'paid':
            return 'green';

        case 'archived':
            return 'purple';

        case 'revision':
        case 'rejected':
        case 'lost':
            return 'red';

        default:
            return 'blue';
    }
};

const StepNumber: FC<{ index: number; state?: FormStateId }> = ({ index, state }) => (
    <Tag
        color={getStateTagColor(state)}
        bordered={false}
        style={{
            width: 32,
            height: 32,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0,
            padding: 0,
            fontSize: 14,
            fontWeight: 500
        }}
    >
        {index + 1}
    </Tag>
);

interface FormStepperProps {
    entityId: string;
    entityName: string;
    items: FormStepItem[];
    onEntityClick?: (entityId: string) => void;
    onNotificationToggle?: (formId: string) => void;
}

/**
 * Card component that displays form steps for an entity
 */
export const FormStepCard: FC<FormStepperProps> = ({
    entityId,
    entityName,
    items,
    onEntityClick,
    onNotificationToggle
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandToggle = () => {
        setIsExpanded(prev => !prev);
    };

    const displayItems = isExpanded ? items : items.slice(0, FORMS_PER_GROUP);
    const shouldShowExpandButton = items.length > FORMS_PER_GROUP;

    return (
        <FlexCard
            key={entityId}
            style={{ width: '100%' }}
            size="small"
        >
            <FlexBox gap={5}>
                <Title level={5} style={{ margin: 0 }}>
                    {entityName}
                </Title>
                <Button
                    type="link"
                    size="small"
                    icon={<ExpandOutlined />}
                    onClick={() => onEntityClick?.(entityId)}
                />
            </FlexBox>
            <Steps
                direction="vertical"
                items={displayItems.map((item, index) => ({
                    icon: <StepNumber index={index} state={item.version.state} />,
                    title: (
                        <FlexBox justifyStart wrap>
                            {item.version.state && (
                                <Tag
                                    color={getStateTagColor(item.version.state)}
                                    bordered={false}
                                    style={{ marginRight: 0 }}
                                >
                                    {formStateDescription[item.version.state]?.shortLabel}
                                </Tag>
                            )}
                            <Tag color="default" style={{ marginRight: 0 }}>
                                {item.version.parentFormType}
                            </Tag>
                            <Link to={`/forms/${item.formId}/versions/${item.formVersionId}`}>
                                <Text strong>
                                    {item.form.title}&nbsp;
                                </Text>
                                {item.form.formNumber && (
                                    <Text type="secondary">
                                        {item?.form?.formNumber}
                                        {item?.version?.formVersionNumber &&
                                            ` ${formVersionNumberPrefix}${item.version.formVersionNumber}`}
                                    </Text>
                                )}
                            </Link>

                        </FlexBox>
                    ),
                    description: (
                        <FlexBox noGrow justifyStart>
                            <Badge
                                dot
                                status={item.notificationStatus === 'unread' ? 'error' : 'default'}
                                style={{ cursor: 'pointer' }}
                                onClick={() => item.form.docId && onNotificationToggle?.(item.form.docId)}
                            />
                            {item.version.meta?.lastModified && (
                                <Text type="secondary">
                                    {getFormattedTime(item.version.meta.lastModified as Timestamp)}
                                </Text>
                            )}
                        </FlexBox>
                    ),
                }))}
            />
            {shouldShowExpandButton && (
                <Button
                    type="text"
                    size="small"
                    style={{
                        marginTop: -15,
                        marginLeft: 45,
                        marginBottom: 10,
                    }}
                    onClick={handleExpandToggle}
                >
                    {isExpanded ? 'Show Less' : `Show ${items.length - FORMS_PER_GROUP} More...`}
                </Button>
            )}
        </FlexCard>
    );
};
