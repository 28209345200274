import React, { useContext, useMemo, useState } from 'react';
import { Typography, Table, message } from 'antd';

import FlexBox from '../atoms/FlexBox';
import { UserContext } from '../../providers/UserProvider';
import { Group, UserDBRole } from '../../types/System.types';
import GroupMembersTable from './GroupMembersTable';
import { GroupMemberItem } from '../../use/useGroupAccess';
import RemoveMemberConfirmModal from '../modals/RemoveMemberConfirmModal';
import { MAX_LIMITED_WIDTH_VIEWPORT_NARROW } from '../../types/System.Parameters.types';

const { Text } = Typography;

interface RemoveModalState {
    member: GroupMemberItem;
    groupName: string;
    removeMember: (memberId: string) => Promise<void>;
    loading?: boolean;
}

const GroupSettingsTab: React.FC = () => {
    const { user, userGroups, groups } = useContext(UserContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [removeModalState, setRemoveModalState] = useState<RemoveModalState | null>(null);

    const isOrganizationAdmin = useMemo(() => {
        return userGroups['organizationAdmin'] === UserDBRole.editor ||
            userGroups['organizationAdmin'] === UserDBRole.owner;
    }, [userGroups]);

    const handleMemberRemove = (
        member: GroupMemberItem,
        groupName: string,
        removeMember: (memberId: string) => Promise<void>
    ) => {
        setRemoveModalState({ member, groupName, removeMember });
    };

    const handleRemoveConfirm = async () => {
        if (!removeModalState) return;

        try {
            setRemoveModalState(prev => ({ ...prev!, loading: true }));
            await removeModalState.removeMember(removeModalState.member.docId);
            messageApi.success('Member removed successfully');
            setRemoveModalState(null);
        } catch (error) {
            messageApi.error('Failed to remove member');
        }
    };

    const handleMessage = {
        success: (message: string) => messageApi.success(message),
        error: (message: string) => messageApi.error(message)
    };

    const groupColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Your Role',
            key: 'role',
            render: (_: any, record: Group) => (
                <Text>{userGroups[record.docId || ''] || 'None'}</Text>
            ),
        },
    ];

    const expandedRowRender = (record: Group) => (
        <GroupMembersTable
            groupId={record.docId || ''}
            isOrganizationAdmin={isOrganizationAdmin}
            user={user}
            groupName={record.name}
            onMemberRemove={handleMemberRemove}
            onMessage={handleMessage}
        />
    );

    return (
        <FlexBox
            column
            alignStart
            justifyStart
            noGrow
            stretch
            style={{
                width: '100%',
                maxWidth: MAX_LIMITED_WIDTH_VIEWPORT_NARROW
            }}
        >
            {contextHolder}
            {isOrganizationAdmin
                ? <Text type="warning">
                    You have administrator access to manage groups
                </Text>
                : <Text type="secondary">
                    Your group memberships
                </Text>
            }
            <Table<Group>
                style={{
                    width: '100%'
                }}
                dataSource={isOrganizationAdmin
                    ? groups
                    : groups.filter(group => userGroups[group.docId || ''])
                }
                columns={groupColumns}
                rowKey="docId"
                expandable={{
                    expandedRowRender,
                }}
            />
            {removeModalState && (
                <RemoveMemberConfirmModal
                    member={removeModalState.member}
                    groupName={removeModalState.groupName}
                    open={true}
                    onConfirm={handleRemoveConfirm}
                    onCancel={() => setRemoveModalState(null)}
                    loading={removeModalState.loading}
                />
            )}
        </FlexBox>
    );
};

export default GroupSettingsTab; 