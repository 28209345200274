import { theme } from 'antd';
import { CSSProperties } from 'react';

const { useToken } = theme;

/** Defines color schemes for different element states */
interface EditorColors {
    /** Border color in normal state */
    border: string;
    /** Background color in normal state */
    background: string;
    /** Border color when selected */
    selectedBorder: string;
    /** Background color when selected */
    selectedBackground: string;
}

/** Props for generating element styles */
interface StyleProps {
    /** Whether the element is a container (Grid, Row, Column) */
    isContainer: boolean;
    /** Whether the element is a Column */
    isColumn: boolean;
    /** Whether the element is currently being dragged */
    isDragging: boolean;
    /** Whether the element is selected */
    isSelected: boolean;
    /** Whether the element is a meta field */
    isMeta: boolean;
    /** Whether the element is a field */
    isField: boolean;
    /** Whether the element is a form property */
    isFormProp?: boolean;
    /** Whether something is being dragged over this element */
    isOver: boolean;
    /** CSS transition string */
    transition?: string;
    /** Additional style overrides */
    style?: CSSProperties;
    /** Element-specific style props */
    elementProps?: { style?: CSSProperties };
}

/** Hook providing style functions for LayoutEditorElement */
export const useLayoutEditorStyles = () => {
    const { token } = useToken();
    const opacity = '25';

    const editorColors: Record<string, EditorColors> = {
        container: {
            border: token['green-6'],
            background: token['green-6'] + opacity,
            selectedBorder: token['green-9'],
            selectedBackground: token['green-9'] + opacity,
        },
        field: {
            border: token['blue-6'],
            background: token['blue-6'] + opacity,
            selectedBorder: token['blue-9'],
            selectedBackground: token['blue-9'] + opacity,
        },
        entity: {
            border: token['gold-6'],
            background: token['gold-6'] + opacity,
            selectedBorder: token['gold-9'],
            selectedBackground: token['gold-9'] + opacity,
        },
        meta: {
            border: token['purple-6'],
            background: token['purple-6'] + opacity,
            selectedBorder: token['purple-9'],
            selectedBackground: token['purple-9'] + opacity,
        },
        formProp: {
            border: token['yellow-6'],
            background: token['yellow-6'] + opacity,
            selectedBorder: token['yellow-9'],
            selectedBackground: token['yellow-9'] + opacity,
        }
    };

    /** Generates the main element container styles */
    const getElementStyle = ({
        isContainer,
        isColumn,
        isDragging,
        isSelected,
        isMeta,
        isField,
        isOver,
        transition,
        style,
        elementProps
    }: StyleProps): CSSProperties => ({
        display: 'flex',
        flexDirection: isContainer ? (isColumn ? 'column' : 'row') : 'row',
        justifyContent: isContainer ? undefined : 'flex-start',
        alignItems: isContainer ? 'flex-start' : 'center',
        flexGrow: isContainer ? 0 : 1,
        alignSelf: 'stretch',
        gap: 10,
        padding: isContainer ? '20px 20px 10px 10px' : '5px 5px 5px 10px',
        borderRadius: token.borderRadiusLG,
        minHeight: isContainer ? 50 : 30,
        minWidth: 120,
        transition,
        position: 'relative',
        cursor: isDragging ? 'grabbing' : 'grab',
        border: `1px ${isContainer ? 'dotted' : 'dashed'} ${
            isSelected
                ? editorColors[isContainer ? 'container' : (isMeta ? 'meta' : (isField ? 'field' : 'entity'))].selectedBorder
                : editorColors[isContainer ? 'container' : (isMeta ? 'meta' : (isField ? 'field' : 'entity'))].border
        }`,
        backgroundColor: isSelected
            ? editorColors[isContainer ? 'container' : (isMeta ? 'meta' : (isField ? 'field' : 'entity'))].selectedBackground
            : isOver
                ? token.colorSuccess + '33'
                : editorColors[isContainer ? 'container' : (isMeta ? 'meta' : (isField ? 'field' : 'entity'))].background,
        ...style,
        ...(elementProps?.style || {})
    });

    /** Generates styles for the element type label */
    const getTypeTextStyle = (): CSSProperties => ({
        fontSize: token.fontSizeSM,
        position: 'absolute',
        left: 10,
        top: -2
    });

    /** Generates styles for the meta field icon */
    const getMetaIconStyle = (): CSSProperties => ({
        marginLeft: 8,
        color: token.purple
    });

    /** Generates styles for the drag handle icon */
    const getDragHandleStyle = (isContainer: boolean, isMeta: boolean, isField: boolean): CSSProperties => ({
        color: editorColors[isContainer ? 'container' : (isMeta ? 'meta' : (isField ? 'field' : 'entity'))].border,
        position: 'absolute',
        right: 2,
        top: '50%',
        transform: 'translateY(-50%)'
    });

    return {
        getElementStyle,
        getTypeTextStyle,
        getMetaIconStyle,
        getDragHandleStyle,
        editorColors
    };
}; 