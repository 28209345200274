import { FC, useContext, useState } from 'react';
import { Modal, Typography, message, Dropdown, Button } from 'antd';
import { FormContext } from '../../../providers/artifacts/FormProvider';
import { FormVersionContext } from '../../../providers/artifacts/FormVersionProvider';
import { setAsCurrentVersion } from '../../../services/artifactFunctions';
import { DownOutlined } from '@ant-design/icons';
import { FormPropsUIDescription, formVersionNumberPrefix } from '../../../types/System.Parameters.types';
import FlexCol from '../../atoms/FlexCol';

/**
 * Interface defining the props for the SelectCurrentVersion component
 * 
 * @interface SelectCurrentVersionProps
 * @property {boolean} [disabled] - Whether the dropdown should be disabled
 * @property {boolean} [divider] - Whether to show a divider above the dropdown
 */
interface SelectCurrentVersionProps {
  disabled?: boolean;
}

const { Text } = Typography;

/**
 * Component that provides a dropdown interface for setting a form version as the current version
 * 
 * This component:
 * - Displays a dropdown with all available form versions
 * - Provides visual indication of which version is currently set as the current version
 * - Shows a confirmation modal before changing the current version
 * - Makes the API call to update the current version when confirmed
 * - Handles loading states and error messages
 * 
 * @component
 * @param {SelectCurrentVersionProps} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const SelectCurrentVersion: FC<SelectCurrentVersionProps> = ({
  disabled = false
}) => {
  const { selectedForm } = useContext(FormContext);
  const { formVersions } = useContext(FormVersionContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [versionToSetAsCurrent, setVersionToSetAsCurrent] = useState<string | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  /**
   * Handles the selection of a version from the dropdown
   * Opens the confirmation modal and stores the selected version ID
   * 
   * @param {string} versionId - The ID of the selected version
   * @param {string} versionNumber - The version number of the selected version (for display)
   */
  const handleVersionSelect = (versionId: string, versionNumber: string) => {
    setVersionToSetAsCurrent(versionId);
    setIsModalOpen(true);
  };

  /**
   * Handles the confirmation of setting a new current version
   * Makes the API call to update the current version and shows success/error messages
   * 
   * @async
   * @returns {Promise<void>}
   */
  const handleConfirm = async () => {
    if (!selectedForm.data?.docId || !versionToSetAsCurrent) {
      messageApi.error('Form or version information is missing');
      return;
    }

    setIsLoading(true);
    try {
      await setAsCurrentVersion(
        selectedForm.data.docId,
        versionToSetAsCurrent
      );

      const versionNumber = formVersions.data?.find(v => v.docId === versionToSetAsCurrent)?.formVersionNumber;
      messageApi.success(`Version ${versionNumber} set as current`);
    } catch (error) {
      messageApi.error(`Failed to set current version: ${error instanceof Error ? error.message : String(error)}`);
      console.error('Error setting current version:', error);
    } finally {
      setIsModalOpen(false);
      setIsLoading(false);
      setVersionToSetAsCurrent(null);
    }
  };

  /**
   * Handles cancellation of the confirmation modal
   * Closes the modal and resets the selected version
   */
  const handleCancel = () => {
    setIsModalOpen(false);
    setVersionToSetAsCurrent(null);
  };

  const label = FormPropsUIDescription.formVersionNumber.shortLabel;

  // Create dropdown menu items for each form version
  const items = formVersions.data?.map((version) => {
    const isCurrentVersion = selectedForm.data?.currentVersionId === version.docId;

    return {
      key: version.docId || '',
      label: (
        <div onClick={() => handleVersionSelect(version.docId || '', String(version.formVersionNumber || ''))}>
          {formVersionNumberPrefix}{version.formVersionNumber}
          <Text 
            type='success' 
            style={{ 
              color: isCurrentVersion ? '' : 'transparent' 
            }}
          >
            &nbsp;current
          </Text>
        </div>
      ),
      disabled: disabled || isCurrentVersion,
    };
  }) || [];

  const selectedVersionNumber = versionToSetAsCurrent ?
    formVersions.data?.find(v => v.docId === versionToSetAsCurrent)?.formVersionNumber : '';

  return (
    <FlexCol style={{ width: '100%' }}>
      {contextHolder}
      <Dropdown 
        menu={{ items }} 
        disabled={disabled || !formVersions.data?.length}
      >
        <Button
          type='text'
          size='small'
          style={{ justifyContent: 'flex-start' }}
        >
          Set Current {label}
          <DownOutlined />
        </Button>
      </Dropdown>

      <Modal
        title="Set Current Version"
        open={isModalOpen}
        onOk={handleConfirm}
        onCancel={handleCancel}
        confirmLoading={isLoading}
        okText="Yes"
        cancelText="No"
      >
        <Text>
          Are you sure you want to change the current {label}?<br />
          <br />
          Form<br />
          <b>{selectedForm.data?.formNumber}</b><br />
          New Current<br />
          <b>{selectedVersionNumber ? formVersionNumberPrefix + selectedVersionNumber : 'Selected ' + label}</b>
        </Text>
      </Modal>
    </FlexCol>
  );
};

export default SelectCurrentVersion; 