/**
 * @fileoverview Renders a read-only meta field UI component for Form metadata.
 */

import { FC, memo } from 'react';
import { theme, Tooltip, Input } from 'antd';
import { 
    UIMetaField, 
    AgentType, 
    Meta
} from '../../types/System.types';
import { MetaFieldUIDescription } from '../../types/System.Parameters.types';
import { useUser } from '../../use/useUser';
import FlexCol from '../atoms/FlexCol';
import FieldLabel from '../atoms/FieldLabel';
import { useFormAndEntityContexts } from '../../use/useFormAndEntityContexts';

interface MetaFieldUIProps {
    /** Configuration object containing meta field properties */
    uiMetaField: UIMetaField;
}

/**
 * Formats a meta value based on its type for display
 */
const formatMetaValue = (value: any, id: keyof Meta): string => {
    if (!value) return '';
    
    if (value.toDate) {
        return value.toDate().toLocaleString();
    }
    
    if (Object.values(AgentType).includes(value)) {
        return value.toString();
    }
    
    return value.toString();
};

/**
 * A read-only component that displays a single meta value from a Form.
 * 
 * @component
 * @memo
 */
const MetaFieldUI: FC<MetaFieldUIProps> = memo(({
    uiMetaField,
}) => {
    const { token } = theme.useToken();
    const { data } = useFormAndEntityContexts();
    const { id } = uiMetaField;
    const metaValue = data?.meta?.[id];
    const formattedValue = formatMetaValue(metaValue, id);

    const { 
        user 
    } = useUser({ 
        userId: id === 'userId' ? metaValue : undefined,
        enabled: id === 'userId' && !!metaValue
    });

    const name = user?.data?.name ?? '';
    const email = user?.data?.email ?? '';

    const displayValue = id === 'userId' && name
        ? `${name}(${email})`
        : formattedValue;

    const label = id in MetaFieldUIDescription 
        ? MetaFieldUIDescription[id as keyof typeof MetaFieldUIDescription].shortLabel 
        : id;
    const tooltip = id in MetaFieldUIDescription
        ? MetaFieldUIDescription[id as keyof typeof MetaFieldUIDescription].longDescription
        : `${id} value`;
    
    return (
        <FlexCol gap={0} {...uiMetaField.props}>
            <FieldLabel
                label={label}
                tooltip={tooltip}
            />
            <Input 
                value={displayValue} 
                readOnly 
                variant='filled' 
                style={{
                    cursor: 'default',
                    backgroundColor: token.colorBgTextActive,
                }}
            />
        </FlexCol>
    );
});

MetaFieldUI.displayName = 'MetaFieldUI';

export default MetaFieldUI; 