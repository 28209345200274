import React, { createContext, useEffect, ReactNode, useContext, useState, useCallback } from 'react';
import useFirebaseAuth from '../use/useFirebaseAuth';
import { H } from 'highlight.run';
import { User } from 'firebase/auth';
import { loadSpace, InitOptions } from '@usersnap/browser';
import { collection, getDocs, doc, runTransaction, arrayRemove, arrayUnion, updateDoc } from 'firebase/firestore';
import { DataContext } from './DataProvider';
import debounce from 'lodash/debounce';

import {
    usersnapSpaceId,
    highlightId,
    environmentType,
    firebaseConfig,
} from '../config';
import { useBoundDoc } from '../use/data/useBoundDoc';
import { useSet } from '../use/data/useSet';
import { UserDBRole, Notification, DocDataWithId, UserSettings, EmailAnalysisNotificationData } from '../types/System.types';
import { Group } from '../types/System.types';
import { useBoundCollection } from '../use/data/useBoundCollection';

/** Represents the target state for a notification */
export enum NotificationReadState {
    READ = 'READ',
    UNREAD = 'UNREAD'
}

export interface HomePageFilters {
    entitySelection: {
        entityId: string | null;
        groupId: string | null;
    } | null;
    dateRange: [string, string]; // ISO format dates
    selectedFormTypes: string[];
    selectedFormStates: string[];
    filterMode: string;
}

export interface ExtendedUserSettings extends UserSettings {
    homePageFilters?: HomePageFilters;
}

/**
 * Context type providing user authentication and management functionality.
 * Handles user auth state, settings, notifications, and form subscriptions.
 */
export interface UserContextType {
    /** Current authenticated Firebase user */
    user: User | null;
    /** Logs out current user and clears auth state */
    logout: () => Promise<void>;
    /** Whether a user is currently authenticated */
    isLoggedIn: boolean;
    /** Whether authentication state is still being determined */
    loading: boolean;
    /** User preferences and application settings */
    settings: UserSettings | null;
    /** Updates user settings and persists to database */
    updateSettings: (newSettings: UserSettings) => void;
    /** Map of group IDs to user's role in each group */
    userGroups: { [groupId: string]: UserDBRole };
    /** List of all available groups in the system */
    groups: Group[];
    /** List of unread notifications for current user */
    unreadNotifications?: Notification[];
    /** List of read notifications for current user */
    readNotifications?: Notification[];
    /** Toggles read/unread status of a notification */
    toggleNotificationRead: (notification: Notification) => Promise<void>;
    /** Update notification content and set its read status */
    updateNotificationAndSetReadStatus: (notification: Notification, newData: any, updatedMessage?: string, targetReadState?: NotificationReadState) => Promise<void>;
    /** Update notification data and mark as read */
    updateNotificationDataAndMarkAsRead: (notification: Notification, newData: any, updatedMessage?: string) => Promise<void>;
    /** Update notification data and mark as unread */
    updateNotificationDataAndMarkAsUnread: (notification: Notification, newData: any, updatedMessage?: string) => Promise<void>;
    /** Subscribes current user to notifications for a form */
    subscribeToForm: (formId: string) => Promise<void>;
    /** Unsubscribes current user from form notifications */
    unsubscribeFromForm: (formId: string) => Promise<void>;
    /** Subscribes current user to notifications for an entity record */
    subscribeToEntityRecord: (entityId: string, recordId: string) => Promise<void>;
    /** Unsubscribes current user from entity record notifications */
    unsubscribeFromEntityRecord: (entityId: string, recordId: string) => Promise<void>;
    /** List of all users in the system */
    users: DocDataWithId[];
    /** Assigns a form to a specific user or clears assignment */
    assignFormToUser: (formId: string, userId?: string | null) => Promise<void>;
    /** Updates the list of users subscribed to form notifications */
    updateFormSubscribers: (formId: string, userIds: string[]) => Promise<void>;
}

/**
 * Props for UserProvider component
 */
interface UserProviderProps {
    /** Child components to be wrapped by the provider */
    children: ReactNode;
}

/**
 * Default context value with empty implementations
 */
const defaultUserContextValue: UserContextType = {
    user: null,
    logout: async () => { },
    isLoggedIn: false,
    loading: true,
    settings: {
        preferDarkMode: 'system',
    } as ExtendedUserSettings,
    updateSettings: () => { },
    userGroups: {},
    groups: [],
    unreadNotifications: undefined,
    toggleNotificationRead: async () => { },
    updateNotificationAndSetReadStatus: async () => { },
    updateNotificationDataAndMarkAsRead: async () => { },
    updateNotificationDataAndMarkAsUnread: async () => { },
    subscribeToForm: async () => { },
    unsubscribeFromForm: async () => { },
    subscribeToEntityRecord: async () => { },
    unsubscribeFromEntityRecord: async () => { },
    users: [],
    assignFormToUser: async () => { },
    updateFormSubscribers: async () => { },
};

/**
 * Context for user authentication and management functionality
 */
export const UserContext = createContext<UserContextType>(defaultUserContextValue);

interface UserProviderProps {
    children: ReactNode;
}

if (window.location.hostname.split(':')[0] !== 'localhost') {
    const h = H.init(highlightId, {
        serviceName: 'Syncretic System',
        tracingOrigins: true,
        manualStart: true,
        networkRecording: {
            enabled: true,
            recordHeadersAndBody: true,
        },
    });
    console.log(`H Analytics:`, h?.sessionSecureID);
} else {
    console.log('H Analytics disabled.');
}

/**
 * Provider component that manages user authentication state and related functionality.
 * Handles user settings, notifications, form subscriptions, and analytics integration.
 */
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const { set } = useSet();
    const { firestore } = useContext(DataContext);
    const { user, logout, loading } = useFirebaseAuth();

    const settings = useBoundDoc<UserSettings>({ 
        path: 'users', 
        docId: user?.uid, 
        enabled: !!user 
    });

    const [userGroups, setUserGroups] = useState<{ [groupId: string]: UserDBRole }>({});
    const [groups, setGroups] = useState<Group[]>([]);

    const users = useBoundCollection({
        path: 'users',
        enabled: true
    });

    useEffect(() => {
        const fetchGroups = async () => {
            if (!user?.uid) return;

            const groupRoles: { [groupId: string]: UserDBRole } = {};
            const groupsRef = collection(firestore, 'groups');
            const groupsSnap = await getDocs(groupsRef);
            const fetchedGroups: Group[] = [];

            for (const groupDoc of groupsSnap.docs) {
                const groupData = groupDoc.data() as Group;
                fetchedGroups.push({ ...groupData, docId: groupDoc.id });

                const membersRef = collection(firestore, `groups/${groupDoc.id}/groupMembers`);
                const membersSnap = await getDocs(membersRef);
                const userMember = membersSnap.docs.find(doc => doc.id === user.uid);

                if (userMember?.data()?.role) {
                    groupRoles[groupDoc.id] = userMember.data().role;
                }
            }

            setGroups(fetchedGroups);
            setUserGroups(groupRoles);
        };

        fetchGroups();
    }, [firestore, user?.uid]);

    useEffect(() => {
        if (user && user.email) {
            if (window.location.hostname.split(':')[0] !== 'localhost') {
                H.identify(user.email, {
                    id: user.uid,
                    highlightDisplayName: user.email,
                });
                H.start()
                console.log(`H Analytics Started, user:`, user.uid, user.email);
            } else {
                console.log('H Analytics disabled (user).');
            }

            loadSpace(usersnapSpaceId).then((api) => {
                api.init({
                    user: {
                        email: user?.email,
                        userId: user?.uid,
                    },
                    custom: {
                        displayName: user?.displayName,
                        userId: user?.uid,
                        email: user?.email,
                        firebaseProjectId: firebaseConfig.projectId,
                        environmentType,
                    },
                } as InitOptions);
                console.log('Usersnap API initialized');
            }).catch((error) => {
                console.error('Failed to initialize Usersnap API', error);
            });
        }
    }, [user]);

    /**
     * Debounced function to update user settings in the database
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateSettings = useCallback(
        debounce(async (newSettings: UserSettings) => {
            if (user) {
                await set('users', user.uid, newSettings);
            }
        }, 1500),
        [user, set]
    );

    /**
     * Updates user settings and triggers debounced save to database
     */
    const updateSettings = useCallback((newSettings: UserSettings) => {
        debouncedUpdateSettings(newSettings);
    }, [debouncedUpdateSettings]);

    /**
     * Toggles the read status of a notification in Firestore
     */
    const toggleNotificationRead = useCallback(async (notification: Notification) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!notification?.notificationEvent?.docId) {
            throw new Error('Invalid notification data');
        }

        try {
            await runTransaction(firestore, async (transaction) => {
                const userRef = doc(firestore, 'users', user.uid);
                const userDoc = await transaction.get(userRef);

                if (!userDoc.exists()) {
                    throw new Error('User document not found');
                }

                const isRead = settings.data?.readNotifications?.some((n: Notification) =>
                    n.notificationEvent.docId === notification.notificationEvent.docId
                );

                if (isRead) {
                    transaction.update(userRef, {
                        readNotifications: arrayRemove(notification),
                        unreadNotifications: arrayUnion(notification)
                    });
                } else {
                    transaction.update(userRef, {
                        unreadNotifications: arrayRemove(notification),
                        readNotifications: arrayUnion(notification)
                    });
                }
            });
        } catch (error) {
            console.error('Failed to toggle notification:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to toggle notification: ${error.message}`);
            }
            throw new Error('Failed to toggle notification: Unknown error occurred');
        }
    }, [user, firestore, settings.data]);

    /** Update notification content and set its read status */
    const updateNotificationAndSetReadStatus = useCallback(async (
        notification: Notification, 
        newData: any,
        updatedMessage?: string,
        targetReadState: NotificationReadState = NotificationReadState.READ
    ) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!notification?.notificationEvent?.docId) {
            throw new Error('Invalid notification data');
        }

        try {
            await runTransaction(firestore, async (transaction) => {
                const userRef = doc(firestore, 'users', user.uid);
                const userDoc = await transaction.get(userRef);

                const notificationRef = doc(firestore, 'notificationEvents', notification.notificationEvent.docId ?? '');
                const notificationDoc = await transaction.get(notificationRef);

                if (!notificationDoc.exists()) {
                    throw new Error('Notification document not found');
                }

                if (!userDoc.exists()) {
                    throw new Error('User document not found');
                }

                // Update the notification data
                const updatedData = {
                    ...notification.notificationEvent.data,
                    ...newData
                };

                const updates: any = { data: updatedData };
                if (updatedMessage !== undefined) {
                    updates.message = updatedMessage;
                }

                transaction.update(notificationRef, updates);

                // make a copy of the notification with the new data and message
                const updatedNotification = {
                    ...notification,
                    notificationEvent: {
                        ...notification.notificationEvent,
                        data: updatedData,
                        ...(updatedMessage !== undefined && { message: updatedMessage })
                    }
                };

                // update the user document based on target read state
                if (targetReadState === NotificationReadState.READ) {
                    transaction.update(userRef, {
                        unreadNotifications: arrayRemove(notification),
                        readNotifications: arrayUnion(updatedNotification)
                    });
                } else {
                    transaction.update(userRef, {
                        readNotifications: arrayRemove(notification),
                        unreadNotifications: arrayUnion(updatedNotification)
                    });
                }
            });

        } catch (error) {
            console.error('Failed to update notification data:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to update notification data: ${error.message}`);
            }
            throw new Error('Failed to update notification data: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Update notification data and mark as read */
    const updateNotificationDataAndMarkAsRead = useCallback(async (
        notification: Notification,
        newData: any,
        updatedMessage?: string
    ) => {
        return updateNotificationAndSetReadStatus(notification, newData, updatedMessage, NotificationReadState.READ);
    }, [updateNotificationAndSetReadStatus]);

    /** Update notification data and mark as unread */
    const updateNotificationDataAndMarkAsUnread = useCallback(async (
        notification: Notification,
        newData: any,
        updatedMessage?: string
    ) => {
        return updateNotificationAndSetReadStatus(notification, newData, updatedMessage, NotificationReadState.UNREAD);
    }, [updateNotificationAndSetReadStatus]);

    /**
     * Subscribes the current user to notifications for a specific form
     */
    const subscribeToForm = useCallback(async (formId: string) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!formId) {
            throw new Error('Form ID is required');
        }

        try {
            await runTransaction(firestore, async (transaction) => {
                const formRef = doc(firestore, 'forms', formId);
                const formDoc = await transaction.get(formRef);

                if (!formDoc.exists()) {
                    throw new Error(`Form not found: ${formId}`);
                }

                transaction.update(formRef, {
                    notificationsSubscribers: arrayUnion(user.uid)
                });
            });
        } catch (error) {
            console.error('Failed to subscribe to form:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to subscribe to form: ${error.message}`);
            }
            throw new Error('Failed to subscribe to form: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Unsubscribe from notifications for a form */
    const unsubscribeFromForm = useCallback(async (formId: string) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!formId) {
            throw new Error('Form ID is required');
        }

        try {
            await runTransaction(firestore, async (transaction) => {
                const formRef = doc(firestore, 'forms', formId);
                const formDoc = await transaction.get(formRef);

                if (!formDoc.exists()) {
                    throw new Error(`Form not found: ${formId}`);
                }

                transaction.update(formRef, {
                    notificationsSubscribers: arrayRemove(user.uid)
                });
            });
        } catch (error) {
            console.error('Failed to unsubscribe from form:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to unsubscribe from form: ${error.message}`);
            }
            throw new Error('Failed to unsubscribe from form: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Subscribe to notifications for an entity record */
    const subscribeToEntityRecord = useCallback(async (entityId: string, recordId: string) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!entityId || !recordId) {
            throw new Error('Entity ID and Record ID are required');
        }

        try {
            await runTransaction(firestore, async (transaction) => {
                const recordRef = doc(firestore, `entities/${entityId}/records`, recordId);
                const recordDoc = await transaction.get(recordRef);

                if (!recordDoc.exists()) {
                    throw new Error(`Entity record not found: ${entityId}/${recordId}`);
                }

                transaction.update(recordRef, {
                    notificationsSubscribers: arrayUnion(user.uid)
                });
            });
        } catch (error) {
            console.error('Failed to subscribe to entity record:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to subscribe to entity record: ${error.message}`);
            }
            throw new Error('Failed to subscribe to entity record: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Unsubscribe from notifications for an entity record */
    const unsubscribeFromEntityRecord = useCallback(async (entityId: string, recordId: string) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!entityId || !recordId) {
            throw new Error('Entity ID and Record ID are required');
        }

        try {
            await runTransaction(firestore, async (transaction) => {
                const recordRef = doc(firestore, `entities/${entityId}/records`, recordId);
                const recordDoc = await transaction.get(recordRef);

                if (!recordDoc.exists()) {
                    throw new Error(`Entity record not found: ${entityId}/${recordId}`);
                }

                transaction.update(recordRef, {
                    notificationsSubscribers: arrayRemove(user.uid)
                });
            });
        } catch (error) {
            console.error('Failed to unsubscribe from entity record:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to unsubscribe from entity record: ${error.message}`);
            }
            throw new Error('Failed to unsubscribe from entity record: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Assign a form to a user or clear assignment */
    const assignFormToUser = useCallback(async (formId: string, userId?: string | null) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!formId) {
            throw new Error('Form ID is required');
        }

        try {
            await runTransaction(firestore, async (transaction) => {
                const formRef = doc(firestore, 'forms', formId);
                const formDoc = await transaction.get(formRef);

                if (!formDoc.exists()) {
                    throw new Error(`Form not found: ${formId}`);
                }

                const assigneeId = userId === undefined ? user.uid : userId;

                if (assigneeId !== null) {
                    const userRef = doc(firestore, 'users', assigneeId);
                    const userDoc = await transaction.get(userRef);
                    if (!userDoc.exists()) {
                        console.error(`Target user not found: ${assigneeId}`);
                        throw new Error(`Target user not found: ${assigneeId}`);
                    }
                }

                transaction.update(formRef, {
                    assignedTo: assigneeId,
                    meta: {
                        lastModified: new Date(),
                        userId: user.uid,
                    }
                });
            });
        } catch (error) {
            console.error('Failed to assign form:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to assign form: ${error.message}`);
            }
            throw new Error('Failed to assign form: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Update form notification subscribers */
    const updateFormSubscribers = useCallback(async (formId: string, userIds: string[]) => {
        if (!user?.uid) {
            throw new Error('Authentication required');
        }

        if (!formId) {
            throw new Error('Form ID is required');
        }

        try {
            await runTransaction(firestore, async (transaction) => {
                const formRef = doc(firestore, 'forms', formId);
                const formDoc = await transaction.get(formRef);

                if (!formDoc.exists()) {
                    throw new Error(`Form not found: ${formId}`);
                }

                // Validate all user IDs exist
                for (const userId of userIds) {
                    const userRef = doc(firestore, 'users', userId);
                    const userDoc = await transaction.get(userRef);
                    if (!userDoc.exists()) {
                        throw new Error(`User not found: ${userId}`);
                    }
                }

                transaction.update(formRef, {
                    notificationsSubscribers: userIds,
                    meta: {
                        lastModified: new Date(),
                        userId: user.uid,
                    }
                });
            });
        } catch (error) {
            console.error('Failed to update form subscribers:', error);
            if (error instanceof Error) {
                throw new Error(`Failed to update form subscribers: ${error.message}`);
            }
            throw new Error('Failed to update form subscribers: Unknown error occurred');
        }
    }, [user, firestore]);

    /** Combined context value */
    const userValue: UserContextType = {
        user,
        logout,
        isLoggedIn: user !== null,
        loading,
        settings: settings.data,
        updateSettings,
        userGroups,
        groups,
        unreadNotifications: settings.data?.unreadNotifications ?? [],
        readNotifications: settings.data?.readNotifications ?? [],
        toggleNotificationRead,
        updateNotificationAndSetReadStatus,
        updateNotificationDataAndMarkAsRead,
        updateNotificationDataAndMarkAsUnread,
        subscribeToForm,
        unsubscribeFromForm,
        subscribeToEntityRecord,
        unsubscribeFromEntityRecord,
        users: users.data ?? [],
        assignFormToUser,
        updateFormSubscribers,
    };

    return (
        <UserContext.Provider value={userValue}>
            {children}
        </UserContext.Provider>
    );
};