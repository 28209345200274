import { FC, useState, useEffect } from 'react';
import { Modal, Button, Typography, Divider, Spin } from 'antd';
import { useFormviews } from '../../use/useFormviews';
import { FormviewsControls } from './formviews/FormviewsControls';
import { FormviewsList } from './formviews/FormviewsList';
import { EntityRecordModal } from '../modals/EntityRecordModal';
import FlexCol from '../atoms/FlexCol';
import { MAX_LIMITED_WIDTH_VIEWPORT } from '../../types/System.Parameters.types';

const { Text } = Typography;

/**
 * Main component for displaying form views on the homepage
 */
export const Formviews: FC = () => {

  const [showLimitModal, setShowLimitModal] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<string>();

  const {
    filters,
    filterActions,
    results,
    utils
  } = useFormviews();

  useEffect(() => {
    if (results.hasReachedLimit) {
      setShowLimitModal(true);
    }
  }, [results.hasReachedLimit]);

  return (
    <FlexCol style={{ maxWidth: MAX_LIMITED_WIDTH_VIEWPORT }}>
      <FormviewsControls
        filters={filters}
        filterActions={filterActions}
        isLoading={results.isLoading}
        utils={utils}
      />
      <Divider style={{ margin: 0 }} />

      {results.isLoading ? (
        <Spin size='large' style={{ margin: 'auto' }} />
      ) : results.error ? (
        <Text type="danger">Error loading forms: {results.error.message}</Text>
      ) : (
        <FormviewsList
          groupedResults={results.groupedResults}
          onEntityClick={setSelectedRecordId}
        />
      )}

      <Modal
        title="Form Limit Reached"
        open={showLimitModal}
        onCancel={() => setShowLimitModal(false)}
        footer={[
          <Button key="ok" type="primary" onClick={() => setShowLimitModal(false)}>
            OK
          </Button>,
        ]}
      >
        <Text>
          Showing the most recent forms. To see older forms, please adjust your date range.
        </Text>
      </Modal>

      <EntityRecordModal
        entityId={filters.entitySelection?.entityId || 'Projects'}
        recordId={selectedRecordId}
        onClose={() => setSelectedRecordId(undefined)}
      />
    </FlexCol>
  );
}; 