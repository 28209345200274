import { FC } from 'react';
import {
    Button,
    Input,
    Select,
    Popconfirm,
    Tooltip,
    Typography,
    theme
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import FlexBox from '../../atoms/FlexBox';
import FlexCol from '../../atoms/FlexCol';
import FlexCard from '../../atoms/FlexCard';
import { MAX_LIMITED_WIDTH_VIEWPORT } from '../../../types/System.Parameters.types';
import { useBoundCollection } from '../../../use/data/useBoundCollection';
import EntityRecordSelect from '../../atoms/EntityRecordSelect';

const { Text } = Typography;

export interface Relationship {
    /** The ID of the entity */
    entityId: string;
    /** The ID of the entity record */
    recordId: string;
    /** Optional notes about this relationship */
    notes: string;
    /** Optional path in Firestore/DB to the target doc. */
    path?: string;
    /** Any extra metadata you want to store. */
    meta?: Record<string, any>;
    /** Optional child relationships for one-to-many support */
    children?: Relationship[];
}

interface GraphUIProps {
    /** Current relationships data */
    value: Relationship[];
    /** Callback when relationships change */
    onChange: (newRelationships: Relationship[]) => void;
    /** Whether the control is disabled */
    disabled?: boolean;
    /** Additional style properties */
    style?: React.CSSProperties;
    /** Optional filter for allowed entity types */
    allowedEntities?: string[];
    /** Optional depth for recursive GraphUI, default is 1 */
    depth?: number;
}

/**
 * Renders a list of cards for managing graph relationships between documents
 */
const GraphUI: FC<GraphUIProps> = ({
    value = [],
    onChange,
    disabled = false,
    style,
    allowedEntities,
    depth = 1
}) => {
    const { token } = theme.useToken();
    const { data: entities = [] } = useBoundCollection({
        path: 'entities',
        initialLimit: 50,
    });
    
    const filteredEntities = allowedEntities && allowedEntities.length
        ? entities.filter((entity: any) => allowedEntities.includes(entity.docId))
        : entities;

    const createNewRelationship = (): Relationship => ({
        entityId: '',
        recordId: '',
        notes: '',
        meta: {}
    });

    const handleRelationshipChange = (index: number, field: keyof Relationship, newValue: string) => {
        const newRelationships = [...value];
        newRelationships[index] = { ...newRelationships[index], [field]: newValue };
        onChange(newRelationships);
    };

    const handleDelete = (index: number) => {
        const newRelationships = [...value];
        newRelationships.splice(index, 1);
        onChange(newRelationships);
    };

    const handleChildrenChange = (index: number, newChildren: Relationship[]) => {
        const newRelationships = [...value];
        newRelationships[index] = { ...newRelationships[index], children: newChildren };
        onChange(newRelationships);
    };

    const handleAddRelationship = () => {
        onChange([...value, createNewRelationship()]);
    };

    const handleAddChildRelationship = (index: number) => {
        if (depth >= 5) return; 
        const updatedRelationship = {
            ...value[index],
            children: [...(value[index].children || []), createNewRelationship()]
        };
        const newRelationships = [...value];
        newRelationships[index] = updatedRelationship;
        onChange(newRelationships);
    };

    const handleEntityChange = (index: number, entityId: string) => {
        const newRelationships = [...value];
        newRelationships[index] = {
            ...newRelationships[index],
            entityId,
            recordId: '',
        };
        onChange(newRelationships);
    };

    const handleEntityRecordSelect = (index: number, recordId?: string, record?: any) => {
        const newRelationships = [...value];
        newRelationships[index] = {
            ...newRelationships[index],
            recordId: recordId || '',
            notes: ''
        };
        onChange(newRelationships);
    };

    return (
        <FlexCol
            style={{
                width: '100%',
                maxWidth: MAX_LIMITED_WIDTH_VIEWPORT,
                ...style,
            }}
            gap={5}
        >
            {value.map((relationship, index) => (
                <FlexCard
                    key={`${relationship.entityId}-${relationship.recordId}-${index}`}
                    stretch
                    style={{
                        width: '100%',
                        backgroundColor: token.colorSplit,
                    }}
                    bodyStyle={{
                        paddingTop: 5,
                        paddingBottom: 5
                    }}
                >
                    <FlexBox stretch>
                        <FlexCol
                            gap={0}
                            style={{
                                flex: 1,
                                maxWidth: 200,
                            }}
                        >
                            <Text>Record</Text>
                            <Select<string>
                                variant='filled'
                                value={relationship.entityId || undefined}
                                placeholder="Select entity"
                                disabled={disabled}
                                style={{
                                    width: '100%',
                                    marginBottom: 10
                                }}
                                onChange={entityId => handleEntityChange(index, entityId)}
                                options={filteredEntities.map(({ docId, name }) => ({
                                    label: name || docId,
                                    value: docId
                                }))}
                            />
                            <EntityRecordSelect
                                entityId={relationship.entityId}
                                recordId={relationship.recordId}
                                onChange={(recordId, record) => handleEntityRecordSelect(index, recordId, record)}
                                disabled={disabled || !relationship.entityId}
                                style={{ width: '100%' }}
                            />
                        </FlexCol>
                        <FlexCol gap={0} style={{ flex: 4 }}>
                            <Text>Notes</Text>
                            <Input.TextArea
                                variant='filled'
                                value={relationship.notes}
                                placeholder="Add notes"
                                style={{
                                    height: '100%',
                                    resize: 'none'
                                }}
                                disabled={disabled}
                                onChange={(e) => handleRelationshipChange(index, 'notes', e.target.value)}
                            />
                        </FlexCol>
                        <FlexCol stretch noGrow alignEnd justifyEnd id='graph-ui-actions'>
                            <Tooltip title="Delete relationship">
                                <Popconfirm
                                    title="Delete this relationship?"
                                    onConfirm={() => handleDelete(index)}
                                    disabled={disabled}
                                >
                                    <Button
                                        type='text'
                                        disabled={disabled}
                                        icon={<DeleteOutlined />}
                                    />
                                </Popconfirm>
                            </Tooltip>
                            <Tooltip title="Add child relationship">
                                <Button
                                    type='text'
                                    disabled={disabled || depth >= 5}
                                    icon={<PlusOutlined />}
                                    onClick={() => handleAddChildRelationship(index)}
                                />
                            </Tooltip>
                        </FlexCol>
                    </FlexBox>
                    <FlexCol>
                        {relationship.children && depth < 5 && (
                            <FlexCol style={{ marginLeft: 20 }}>
                                <GraphUI
                                    value={relationship.children}
                                    onChange={(childRelationships) => handleChildrenChange(index, childRelationships)}
                                    disabled={disabled}
                                    allowedEntities={allowedEntities}
                                    depth={depth + 1}
                                />
                            </FlexCol>
                        )}
                    </FlexCol>
                </FlexCard>
            ))}
            <FlexBox stretch>
                <Tooltip title="Add new relationship">
                    <Button
                        type='link'
                        disabled={disabled}
                        icon={<PlusOutlined />}
                        onClick={handleAddRelationship}
                    >
                        Add new relationship
                    </Button>
                </Tooltip>
            </FlexBox>
        </FlexCol>
    );
};

export default GraphUI;
