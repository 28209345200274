import { FC, useContext, useState, useEffect } from 'react';
import { Dropdown, Button, message, Modal, Typography, Tooltip } from 'antd';
import Icon from '../../../components/atoms/Icon';
import { FormContext } from '../../../providers/artifacts/FormProvider';
import { FormVersionContext } from '../../../providers/artifacts/FormVersionProvider';
import { FormType } from '../../../types/System.types';
import { FieldViewVisibility, FormPropsUIDescription, formStateDescription, FormStateId } from '../../../types/System.Parameters.types';
import { createFormVersion, createFormFromForm, setFormState } from '../../../services/artifactFunctions';
import { useRequiredFieldsCheck } from '../../../use/form/useRequiredFieldsCheck';
import { pdfExport } from '../../../services/pdfExport';
import FormRequiredFieldsModal from '../../modals/FormRequiredFieldsModal';
import FormExportModal from '../../modals/FormExportModal';
import FormEmailModal from '../../modals/FormEmailModal';
import SelectCurrentVersion from './SelectCurrentVersion';

/** Props for FormWorkflowSelect component */
interface FormWorkflowSelectProps {
  /** ID of the form being managed */
  formId: string;
  /** Reference to the content div for PDF export */
  contentRef: React.RefObject<HTMLDivElement>;
}

const { Text } = Typography;

/**
 * Dropdown component for managing form workflow actions including:
 * - Creating new versions
 * - Creating new forms
 * - Changing form states
 * - Exporting to PDF
 * - Sending via email
 */
const FormWorkflowSelect: FC<FormWorkflowSelectProps> = ({
  formId,
  contentRef
}) => {
  const {
    selectedForm,
    formTypeOfSelectedForm,
    formTypes,
    visibilityMode,
    setVisibilityMode,
  } = useContext(FormContext);

  const {
    selectedFormVersion,
    navigateToVersion,
    formVersionAccess
  } = useContext(FormVersionContext);

  const {
    checkRequiredFields,
    isRequiredFieldsModalOpen,
    currentMissingFields,
    handleCloseRequiredFieldsModal
  } = useRequiredFieldsCheck({
    layoutStructure: formTypeOfSelectedForm.data?.defaultUILayout?.structure,
    formVersionFields: selectedFormVersion.data?.fields,
    formVersionEntityReferences: selectedFormVersion.data?.entityReferences,
    visibilityMode
  });

  const [isCreating, setIsCreating] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingStateChange, setPendingStateChange] = useState<FormStateId | null>(null);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportVisibilityMode, setExportVisibilityMode] = useState<FieldViewVisibility>('external');
  const [pendingExport, setPendingExport] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  useEffect(() => {
    if (pendingExport) {
      pdfExport({
        contentRef,
        formData: selectedForm.data,
        versionTitle: selectedFormVersion.data?.docId,
      });
      setPendingExport(false);
    }
  }, [pendingExport, contentRef, selectedForm.data, selectedFormVersion.data?.docId]);

  /** Creates a new version of the current form */
  const handleCreateVersion = async (state: FormStateId) => {
    if (!selectedFormVersion.data) return;
    if (!checkRequiredFields()) return;

    setIsCreating(true);
    try {
      const newVersionId = await createFormVersion(formId, state, selectedFormVersion.data);
      navigateToVersion(formId, newVersionId);
    } catch (err) {
      messageApi.error(`Error creating new version: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setIsCreating(false);
    }
  };

  /** Creates a new form based on the current form */
  const handleCreateFormFromForm = async (newFormType: FormType["docId"]) => {
    if (!selectedFormVersion.data || !formId || !newFormType) return;
    if (!checkRequiredFields()) return;

    setIsCreating(true);
    try {
      const response = await createFormFromForm({
        formId,
        newFormType,
        title: `New form from ${selectedForm.data?.title ?? 'existing form'}`,
      });
      navigateToVersion(response.newFormId, response.newVersionId);
    } catch (err) {
      messageApi.error(`Error creating new form: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setIsCreating(false);
    }
  };

  /** Initiates the state change process */
  const handleSetState = async (newState: FormStateId) => {
    if (!selectedFormVersion.data?.docId) return;
    if (!checkRequiredFields()) return;

    if (newState !== 'draft') {
      setPendingStateChange(newState);
      setIsModalOpen(true);
    } else {
      await performStateChange(newState);
    }
  };

  /** Executes the state change operation */
  const performStateChange = async (state: FormStateId) => {
    setIsCreating(true);
    try {
      await setFormState(formId, selectedFormVersion.data!.docId!, state);
      formVersionAccess?.refresh?.();
    } catch (error) {
      console.error('Error changing form state:', error);
    } finally {
      setIsCreating(false);
    }
  };

  /** Handles confirmation of state change in modal */
  const handleModalOk = async () => {
    if (pendingStateChange) {
      await performStateChange(pendingStateChange);
      setPendingStateChange(null);
    }
    setIsModalOpen(false);
  };

  /** Handles cancellation of state change in modal */
  const handleModalCancel = () => {
    setPendingStateChange(null);
    setIsModalOpen(false);
  };

  /** Initiates the PDF export process */
  const handleExport = () => {
    if (!checkRequiredFields()) return;
    setIsExportModalOpen(true);
  };

  /** Confirms and executes the PDF export */
  const handleExportConfirm = () => {
    setVisibilityMode(exportVisibilityMode);
    setPendingExport(true);
    setIsExportModalOpen(false);
  };

  /** Initiates the email sending process */
  const handleEmailClick = () => {
    if (!checkRequiredFields()) return;
    setIsEmailModalOpen(true);
  };

  const currentVersionState = selectedFormVersion.data?.state;
  const possibleStates = formTypeOfSelectedForm.data?.possibleStates;
  const allowedNextStates = currentVersionState
    ? possibleStates?.[currentVersionState]?.allowedNextStates
    : undefined;
  const allowedNextForms = formTypeOfSelectedForm.data?.allowedNextForms;

  const noStateAllowedItem = [{
    key: 'no-states',
    label: 'Not allowed',
    disabled: true
  }];

  const noFormAllowedItem = [{
    key: 'no-forms',
    label: 'Not allowed',
    disabled: true
  }];

  const createItems = [
    {
      key: 'share',
      type: 'group' as const,
      label: 'Share',
      children: [
        {
          key: 'email',
          label: (
            <span>
              Send via Email
            </span>
          ),
          onClick: handleEmailClick
        },
        {
          key: 'pdf',
          label: (
            <span>
              Export as PDF
            </span>
          ),
          onClick: handleExport
        }
      ]
    },
    {
      key: 'divider1',
      type: 'divider' as const
    },
    {
      key: 'state',
      type: 'group' as const,
      label: 'Set form to',
      children: (allowedNextStates?.length ?? 0) > 0
        ? allowedNextStates
          ?.filter(state => state !== 'draft')
          .map((state: FormStateId) => ({
            key: `state-${state}`,
            label: (
              <Tooltip title={formStateDescription[state]?.longDescription}>
                {formStateDescription[state]?.shortLabel || state}
              </Tooltip>
            ),
            onClick: () => handleSetState(state)
          })) ?? []
        : noStateAllowedItem
    },
    {
      key: 'divider2',
      type: 'divider' as const
    },
    {
      key: 'form',
      type: 'group' as const,
      label: 'Create new form',
      children: (allowedNextForms?.length ?? 0) > 0
        ? allowedNextForms?.map(formType => ({
          key: `form-${formType}`,
          label: (
            <Tooltip title={formTypes.data?.find(ft => ft.docId === formType)?.description?.longDescription}>
              {formTypes.data?.find(ft => ft.docId === formType)?.description?.shortLabel || formType}
            </Tooltip>
          ),
          onClick: () => handleCreateFormFromForm(formType)
        })) ?? []
        : noFormAllowedItem
    },
    {
      key: 'divider3',
      type: 'divider' as const
    },
    {
      key: 'version',
      type: 'group' as const,
      label: FormPropsUIDescription.formVersionNumber.shortLabel,
      children: [
        ...(allowedNextStates?.includes('draft')
          ? [{
            key: `state-draft`,
            label: (
              <Tooltip title={formStateDescription['draft']?.longDescription}>
                New {formStateDescription['draft']?.shortLabel || 'draft'} {FormPropsUIDescription.formVersionNumber.shortLabel}
              </Tooltip>
            ),
            onClick: () => handleCreateVersion('draft')
          }]
          : noStateAllowedItem),
        {
          key: 'goto-current-version',
          label: (
            <Tooltip title="Navigate to the current version of this form">
              <Text disabled={!selectedForm.data?.currentVersionId || selectedFormVersion.data?.docId === selectedForm.data?.currentVersionId}>Go to Current Version</Text>
            </Tooltip>
          ),
          onClick: () => {
            if (selectedForm.data?.currentVersionId && selectedForm.data?.docId) {
              navigateToVersion(selectedForm.data.docId, selectedForm.data.currentVersionId);
            }
          },
          disabled: !selectedForm.data?.currentVersionId || selectedFormVersion.data?.docId === selectedForm.data?.currentVersionId
        }
      ]
    },
    {
      key: 'set-current-version',
      label: <SelectCurrentVersion disabled={isCreating} />,
      disabled: true
    }
  ];

  return (
    <>
      {contextHolder}
      <Dropdown
        menu={{ items: createItems }}
        trigger={['click']}
        disabled={isCreating}
        overlayStyle={{ width: '320px' }}
      >
        <Button
          type='primary'
          loading={isCreating}
          disabled={isCreating}
          icon={<Icon.ChevronsDown />}
        >
          Workflow
        </Button>
      </Dropdown>
      <Modal
        title="Change Form State"
        open={isModalOpen}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        confirmLoading={isCreating}
        okText="Yes"
        cancelText="No"
      >
        <Text>Are you sure you want to change the state to {pendingStateChange ? formStateDescription[pendingStateChange]?.shortLabel || pendingStateChange : ''}?<br /><Text type='warning'>This change is permanent.</Text></Text>
      </Modal>
      <FormRequiredFieldsModal
        missingFields={currentMissingFields}
        open={isRequiredFieldsModalOpen}
        onClose={handleCloseRequiredFieldsModal}
      />
      <FormExportModal
        open={isExportModalOpen}
        onCancel={() => setIsExportModalOpen(false)}
        onConfirm={handleExportConfirm}
        exportVisibilityMode={exportVisibilityMode}
        setExportVisibilityMode={setExportVisibilityMode}
      />
      <FormEmailModal
        open={isEmailModalOpen}
        onCancel={() => setIsEmailModalOpen(false)}
        formId={formId}
        formTitle={selectedForm.data?.title}
        formNumber={selectedForm.data?.formNumber}
        contentRef={contentRef}
      />
    </>
  );
};

export default FormWorkflowSelect; 