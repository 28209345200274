// WARNING: If you add imports here these will also impact sub repositories (cloudRun, functions, etc), you might need to add imports there as well.
import { FieldType } from './System.types';
import { RecordStatus } from './System.Parameters.types';
import { fieldDataType } from './System.FieldTypes.types';

// Current types that will remain active
export type ConfigFieldTypeId = 
  | 'addressLine1'
  | 'addressLine2'
  | 'addressCity'
  | 'addressState'
  | 'addressCounty'
  | 'addressZip'
  | 'addressCountry'
  | 'billAddressLine1'
  | 'billAddressLine2'
  | 'billAddressCity'
  | 'billAddressState'
  | 'billAddressZip'
  | 'billAddressCountry'
  | 'billOfMaterials'
  | 'buySellOK'
  | 'caveats'
  | 'companyName'
  | 'companyDepartment'
  | 'contractorBidPrice'
  | 'contractorExtBidPrice'
  | 'contractorMarkup'
  | 'customerNotes'
  | 'estimatedBudget'
  | 'expectedDeliveryDate'
  | 'firstName'
  | 'hasControls'
  | 'internalNotes'
  | 'isOutOfTown'
  | 'jobTitle'
  | 'lastName'
  | 'logoAttachment'
  | 'logoURL'
  | 'manufacturerTerms'
  | 'manufacturer'
  | 'middleName'
  | 'needsCloseList'
  | 'overageAmt'
  | 'overageCap'
  | 'overageSplit'
  | 'parentCompany'
  | 'quantity'
  | 'qos'
  | 'shipAddressLine1'
  | 'shipAddressLine2'
  | 'shipAddressCity'
  | 'shipAddressState'
  | 'shipAddressZip'
  | 'shipAddressCountry'
  | 'spc'
  | 'standardCommission'
  | 'verticalMarket'
  | 'webAdvertise'
  | 'relationships'
  | 'poNumber'
   // BOM Columns
  | 'bidPrice'  // BOM
  | 'buyPrice'  // BOM
  | 'cutComm'  // BOM
  | 'cutCommAmt'  // BOM
  | 'cutPrice'  // BOM
  | 'cutTiers'  // BOM
  | 'description'  // BOM
  | 'didCalculation'
  | 'distMarkdown'  // BOM
  | 'distMarkup'  // BOM
  | 'effectiveCommissionPercent'  // BOM
  | 'effectiveEnd'  // Leaving this version for backwards compatibility (use effectiveEndDate instead)
  | 'effectiveStart'  // Leaving this version for backwards compatibility (use effectiveStartDate instead)
  | 'effectiveEndDate'  // BOM
  | 'effectiveStartDate'  // BOM
  | 'extBidPrice'  // BOM
  | 'extBuyPrice'  // BOM 
  | 'fixtureOrControl'  // BOM
  | 'oBaseComm'  // BOM
  | 'oBaseCommission'  // BOM
  | 'oBasePrice'  // BOM
  | 'overageComm'  // BOM
  | 'overageCommission'  // BOM
  | 'partNumber'  // BOM
  | 'totalCommissionAmt'  // BOM
  | 'upc'  // BOM
  // Design BOM (AKA Fixture Schedule) columns
  | 'type'
  | 'tag'
  | 'mark'
  | 'productCatalog'
  | 'brand'
  | 'CatalogNumber'
  | 'model'
  | 'designComments'
  | 'remarks'
  | 'source'
  | 'lamp'
  | 'lampQuantity'
  | 'series'
  | 'colorTemp'
  | 'wattage'
  | 'controlProtocol'
  | 'finish'
  | 'mounting'
  | 'location'
  | 'bottomOfFixture'
  | 'cutSheet'
  | 'image';


/**
 * Select options for Select fields
 */
export const verticalMarketSelectOptions = {
  /**
   * Select options for the vertical market field
   */
  verticalMarket: [
    {
      value: "tenantImprovement",
      label: "Tenant Improvement"
    },
    {
      value: "hospitality",
      label: "Hospitality"
    },
    {
      value: "esco",
      label: "ESCO"
    },
    {
      value: "healthcare",
      label: "Healthcare"
    },
    {
      value: "dataCenter",
      label: "Data Center"
    },
    {
      value: "higherEducation",
      label: "Higher Education"
    },
    {
      value: "k12",
      label: "K-12"
    },
    {
      value: "transportation",
      label: "Transportation"
    },
    {
      value: "municipality",
      label: "Municipality"
    },
    {
      value: "multiFamily",
      label: "Multi-Family"
    },
    {
      value: "warehouse",
      label: "Warehouse"
    },
    {
      value: "manufacturing",
      label: "Manufacturing"
    },
    {
      value: "architainment",
      label: "Architainment"
    }
  ]
};

/**
 * Field types for the CLW system
 */
export const configFieldType: Record<ConfigFieldTypeId, FieldType> = {
  didCalculation: {
    fieldTypeId: 'didCalculation',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Calculation Done',
      longDescription: 'Did we spend the time and money to do a calculation?',
    },
  },
  estimatedBudget: {
    fieldTypeId: 'estimatedBudget',
    fieldDataTypeId: 'currency',
    visibility: ['internal'],
    description: {
      shortLabel: 'Estimated Budget',
      longDescription: 'Estimated budget for the lighting project',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  expectedDeliveryDate: {
    fieldTypeId: 'expectedDeliveryDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Expected Delivery Date',
      longDescription: 'Expected date of delivery for the form',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  customerNotes: {
    fieldTypeId: 'customerNotes',
    fieldDataTypeId: 'longText',
    description: {
      shortLabel: 'Customer Notes',
      longDescription: 'Notes that will be shared with the customer',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.longText.validation,
    errorMessage: fieldDataType.longText.errorMessage,
  },
  spc: {
    fieldTypeId: 'spc',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'SPC',
      longDescription: 'Strategic Project Coordinator',
    },
  },
  hasControls: {
    fieldTypeId: 'hasControls',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Controls',
      longDescription: 'Does the order have controls?',
    },
  },
  internalNotes: {
    fieldTypeId: 'internalNotes',
    fieldDataTypeId: 'longText',
    visibility: ['internal'],
    description: {
      shortLabel: 'Internal Notes',
      longDescription: 'Notes that will NOT be shared with the customer',
    },
  },
  isOutOfTown: {
    fieldTypeId: 'isOutOfTown',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Out of Town',
      longDescription: 'Is the order out of town?',
    },
  },
  logoURL: {
    fieldTypeId: 'logoURL',
    fieldDataTypeId: 'url',
    visibility: ['internal'],
    description: {
      shortLabel: 'Logo URL',
      longDescription: 'URL of the logo',
    },
  },
  logoAttachment: {
    fieldTypeId: 'logoAttachment',
    fieldDataTypeId: 'attachments',
    visibility: ['internal'],
    description: {
      shortLabel: 'Logo Attachment',
      longDescription: 'Attachment of the logo',
    },
  },
  needsCloseList: {
    fieldTypeId: 'needsCloseList',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Close List',
      longDescription: 'Does the order need a close list?',
    },
  },
  qos: {
    fieldTypeId: 'qos',
    fieldDataTypeId: 'custom',
    visibility: ['internal'],
    description: {
      shortLabel: 'Quality of Specification',
      longDescription: 'Quality of Specification',
    },
    status: RecordStatus.Active,
    validation: fieldDataType.custom.validation,
    errorMessage: fieldDataType.custom.errorMessage,
  },
  verticalMarket: {
    fieldTypeId: 'verticalMarket',
    fieldDataTypeId: 'singleSelect',
    props: {
      options: verticalMarketSelectOptions.verticalMarket,
    },
    description: {
      shortLabel: 'Vertical Market',
      longDescription: 'Vertical market segment for the project',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    errorMessage: 'Must be a valid vertical market segment (e.g., "Tenant Improvement", "Healthcare", "Data Center")',
  },
  addressLine1: {
    fieldTypeId: 'addressLine1',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Address Line 1',
      longDescription: 'First line of the address',
    },
  },
  addressLine2: {
    fieldTypeId: 'addressLine2',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Address Line 2',
      longDescription: 'Second line of the address',
    },
  },
  addressCity: {
    fieldTypeId: 'addressCity',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Address City',
      longDescription: 'City for the address',
    },
  },
  addressState: {
    fieldTypeId: 'addressState',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Address State',
      longDescription: 'State for the address',
    },
  },
  addressCounty: {
    fieldTypeId: 'addressCounty',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Address County',
      longDescription: 'County for the address',
    },
  },
  addressZip: {
    fieldTypeId: 'addressZip',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Address Zip',
      longDescription: 'Zip for the address',
    },
  },
  addressCountry: {
    fieldTypeId: 'addressCountry',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Address Country',
      longDescription: 'Country for the address',
    },
  },
  shipAddressLine1: {
    fieldTypeId: 'shipAddressLine1',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Ship Address Line 1',
      longDescription: 'First line of the shipping address',
    },
  },
  shipAddressLine2: {
    fieldTypeId: 'shipAddressLine2',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Ship Address Line 2',
      longDescription: 'Second line of the shipping address',
    },
  },
  shipAddressCity: {
    fieldTypeId: 'shipAddressCity',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Ship City',
      longDescription: 'City for the shipping address',
    },
  },
  shipAddressState: {
    fieldTypeId: 'shipAddressState',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Ship State',
      longDescription: 'State for the shipping address',
    },
  },
  shipAddressZip: {
    fieldTypeId: 'shipAddressZip',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Ship Zip',
      longDescription: 'Zip code for the shipping address',
    },
  },
  shipAddressCountry: {
    fieldTypeId: 'shipAddressCountry',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Ship Country',
      longDescription: 'Country for the shipping address',
    },
  },
  billAddressLine1: {
    fieldTypeId: 'billAddressLine1',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Bill Address Line 1',
      longDescription: 'First line of the billing address',
    },
  },
  billAddressLine2: {
    fieldTypeId: 'billAddressLine2',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Bill Address Line 2',
      longDescription: 'Second line of the billing address',
    },
  },
  billAddressCity: {
    fieldTypeId: 'billAddressCity',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Bill City',
      longDescription: 'City for the billing address',
    },
  },
  billAddressState: {
    fieldTypeId: 'billAddressState',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Bill State',
      longDescription: 'State for the billing address',
    },
  },
  billAddressZip: {
    fieldTypeId: 'billAddressZip',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Bill Zip',
      longDescription: 'Zip code for the billing address',
    },
  },
  billAddressCountry: {
    fieldTypeId: 'billAddressCountry',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Bill Country',
      longDescription: 'Country for the billing address',
    },
  },
  firstName: {
    fieldTypeId: 'firstName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'First Name',
      longDescription: 'First name of the person',
    },
  },  
  middleName: {
    fieldTypeId: 'middleName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Middle Name',
      longDescription: 'Middle name or initial of the person',
    },
  },
  lastName: {
    fieldTypeId: 'lastName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Last Name',
      longDescription: 'Last name of the person',
    },
  },
  companyName: {
    fieldTypeId: 'companyName',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Company Name',
      longDescription: 'Name of the company',
    },
  },
  jobTitle: {
    fieldTypeId: 'jobTitle',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Contact Title',
      longDescription: 'Title of the contact',
    },
  },
  companyDepartment: {
    fieldTypeId: 'companyDepartment',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Department',
      longDescription: 'Department of the contact',
    },
  },
  billOfMaterials: {
    fieldTypeId: 'billOfMaterials',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Design Fixture Schedule or BOM Table',
      longDescription: 'Design Fixture Schedule or Bill of Materials (BOM) Table',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: (value: any) => Array.isArray(value),
  },
  bidPrice: {
    fieldTypeId: 'bidPrice',
    fieldDataTypeId: 'currency',
    visibility: ['internal'],
    description: {
      shortLabel: 'Bid Price',
      longDescription: 'Price being bid for the item',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  buyPrice: {
    fieldTypeId: 'buyPrice',
    fieldDataTypeId: 'currency',
    visibility: ['internal', 'external'],
    description: {
      shortLabel: 'Buy Price',
      longDescription: 'Purchase price of the item',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  contractorBidPrice: {
    fieldTypeId: 'contractorBidPrice',
    fieldDataTypeId: 'currency',
    visibility: ['internal', 'contractor'],
    description: {
      shortLabel: 'Contractor Bid Price',
      longDescription: 'Bid price from the contractor',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  contractorExtBidPrice: {
    fieldTypeId: 'contractorExtBidPrice',
    fieldDataTypeId: 'currency',
    visibility: ['internal', 'contractor'],
    description: {
      shortLabel: 'Extended Contractor Bid Price',
      longDescription: 'Extended bid price from the contractor (typically price × quantity)',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  contractorMarkup: {
    fieldTypeId: 'contractorMarkup',
    fieldDataTypeId: 'percentage',
    visibility: ['internal'],
    description: {
      shortLabel: 'Contractor Markup',
      longDescription: 'Markup percentage applied by the contractor',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  cutComm: {
    fieldTypeId: 'cutComm',
    fieldDataTypeId: 'percentage',
    visibility: ['internal'],
    description: {
      shortLabel: 'Cut Commission',
      longDescription: 'Commission percentage after cuts/adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  cutCommAmt: {
    fieldTypeId: 'cutCommAmt',
    fieldDataTypeId: 'currency',
    visibility: ['internal'],
    description: {
      shortLabel: 'Cut Commission Amount',
      longDescription: 'Commission amount after cuts/adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  cutPrice: {
    fieldTypeId: 'cutPrice',
    fieldDataTypeId: 'currency',
    visibility: ['internal'],
    description: {
      shortLabel: 'Cut Price',
      longDescription: 'Price after cuts/adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  cutTiers: {
    fieldTypeId: 'cutTiers',
    fieldDataTypeId: 'table',
    description: {
      shortLabel: 'Additional Cut Pricing',
      longDescription: 'Additional cut pricing and commission structure',
    },
    status: RecordStatus.Active,
    defaultValue: [],
    validation: fieldDataType.table.validation,
    errorMessage: fieldDataType.table.errorMessage,
  },
  description: {
    fieldTypeId: 'description',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'external', 'contractor', 'designInput'],
    description: {
      shortLabel: 'Description',
      longDescription: 'Detailed description of the item',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  distMarkdown: {
    fieldTypeId: 'distMarkdown',
    fieldDataTypeId: 'percentage',
    visibility: ['internal'],
    description: {
      shortLabel: 'Distributor Markdown',
      longDescription: 'Markdown percentage applied by the distributor',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  distMarkup: {
    fieldTypeId: 'distMarkup',
    fieldDataTypeId: 'percentage',
    visibility: ['internal'],
    description: {
      shortLabel: 'Distributor Markup',
      longDescription: 'Markup percentage applied by the distributor',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  effectiveCommissionPercent: {
    fieldTypeId: 'effectiveCommissionPercent',
    fieldDataTypeId: 'percentage',
    visibility: ['internal'],
    description: {
      shortLabel: 'Effective Commission Percentage',
      longDescription: 'Final effective commission percentage after all adjustments',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  effectiveEndDate: {
    fieldTypeId: 'effectiveEndDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Effective End Date',
      longDescription: 'Date when pricing expires',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  effectiveStartDate: {
    fieldTypeId: 'effectiveStartDate',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Effective Start Date',
      longDescription: 'Date when pricing becomes effective',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  effectiveEnd: {
    fieldTypeId: 'effectiveEnd',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Effective End Date',
      longDescription: 'Date when pricing expires',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  effectiveStart: {
    fieldTypeId: 'effectiveStart',
    fieldDataTypeId: 'date',
    description: {
      shortLabel: 'Effective Start Date',
      longDescription: 'Date when pricing becomes effective',
    },
    status: RecordStatus.Active,
    defaultValue: null,
    validation: fieldDataType.date.validation,
    errorMessage: fieldDataType.date.errorMessage,
  },
  extBidPrice: {
    fieldTypeId: 'extBidPrice',
    fieldDataTypeId: 'currency',
    visibility: ['internal'],
    description: {
      shortLabel: 'Extended Bid Price',
      longDescription: 'Extended bid price (typically price × quantity)',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  extBuyPrice: {
    fieldTypeId: 'extBuyPrice',
    fieldDataTypeId: 'currency',
    visibility: ['internal', 'external'],
    description: {
      shortLabel: 'Extended Buy Price',
      longDescription: 'Extended buy price (typically price × quantity)',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  manufacturer: {
    fieldTypeId: 'manufacturer',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'external', 'contractor'],
    description: {
      shortLabel: 'Manufacturer',
      longDescription: 'Name of the manufacturer',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  fixtureOrControl: {
    fieldTypeId: 'fixtureOrControl',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'external', 'contractor'],
    description: {
      shortLabel: 'Fixture | Control',
      longDescription: 'Types can be Fixture, Control, or Both',
    },
    status: RecordStatus.Active,
    defaultValue: 'Fixture',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  oBaseComm: {
    fieldTypeId: 'oBaseComm',
    fieldDataTypeId: 'percentage',
    visibility: ['internal'],
    description: {
      shortLabel: 'oBase Commission',
      longDescription: 'Commission percentage for the oBase price',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  oBaseCommission: {
    fieldTypeId: 'oBaseCommission',
    fieldDataTypeId: 'currency',
    visibility: ['internal'],
    description: {
      shortLabel: 'Base Commission Amount',
      longDescription: 'Base commission amount calculated from oBase price and commission rate',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  oBasePrice: {
    fieldTypeId: 'oBasePrice',
    fieldDataTypeId: 'currency',
    visibility: ['internal'],
    description: {
      shortLabel: 'oBase Price',
      longDescription: 'Base price threshold - when sell price equals or exceeds this amount, both base commission and overage commission apply',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  overageAmt: {
    fieldTypeId: 'overageAmt',
    fieldDataTypeId: 'currency',
    visibility: ['internal'],
    description: {
      shortLabel: 'Overage Amount',
      longDescription: 'Amount exceeding the base price threshold',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  overageComm: {
    fieldTypeId: 'overageComm',
    fieldDataTypeId: 'percentage',
    visibility: ['internal'],
    description: {
      shortLabel: 'Overage Commission',
      longDescription: 'Commission percentage for overage amounts',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  overageCommission: {
    fieldTypeId: 'overageCommission',
    fieldDataTypeId: 'currency',
    visibility: ['internal'],
    description: {
      shortLabel: 'Overage Commission Amount',
      longDescription: 'Commission amount calculated on the overage amount',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  partNumber: {
    fieldTypeId: 'partNumber',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'external', 'contractor'],
    description: {
      shortLabel: 'Part Number',
      longDescription: 'Manufacturer part number',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  quantity: {
    fieldTypeId: 'quantity',
    fieldDataTypeId: 'number',
    visibility: ['internal', 'external', 'contractor'],
    description: {
      shortLabel: 'Quantity',
      longDescription: 'Number of units',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  },
  totalCommissionAmt: {
    fieldTypeId: 'totalCommissionAmt',
    fieldDataTypeId: 'currency',
    visibility: ['internal'],
    description: {
      shortLabel: 'Total Commission Amount',
      longDescription: 'Total commission amount including base and overage commission',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  upc: {
    fieldTypeId: 'upc',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'external', 'contractor'],
    description: {
      shortLabel: 'UPC',
      longDescription: 'Universal Product Code',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  buySellOK: {
    fieldTypeId: 'buySellOK',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Buy/Sell OK',
      longDescription: 'Whether buying and selling is allowed',
    },
    status: RecordStatus.Active,
    defaultValue: false,
    validation: fieldDataType.yesNo.validation,
    errorMessage: fieldDataType.yesNo.errorMessage,
  },
  overageCap: {
    fieldTypeId: 'overageCap',
    fieldDataTypeId: 'currency',
    description: {
      shortLabel: 'Overage Cap',
      longDescription: 'Maximum overage amount allowed',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.currency.validation,
    errorMessage: fieldDataType.currency.errorMessage,
  },
  overageSplit: {
    fieldTypeId: 'overageSplit',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Overage Split',
      longDescription: 'Percentage split for overage amounts',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  parentCompany: {
    fieldTypeId: 'parentCompany',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Parent Company',
      longDescription: 'Name of the parent company',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  manufacturerTerms: {
    fieldTypeId: 'manufacturerTerms',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'Manufacturer Terms',
      longDescription: 'Manufacturer Terms for quoting and payment',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  standardCommission: {
    fieldTypeId: 'standardCommission',
    fieldDataTypeId: 'percentage',
    description: {
      shortLabel: 'Standard Commission',
      longDescription: 'Standard commission percentage',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.percentage.validation,
    errorMessage: fieldDataType.percentage.errorMessage,
  },
  webAdvertise: {
    fieldTypeId: 'webAdvertise',
    fieldDataTypeId: 'yesNo',
    description: {
      shortLabel: 'Web Advertise',
      longDescription: 'Whether web advertising is allowed',
    },
    status: RecordStatus.Active,
    defaultValue: false,
    validation: fieldDataType.yesNo.validation,
    errorMessage: fieldDataType.yesNo.errorMessage,
  },
  relationships: {
    fieldTypeId: 'relationships',
    fieldDataTypeId: 'graph',
    props: {
      allowedEntities: ['Architects', 'Contacts']
    },
    description: {
      shortLabel: 'Relationships',
      shortDescription: 'Relationships represents relationships.',
      longLabel: 'Relationships Builder',
      longDescription: 'Relationships represents relationships between different elements.'
    },
    status: RecordStatus.Active,
    defaultValue: []
  },
  poNumber: {
    fieldTypeId: 'poNumber',
    fieldDataTypeId: 'text',
    description: {
      shortLabel: 'PO Number',
      longDescription: 'Purchase Order Number',
    },
    status: RecordStatus.Active,
    errorMessage: 'Write a PO Number',
  },
  
  // Design BOM columns
  type: {
    fieldTypeId: 'type',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1'],
    description: {
      shortLabel: 'Type',
      longDescription: 'Types can be Fixture, Control, or Both',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  tag: {
    fieldTypeId: 'tag',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designAlt2'],
    description: {
      shortLabel: 'Tag',
      longDescription: 'Alternative column label for type field',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  mark: {
    fieldTypeId: 'mark',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designAlt3'],
    description: {
      shortLabel: 'Mark',
      longDescription: 'Alternative column label for type field',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  productCatalog: {
    fieldTypeId: 'productCatalog',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'external', 'contractor', 'designInput', 'designAlt1'],
    description: {
      shortLabel: 'Manufacturer Product Catalog',
      longDescription: 'Product Catalog information',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  brand: {
    fieldTypeId: 'brand',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Brand',
      longDescription: 'Alternative column label for productCatalog field',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  CatalogNumber: {
    fieldTypeId: 'CatalogNumber',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Catalog Number',
      longDescription: 'Alternative column label for model field',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  model: {
    fieldTypeId: 'model',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1'],
    description: {
      shortLabel: 'Model',
      longDescription: 'The model of the product, to include the manufacturer\'s part number if available',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  designComments: {
    fieldTypeId: 'designComments',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1'],
    description: {
      shortLabel: 'Design Comments',
      longDescription: 'Design comments for the fixture',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  remarks: {
    fieldTypeId: 'remarks',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Remarks',
      longDescription: 'Alternative column label for designComments field',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  source: {
    fieldTypeId: 'source',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1'],
    description: {
      shortLabel: 'Source',
      longDescription: 'Light source information',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  lamp: {
    fieldTypeId: 'lamp',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Lamp',
      longDescription: 'Alternative column label for source field',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  }, 
  lampQuantity: {
    fieldTypeId: 'lampQuantity',
    fieldDataTypeId: 'number',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Lamp Qty',
      longDescription: 'Alternative column label for quantity field',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  series: {
    fieldTypeId: 'series',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Series',
      longDescription: 'Product series information',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  colorTemp: {
    fieldTypeId: 'colorTemp',
    fieldDataTypeId: 'number',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Color Temp',
      longDescription: 'Color temperature of the light source',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  wattage: {
    fieldTypeId: 'wattage',
    fieldDataTypeId: 'number',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Wattage',
      longDescription: 'Power consumption in watts',
    },
    status: RecordStatus.Active,
    defaultValue: 0,
    validation: fieldDataType.number.validation,
    errorMessage: fieldDataType.number.errorMessage,
  }, 
  controlProtocol: {
    fieldTypeId: 'controlProtocol',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Control Protocol',
      longDescription: 'Control protocol used by the fixture',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  }, 
  finish: {
    fieldTypeId: 'finish',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Finish',
      longDescription: 'Finish or color of the fixture',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  }, 
  mounting: {
    fieldTypeId: 'mounting',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Mounting',
      longDescription: 'How the fixture is mounted',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  location: {
    fieldTypeId: 'location',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Location',
      longDescription: 'Where the fixture is installed',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  bottomOfFixture: {
    fieldTypeId: 'bottomOfFixture',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Bottom of Fixture',
      longDescription: 'Bottom of fixture specifications',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  }, 
  cutSheet: {
    fieldTypeId: 'cutSheet',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Cut Sheet',
      longDescription: 'URL to fixture cut sheet documentation',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  image: {
    fieldTypeId: 'image',
    fieldDataTypeId: 'text',
    visibility: ['internal', 'designInput', 'designAlt1', 'designAlt2', 'designAlt3'],
    description: {
      shortLabel: 'Image',
      longDescription: 'URL to thumbnail image of the product',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.text.validation,
    errorMessage: fieldDataType.text.errorMessage,
  },
  caveats: {
    fieldTypeId: 'caveats',
    fieldDataTypeId: 'longText',
    visibility: ['internal', 'external'],
    description: {
      shortLabel: 'Caveats',
      longDescription: 'Important notes, warnings, or special considerations about the item',
    },
    status: RecordStatus.Active,
    defaultValue: '',
    validation: fieldDataType.longText.validation,
    errorMessage: fieldDataType.longText.errorMessage,
  }
};

