import { DatePicker, TimeRangePickerProps, Grid } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { defaultMetricStartTimeWindow } from '../../types/System.Parameters.types';
import { FC } from 'react';

dayjs.extend(quarterOfYear);
const { useBreakpoint } = Grid;

interface DatePickerWithPresetsProps {
    value: [Dayjs, Dayjs] | null;
    onChange: (dates: [Dayjs | null, Dayjs | null] | null) => void;
    style?: React.CSSProperties;
    size?: 'small' | 'middle' | 'large';
    format?: string;
}

export const DatePickerWithPresets: FC<DatePickerWithPresetsProps> = ({
    value,
    onChange,
    style,
    size = 'middle',
    format
}) => {
    const screen = useBreakpoint();

    const presets: TimeRangePickerProps['presets'] = [
        {
            label: 'YTD',
            value: [dayjs().startOf('year'), dayjs()]
        },
        {
            label: 'QTD',
            value: [dayjs().startOf('quarter'), dayjs()]
        },
        {
            label: 'MTD',
            value: [dayjs().startOf('month'), dayjs()]
        },
        {
            label: 'WTD',
            value: [dayjs().startOf('week'), dayjs()]
        },
        {
            label: 'All Time',
            value: [dayjs(defaultMetricStartTimeWindow), dayjs()]
        }
    ];

    return (
        <DatePicker.RangePicker
            value={value}
            onChange={onChange}
            style={{ 
                width: screen.sm ? 265 : 140,
                ...style 
            }}
            presets={presets}
            size={size}
            format={format || (screen.sm ? 'YYYY-MM-DD' : 'MMM-YY')}
        />
    );
};