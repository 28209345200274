import { WhereFilterOp, Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import { ListFilters } from '../../providers/DataProvider';

/**
 * Creates Firestore query filters for form views
 * Note: Form types, form states, and view mode filtering is done client-side
 * This is because Firestore has limitations on the number of conditions
 * that can be applied to a query
 */
export function createFirestoreFilters(
  currentDateRange: [dayjs.Dayjs, dayjs.Dayjs],
  currentEntitySelection: { entityId: string; groupId: string | null } | null
): ListFilters {
  const conditions: ListFilters = [
    {
      field: 'meta.lastModified',
      operator: '>=' as WhereFilterOp,
      value: Timestamp.fromMillis(currentDateRange[0].startOf('day').valueOf()),
    },
    {
      field: 'meta.lastModified',
      operator: '<=' as WhereFilterOp,
      value: Timestamp.fromMillis(currentDateRange[1].endOf('day').valueOf()),
    },
  ];

  if (currentEntitySelection?.entityId) {
    conditions.push({
      field: `entityReferences.${currentEntitySelection.entityId}`,
      operator: '!=' as WhereFilterOp,
      value: null,
    });
  }

  return conditions;
}