/**
 * @fileoverview Renders a form field UI component with validation, tooltips, and real-time updates.
 */

import { FC, useState, memo } from 'react';
import { Tooltip } from 'antd';
import { UIField } from '../../types/System.types';
import FlexCol from '../atoms/FlexCol';
import FieldLabel from '../atoms/FieldLabel';
import ControlUI from './ControlUI';
import { useFieldUI } from '../../use/useFieldUI';

/**
 * Props for the FieldUI component
 */
interface FieldUIProps {
  /** Configuration object containing field properties and metadata */
  uiField: UIField;
}

/**
 * A form field component that handles rendering, validation, and data updates.
 * 
 * @component
 * @memo
 * 
 * @features
 * - Real-time validation with visual feedback
 * - Context-aware data persistence (Entity or Form context)
 * - Tooltip-based help text and error messages
 * - Read-only mode support
 * - Automatic field type detection and rendering
 * - Debounced updates to prevent excessive writes
 * 
 * @context
 * - Requires either EntityContext or FormContext
 * - Also uses UserContext for event logging
 * 
 * @throws {Alert}
 * Renders an error Alert if neither EntityContext nor FormContext is available
 */
const FieldUI: FC<FieldUIProps> = memo(({
  uiField
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const {
    hasContext,
    isReadOnly,
    fieldData,
    value,
    setValue,
    validationStatus,
    isSaving,
    props,
    errorMessage,
    shouldShowField,
    label,
    description,
  } = useFieldUI({
    uiField,
  });

  if (!hasContext && value === null) {
    return null;
  }

  if (shouldShowField && !shouldShowField()) {
    return null;
  }

  return (
    <FlexCol gap={0} {...uiField.props} noGrow>
      <FieldLabel
        label={label}
        required={uiField.required}
        isSaving={isSaving}
        tooltip={description}
      />
      <ControlUI
        field={fieldData}
        value={value}
        uiField={uiField}
        onChange={setValue}
        readOnly={isReadOnly}
        onFocus={() => {
          setIsFocused(true);
          setShowPopover(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          setShowPopover(false);
        }}
        status={validationStatus}
        style={{ width: '100%' }}
        {...props}
      />
      <Tooltip
        color='red'
        title={
          <div onClick={() => setShowPopover(false)}>
            {errorMessage}
          </div>
        }
        open={!!validationStatus && showPopover && isFocused}
        placement='bottomLeft'
      />
    </FlexCol>
  );
});

FieldUI.displayName = 'FieldUI';

export default FieldUI;