import { useState } from 'react';
import { attachmentStorageService } from './attachmentStorageService';
import { attachmentDbService } from './attachmentDbService';

/**
 * Props for the useAttachmentDownload hook
 */
interface UseAttachmentDownloadProps {
    /** Callback triggered on error */
    onError?: (error: Error) => void;
    /** Callback triggered on successful operation */
    onSuccess?: (message: string) => void;
}

/**
 * Hook for downloading attachments from Firebase Storage
 */
export const useAttachmentDownload = ({
    onError,
    onSuccess
}: UseAttachmentDownloadProps = {}) => {
    const [downloading, setDownloading] = useState(false);

    /**
     * Downloads a file from Firebase Storage and triggers browser download
     * @param fileUid Attachment document ID
     * @param fileName Name to save the file as
     */
    const handleDownload = async (fileUid: string, fileName: string) => {
        try {
            setDownloading(true);
            const attachmentData = await attachmentDbService.getAttachmentDoc(fileUid);
            
            const blob = await attachmentStorageService.downloadFromStorage(attachmentData.storagePath);
            const blobUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);

            onSuccess?.('File downloaded successfully');
        } catch (error) {
            console.error('Download error:', error);
            onError?.(error instanceof Error ? error : new Error('Download failed'));
        } finally {
            setDownloading(false);
        }
    };

    return {
        downloading,
        handleDownload
    };
}; 