import { FC } from 'react';
import FlexBox, { FlexBoxProps } from './FlexBox';

/**
 * FlexCol component using FlexBox with column layout.
 *
 * @export
 * @interface FlexColProps
 * @extends {Omit<FlexBoxProps, 'column'>}
 * @property {number} [gap=10]
 */
export interface FlexColProps extends Omit<FlexBoxProps, 'column'> {
  gap?: number;
}

/**
 * Renders a column-based flex container.
 *
 * @export
 * @param {FlexColProps} props Component properties.
 * @returns {JSX.Element}
 */
const FlexCol: FC<FlexColProps> = ({
  children,
  alignStart,
  justifyStart,
  justifyEnd,
  justifyCenter,
  alignEnd,
  stretch = true,
  gap = 10,
  ...props
}) => {
  const resolvedJustifyStart =
    justifyStart === undefined ? (!justifyEnd && !justifyCenter ? true : false) : justifyStart;
  const resolvedAlignStart =
    alignStart === undefined ? (!alignEnd ? true : false) : alignStart;

  return (
    <FlexBox
      column
      gap={gap}
      stretch={stretch}
      justifyStart={resolvedJustifyStart}
      justifyEnd={justifyEnd}
      justifyCenter={justifyCenter}
      alignStart={resolvedAlignStart}
      alignEnd={alignEnd}
      {...props}
    >
      {children}
    </FlexBox>
  );
};

export default FlexCol;