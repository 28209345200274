import { FC } from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { EmailRecord, parseEML } from './emailParser';
import FlexBox from '../../../atoms/FlexBox';
import { Typography } from 'antd';

const { Dragger } = Upload;
const { Text } = Typography;

interface EmailUploaderProps {
    /** Whether the uploader is disabled */
    disabled?: boolean;
    /** Callback when an email is successfully parsed */
    onEmailParsed: (email: EmailRecord) => void;
}

export const EmailUploader: FC<EmailUploaderProps> = ({
    disabled = false,
    onEmailParsed
}) => {
    const [messageApi, contextHolder] = message.useMessage();

    const handleFileDrop = async (file: File) => {
        try {
            if (file.name.toLowerCase().endsWith('.eml')) {
                const emailData = await parseEML(file);
                onEmailParsed(emailData);
                messageApi.success('Email imported successfully');
                return false;
            }
            messageApi.error('Please upload an .eml file');
            return Upload.LIST_IGNORE;
        } catch (error) {
            messageApi.error('Failed to parse email file');
            return Upload.LIST_IGNORE;
        }
    };

    return (
        <>
            {contextHolder}
            <Dragger
                name="eml"
                multiple={true}
                showUploadList={false}
                beforeUpload={handleFileDrop}
                accept=".eml"
                style={{ width: '100%' }}
                disabled={disabled}
            >
                <FlexBox justifyCenter style={{ margin: -10 }}>
                    <InboxOutlined style={{ fontSize: 20 }} />
                    <Text>Drop .eml files here</Text>
                </FlexBox>
            </Dragger>
        </>
    );
}; 