import { FC } from 'react';
import { Modal } from 'antd';
import Entity from '../Entity';
import { EntityProvider } from '../../providers/artifacts/EntityProvider';
import { EntityRecordProvider } from '../../providers/artifacts/EntityRecordProvider';
import { Entity as EntityType } from '../../types/System.types';

interface EntityRecordModalProps {
  entityId: EntityType["docId"];
  recordId?: string;
  onClose: () => void;
}

export const EntityRecordModal: FC<EntityRecordModalProps> = ({
  entityId,
  recordId,
  onClose
}) => {
  return (
    <Modal
      title={`${entityId} Details`}
      open={!!recordId}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      {recordId && (
        <EntityProvider initialEntityId={entityId}>
          <EntityRecordProvider initialRecordId={recordId}>
            <Entity disableRecordSelect={true} />
          </EntityRecordProvider>
        </EntityProvider>
      )}
    </Modal>
  );
}; 