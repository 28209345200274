/**
 * SYSTEM TYPES
 * Defines the data structure of the system.
 * 
 * This file is the main entry point for the system types. 
 * This file is *not* meant to house interfaces.
 * It imports all the other types satellite type files and exports them.
 * 
 * WARNING: If you add imports here these will also impact sub repositories (cloudRun, functions, etc), you might need to add imports there as well.
 * 
 * If you add imports here these will also impact sub repositories (cloudRun, functions, etc), you might need to add imports there as well.
 */

import { StorageReference } from "firebase/storage";
import { Timestamp, FieldValue, DocumentData, WhereFilterOp } from "firebase/firestore";
import type { DocumentReference as FirestoreDocumentReference } from "firebase/firestore";
import { 
    ConfigFieldTypeId,
    configFieldType,
} from "./CLW.FieldTypes.types";
import { 
    SystemFieldTypeId,
    fieldDataType,
} from "./System.FieldTypes.types";
import { 
    BuiltInFieldTypeId,
    builtInFieldType,
} from "./System.BuiltInFieldTypes.types";
import { 
    Metric, 
    MetricGroupedBy, 
    MetricRecord, 
    StatisticType, 
    TimePeriod, 
    FrequencyOfCalculation 
} from './System.MetricTypes.types';
import { 
    FormStateId,
    RecordStatus,
    FieldViewVisibility
} from "./System.Parameters.types";

export type DocumentReference = FirestoreDocumentReference;
export type TimeType = Timestamp | FieldValue;
export { 
    StatisticType, 
    TimePeriod, 
    FrequencyOfCalculation
};
export type { 
    Metric, 
    MetricGroupedBy, 
    MetricRecord, 
    SystemFieldTypeId
};

/**
 * Represents the field types in the system.
 * This is a union of the config field types, the built-in field types, and the field data types.
 * These are used to render the UI.
 */
export type FieldTypeId = ConfigFieldTypeId | BuiltInFieldTypeId | SystemFieldTypeId;

/**
 * Combines all field types in the system. 
 * This includes config field types, built-in field types, and field data types.
 */
export const fieldType: Record<FieldTypeId, FieldType> = {
    ...fieldDataType,
    ...builtInFieldType,
    ...configFieldType,
};

/**
 * Form document, such as a quote or invoice.
 * Is a DB document.
 * Stored in the collection `forms/`.
 */
export type Form = {
    /** Unique identifier for the form 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Title of the form (DEPRECATED) */
    title: string;
    /** Form number */
    formNumber?: string;
    /** Organization associated with the form */
    organization: string;
    /** User roles associated with the form */
    userRoles: { 
        [key: string]: UserDBRole 
    };
    /** User group associated with the form (singular) */
    userGroup?: Group["docId"];
    /** Subscribers to notifications for the form */
    notificationsSubscribers?: string[];
    /** User assigned to the form */
    assignedTo?: string;
    /** Type (formTypeId) of the form */
    formType: FormType["docId"];
    /** Current version (formVersionId) of the form */
    currentVersionId: FormVersion["docId"];
    /** External IDs from other systems */
    externalIds?: Record<string, string>;
    /** Last revision number */
    lastFormVersionNumber?: number;
    /** Metadata for the form */
    meta?: Meta;
    /** Subcollection `forms/{formId}/formVersions/` for the versions of this form */
};

/**
 * Represents a version of a Form, which have states such as draft, submitted, approved, and archived.
 * Is a DB document.
 * Stored in the sub-collection `forms/{formId}/formVersions/`.
 */
export type FormVersion = {
    /** The form version id
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** The state of the form version */
    state: FormStateId;
    /** The form version number */
    formVersionNumber: number;
    /** Type (formTypeId) of the form. This is a copy of the formType from the parent Form for easier access and querying */
    parentFormType?: FormType["docId"];
    /** The form data of the parent form. This is a copy of the parent Form for easier access and querying */
    parentFormData?: Form;
    /** Form data: field names and values specific to this form */
    fields: Fields;
    /** References to EntityRecords used in this form */
    entityReferences: {
        [entityId: string]: EntityRecord["docId"];
    };
    /** Associated files - references to attachment documents */
    attachments?: string[];
    /** Metadata for the form data */
    meta?: Meta;
    /** Layout ID for the form version */
    layoutId?: UILayout["docId"];
};

/**
 * Defines the type of a Form, such as a quote or invoice.
 * Is a DB document.
 * Stored in the collection `formTypes/`.
 */
export type FormType = {
    /** Unique identifier for the form type 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** The possible states for the form type NOTE: in the future this shoudl be `Record<FormStateId, FormState>` */
    possibleStates: {  
        [key: string]: FormState;
    };
    /** Fields for metrics */
    metricFields?: {
        /** A list of fields in the form that contains the timestamp that can be used for metrics */
        timeFields?: FieldTypeId[];
        /** A list of fields in the form that contains the values that can be used for metrics */
        valueFields?: FieldTypeId[];
    };
    /** The default format for the form type using the new UILayout structure */
    defaultUILayout: UILayout;
    /** Array of form types that can be created next */
    allowedNextForms?: FormType["docId"][];
    /** The name of the starting state for new documents of this type */
    initialState?: FormStateId;
    /** Description of the document type */
    description?: Description;
    /** Metadata for the form type */
    meta?: Meta;
    /** Subcollection `formTypes/{formTypeId}/uiLayouts/` for the UILayouts for this form type */
};

/**
 * Implements the logic for a form state in the workflow around Forms.
 * Is a map or object field.
 */
export type FormState = {
    /** The name of the state 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: FormStateId;
    /** Description of the document state (CURRENTLY NOT USED, see `formStateDescription`) */
    // description?: Description;
    /** Array of state formStateId that this state can transition to */
    allowedNextStates: FormStateId[];
    /** Rules that enable the state */
    entryRules?: { 
        [key: string]: FormRule 
    };
    /** Rules that enable the state */
    exitRules?: { 
        [key: string]: FormRule 
    };
    /** Actions that are performed when entering the state */
    onEntryActions?: { 
        [key: string]: FormAction 
    };
    /** Actions that are performed when exiting the state */
    onExitActions?: { 
        [key: string]: FormAction 
    };
    /** Metadata for the form state */
    meta?: Meta;
};

/**
 * Represents a rule for a form state.
 * Is a map field.
 */
export type FormRule = {
    /** The function name (in code) for the rule */
    functionName: string;
    /** Condition for the rule */
    condition: () => boolean;
    /** Error message for the rule */
    errorMessage?: string;
    /** Description of the rule */
    description?: Description;
    /** Metadata for the rule */
    meta?: Meta;
};

/**
 * Represents an action for a form state.
 * Is a map or object field.
 */
export type FormAction = {
    /** The action data */
    action: () => void;
    /** Description of the action */
    description?: Description;
    /** Metadata for the action */
    meta?: Meta;
};

/**
 * Represents UI definitions to display data in forms or entities.
 * Is a map or a DB document.
 * Builds the layout of the form UI.
 * Stored in subcollection `/formTypes/{formTypeId}/uiLayouts/` for the parent form type.
 */
export interface UILayout {
    /** Unique identifier for the UILayout 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Structure of the UILayout */
    structure: UIElement;
    /** Description of the UILayout */
    description?: Description;
    /** Metadata for the UILayout */
    meta?: Meta;
}

/** Enum representing the types of UI elements in the layout structure.
 * @enum {string}
 */
export const enum UIElementType {
    /** Grid container for complex, multi-column layouts. */
    Grid = 'grid',
    /** Row container for horizontal arrangement of UI elements. */
    Row = 'row',
    /** Column container for vertical arrangement of UI elements. */
    Column = 'column',
    /** Field element for rendering individual form fields or data display elements. */
    Field = 'field',
    /** Entity reference element for linking and displaying related entity data. */
    EntityReference = 'entityReference',
    /** Meta field element for rendering form metadata values. */
    MetaField = 'metaField',
    /** Form property element for rendering form-level properties like number and type. */
    FormProperty = 'formProperty'
}

/**
 * Represents a UI element in the layout structure.
 * Builds the layout of the form UI.
 * Can be either a container, a field, an entity reference, or a meta field.
 */
export type UIElement = UIContainer | UIField | UIEntityReference | UIMetaField | UIFormProps;

/**
 * Represents a container element in the UI layout.
 * Builds the layout of the form UI.
 * Can contain other containers, fields, or entity references.
 */
export interface UIContainer {
    /** Type of the container for UI rendering */
    uiElementType: UIElementType.Grid | UIElementType.Row | UIElementType.Column;
    /** Child elements of the container */
    children: UIElement[];
    /** Whether this container is required in this specific form type */
    required?: boolean;
    /** Optional properties for container rendering (e.g., width, height, gap) */
    props?: Record<string, any>;
}

/**
 * Represents a field element in the UI layout.
 * Builds the layout of the form UI.
 */
export interface UIField {
    /** Type of the element for UI rendering */
    uiElementType: UIElementType.Field;
    /** Field type of the field */
    fieldTypeId: FieldTypeId;
    /** ID of the field this element represents, maps to a Field value */
    fieldId: string;
    /** Whether this field is required in this specific form type */
    required?: boolean;
    /** Columns for the field (for table rendering) */
    columns?: TableColumnType[];
    /** Status of the field */
    status?: RecordStatus;
    /** Optional properties for field rendering */
    props?: Record<string, any>;
}

/**
 * Represents a meta field element in the UI layout.
 * Used to display individual meta values from a Form.
 */
export interface UIMetaField {
    /** Type of the element for UI rendering */
    uiElementType: UIElementType.MetaField;
    /** Key of the meta field to display */
    id: keyof Meta | keyof Form;
    /** Whether this meta field is required in this specific form type (for compatibility with other UI elements) */
    required?: boolean;
    /** Optional properties for meta field rendering */
    props?: Record<string, any>;
}

/**
 * Represents a form property element in the UI layout.
 * Used to display form-level properties.
 */
export interface UIFormProps {
    /** Type of the element for UI rendering */
    uiElementType: UIElementType.FormProperty;
    /** Key of the form property to display */
    id: keyof Form;
    /** Whether this form property is required in this specific form type (for compatibility with other UI elements) */
    required?: boolean;
    /** Optional properties for form property rendering */
    props?: Record<string, any>;
}

/**
 * Represents the aggregation types that can be used in Table components.
 */
export type TableAggregationType = 'sum' | 'avg' | 'min' | 'max' | 'count' | 'none';

/**
 * Represents the calculated field types that can be used in Table components.
 */
export type CalculatedFieldType = FieldType & {
    /** Formula or calculation logic for this field */
    formula?: string;
};

/**
 * Represents a column definition for tables with field type information.
 * Used to define the structure and behavior of table columns.
 */
export type TableColumnType = {
    /** Unique identifier for the field, corresponds to `fieldId` (this prop is used by antd) */
    dataIndex: string;
    /** Type of UI element for this column */
    uiElementType?: UIElementType;
    /** Type of field to render in this column */
    fieldTypeId?: SystemFieldTypeId | CalculatedFieldType["fieldDataTypeId"];
    /** Display title for the column header */
    title?: string;
    /** Description for the column header */
    description?: Description;
    /** Type of aggregation to perform on this column for totals */
    aggregationType?: TableAggregationType;
    /** Entity ID for entity reference columns */
    entityId?: string;
    /** Visibility of the column */
    // #### TODO: leaving this in for backwards compatibility, but should be removed in the future
    visibility?: {
        /** Visible to internal users */
        internal?: boolean;
        /** Visible in print view */
        print?: boolean;
        /** Visible in contractor view */
        contractor?: boolean;
    };
    /** Additional antd Table column properties */
    [key: string]: any;
};

/**
 * Represents an entity reference element in the UI layout.
 * Builds the layout of the form UI.
 */
export interface UIEntityReference {
    /** Type of the element for UI rendering */
    uiElementType: UIElementType.EntityReference;
    /** Key of the entity reference in the form's entityReferences */
    entityId: Entity["docId"];
    /** Whether this field is required in this specific form type */
    required?: boolean;
    /** Optional properties for entity reference rendering */
    props?: Record<string, any>;
}

/**
 * Represents a field-based query condition for filtering records.
 * Used to define conditions for filtering record groups.
 */
export type FieldQueryCondition = {
    /** Field path within the record's fields object or a top-level document field */
    fieldPath: string;
    /** Comparison operator to apply to the field (e.g., '==', 'array-contains', etc.) */
    operator: WhereFilterOp;
    /** Value to compare against */
    value: any;
};

/** 
 * Represents a record group definition with optional query conditions
 * Groups can be filtered by:
 * 1. Basic groups (description only)
 * 2. Field-based queries (using field conditions)
 * 3. Combined queries (using multiple field conditions)
 */
export type RecordGroup = {
    /** Unique identifier for the record group
     * This field is system generated and not present in the document data as a field.
     */
    groupId?: string;
    /** Description of the record group */
    description: Description;
    /** 
     * Query conditions that define this group's membership
     * Each condition is a field-based query that will be combined with AND logic
     */
    queryConditions: FieldQueryCondition[];
    /** Metadata for the record group */
    meta?: Meta;
};

/**
 * Represents an type of record entity in the system, such as customer or project.
 * Is a DB document.
 * Stored in the collection `entities/`.
 * Named 'Reference Types' in the UI.
 */
export type Entity = {
    /** Unique identifier for the entity 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Status of the entity */
    status: RecordStatus;
    /** Default UILayout for the entity */
    defaultUILayout: UILayout;
    /** Description of the entity */
    description?: Description;
    /** User roles associated with the entity */
    userRoles?: { 
        [key: string]: UserDBRole 
    };
    /** User group associated with the entity (singular) */
    userGroup?: string;
    /** Record groups defined for this entity */
    recordGroups?: {[key: string]: RecordGroup;};
    /** Metadata for the entity */
    meta?: Meta;
    // Note: EntityRecords are stored in a subcollection `entities/{entityId}/records/`
    // but are not referenced here as they are handled by the database structure
};

/**
 * Represents a specific record of an Entity, such as a particular customer or project.
 * Is a DB document.
 * Stored in the subcollection `entities/{entityId}/records/`.
 * Named 'Records' in the UI.
 */
export type EntityRecord = {
    /** Unique identifier for the entity record
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Status of the entity record */
    status?: RecordStatus;
    /** Name of the entity record */
    name: string;
    /** Fields storing the data for this entity record */
    fields: Fields;
    /** References to EntityRecords used in this entity record */
    entityReferences?: {
        [entityId: string]: EntityRecord["docId"];
    };
    /** Groups this record belongs to */
    recordGroupIds?: string[];
    /** Subscribers to notifications for the entity record */
    notificationsSubscribers?: string[];
    /** Metadata for the entity record */
    meta?: Meta;
};

/**
 * Represents a collection of Field with user defined meaning and values.
 * Is a map field.
 */
export type Fields = {
    [field: string]: Field;
};

/**
 * Represents a org-level definition and the stored data value.
 */
export type Field = {
    /** Value of the field */
    value: any;
    /** Description of the field */
    description?: Description;
    /** Metadata for the field */
    meta?: Meta;
};

/**
 * Represents the possible validation states for a field.
 * - 'error': Field has invalid input
 * - 'warning': Field has concerning but valid input
 * - '': Field is in a neutral state
 * - undefined: Field has not been validated
 */
export type ValidationStatus = 'error' | 'warning' | '' | undefined;

/**
 * Represents a type of Field.
 * Defines the data type and the UI rendering.
 */
export type FieldType = {
    /** Unique identifier for the field type */
    fieldTypeId?: FieldTypeId;
    /** The base field data type ID for this field type */
    fieldDataTypeId: SystemFieldTypeId;
    /** UI labels for the field type */
    description?: Description;
    /** Status of the field type */
    status?: RecordStatus;
    /** Visibility of the field */
    visibility?: FieldViewVisibility[];
    /** Metadata for the field type */
    meta?: Meta;
    /** Default value for the field */
    defaultValue?: any;
    /** Validation rules for the field */
    validation?: ((value: any) => boolean);
    /** Error message for the field */
    errorMessage?: string;
    /** Format value for display */
    formatter?: (value: any) => string;
    /** Parse displayed value back to stored value */
    parser?: (displayValue: string | undefined) => any;
    /** Prefix for the field */
    prefix?: string;
    /** Suffix for the field */
    suffix?: string;
    /** Additional props passed to the control */
    [key: string]: any;
}

/**
 * Represents a UI control in the system.
 */
export type UIControl = {
    /** Unique identifier for the UI control */
    uiControlId: string;
    /** antd UI control to use (e.g., 'Input', 'Select', 'DatePicker') */
    uiControl: string;
    /** Properties for the UI control */
    uiControlProps?: {
        [key: string]: any;
    };
    /** Description of this UI option */
    description?: Description;
};

/**
 * Represents user settings and preferences stored in Firestore.
 */
export type UserSettings = {
    /** System theme preference */
    preferDarkMode?: string;
    /** User role in the system */
    role?: string;
    /** Current organization context */
    currentOrganization?: string;
    /** Unread notifications for the user */
    unreadNotifications?: Notification[];
    /** Read notifications for the user */
    readNotifications?: Notification[];
    /** Filter preferences for views */
    filterPreferences?: {
        /** Entity type filter selection */
        entityType?: string;
        /** Date range filter selection */
        dateRange?: [string, string];
        /** Form type filter selections */
        formTypes?: [string, string, string][];
        /** Filter mode selection */
        filterMode?: string;
    };
};

/**
 * Represents a user in the system.
 * Is a DB document.
 * Stored in the collection `users/`.
 */
export type User = {
    /** Unique identifier for the user 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Name of the user */
    name: string;
    /** Email of the user */
    email: string;
    /** Super user flag */
    super?: boolean;
    /** User data at creation */
    propertiesAtCreation?: any;
    /** Last time user signed in */
    lastSignedIn?: TimeType;
    /** Metadata for the user */
    meta: Meta;
    /** User settings and preferences */
    settings?: UserSettings;
    /** Unread notifications for the user */
    unreadNotifications: Notification[];
    /** Read notifications for the user */
    readNotifications: Notification[];
};

/**
 * Represents a notification for the user.
 */
export type Notification = {
    /** Event that triggered the notification */
    notificationEvent: NotificationEvent;
    /** Timestamp of when the notification was created */
    timestamp: TimeType;
}

/**
 * Represents the type of form change.
 */
export type FormChangeType = 'STATE_CHANGE' | 'NEW_VERSION' | 'NEW_FORM' | 'SET_CURRENT_VERSION';

/**
 * Represents the type of change that triggered a notification.
 */
export type NotificationChangeType = FormChangeType | 'EMAIL_ANALYSIS' | 'ENTITY_UPDATE';

/**
 * Represents email analysis notification data
 */
export interface DismissInfo {
    userId: string;
    timestamp: TimeType;
    reason?: string;  // Max 256 chars
}

export interface EmailAnalysisNotificationData {
    emailAnalysisId: string;  // ID of the email analysis record
    emailSubject: string;
    project?: string;
    actionItems?: string[];
    suggestedFormType: FormType;
    assignedToUserId: string;
    assignedToUser: string;
    assignedToEmail: string;
    formId?: string;
    approvedBy?: {
        userId: string;
        timestamp: TimeType;
    };
    dismissedBy?: DismissInfo;
}

/**
 * Represents an event that triggered a notification.
 */
export type NotificationEvent = {
    /** Unique identifier for the log entry */
    docId?: DocId;
    /** Timestamp of when the event occurred */
    timestamp: TimeType;
    /** Description of the event */
    message: string;
    /** Type of the event */
    changeType: NotificationChangeType;
    /** Associated form ID, if applicable */
    formId?: string;
    /** Origin URI of the notification, if applicable */
    relatedUri?: string;
    /** Associated user ID, if applicable */
    userId?: string;
    /** Additional metadata for the event */
    meta?: Meta;
    /** Additional data specific to the notification type */
    data?: EmailAnalysisNotificationData;
};

/**
 * Represents an event log entry in the system.
 * Is a DB document.
 * Stored in the collection `eventLogs/`.
 */
export type EventLogEntry = {
    /** Unique identifier for the log entry 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Timestamp of when the event occurred */
    timestamp: TimeType;
    /** Type of the event */
    agentType: AgentType;
    /** Category of the event */
    eventCategory: EventCategory;
    /** Description of the event */
    description: string;
    /** Associated form ID, if applicable */
    formId?: string;
    /** Associated user ID, if applicable */
    userId?: string;
    /** Changed values, if any */
    changedValues?: {
        [key: string]: {
            oldValue?: any;
            newValue: any;
        };
    };
    /** Indicates if the event is an error */
    isError?: boolean;
    /** Additional metadata for the event */
    meta?: Meta;
};

/**
 * Enum representing the types of events that can be logged.
 * Is a Enum field.
 */
export enum AgentType {
    /** Represents a human user */
    User = "User",
    /** Represents an AI language model agent */
    LLMAgent = "LLMAgent",
    /** Represents a scheduling agent */
    Scheduler = "Scheduler",
    /** Represents the system itself */
    System = "System"
}

/**
 * Enum representing the categories of events.
 * Is a Enum field.
 */
export enum EventCategory {
    /** Event related to creating a new form */
    FormCreation = "FormCreation",
    /** Event related to updating an existing form */
    FormUpdate = "FormUpdate",
    /** Event related to changing the state of a form */
    FormStateChange = "FormStateChange",
    /** Event related to archiving a form */
    FormArchive = "FormArchive",
    /** Event related to system maintenance tasks */
    SystemMaintenance = "SystemMaintenance",
    /** Event related to user management operations */
    UserManagement = "UserManagement",
    /** Event related to a metered cost */
    MeteredEvent = "MeteredEvent",
    /** Event related to an error */
    Error = "Error",
    /** Event related to AI completion operations */
    LLMCompletion = "LLMCompletion",
    /** Event related to sequence number generation */
    SequenceGeneration = "SequenceGeneration",
    /** Event related to sending emails */
    EmailSend = "EmailSend",
}

/**
 * Represents an associated file in the system.
 * Is a DB document.
 * Stored in the collection `attachments/`.
 */
export type Attachment = {
    /** Unique identifier for the attachment */
    docId?: DocId;
    /** Original filename */
    name: string;
    /** File size in bytes */
    size: number;
    /** MIME type */
    type: string;
    /** URL to access the file in storage */
    storageUrl: string;
    /** Full path in Firebase Storage */
    storagePath: string;
    /** Storage reference for the file */
    storageRef: StorageReference;
    /** Path to the form version the attachment originated from */
    formVersionPath?: string;
    /** Path to the entity record the attachment originated from */
    entityRecordPath?: string;
    /** Metadata */
    meta: Meta;
};

/**
 * Represents a user role in the system.
 */
export type UserRole = {
    /** Optional array of permissions associated with the role */
    permissions?: { 
        [key: string]: string 
    };
    /** Description of the user role */
    description?: Description;
    /** Metadata for the user role */
    meta?: Meta;
};

/**
 * Enum representing the user roles for documents and collections in the document database.
 */
export enum UserDBRole {
    /** Owner: read, write and manage access */
    owner = "owner",
    /** Editor: read, write access */
    editor = "editor",
    /** Viewer: read access */
    viewer = "viewer"
}

/** 
 * Represents a group in the system.
 * Is a DB document.
 * Stored in the collection `groups/`.
 */
export type Group = {
    /** Unique identifier for the group 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Name of the group */
    name: string;
    /** Organization the group belongs to */
    organization: string;
    /** Description of the group */
    description?: string;
    /** Metadata for the group */
    meta?: Meta;
    /** Subcollection `groups/{groupId}/groupMembers/` for members of this group */
};

/**
 * Represents a group member.
 * Is a DB document.
 * Stored in the subcollection `groups/{groupId}/groupMembers/`.
 */
export type GroupMember = {
    /** User ID of the member 
     * This field is system generated and not present in the document data as a field.
     * Generally, JSON files and document write operations should not include this field.
     */
    docId?: DocId;
    /** Role of the member in the group */
    role: UserDBRole;
    /** Metadata for the group member */
    meta?: Meta;
};

/**
 * Represents a user readable description of a data object.
 * Is a map field.
 */
export type Description = {
    /** Short label for the action */
    shortLabel?: string;
    /** Short description of the action */
    shortDescription?: string;
    /** Long label for the action */
    longLabel?: string;
    /** Long description of the action */
    longDescription?: string;
};

/**
 * Represents metadata for top level objects.
 * Is a map field.
 */
export type Meta = {
    /** Timestamp of the creation */
    created?: TimeType;
    /** Timestamp of the last modification */
    lastModified?: TimeType;
    /** User ID last changed the object */
    userId?: string;
    /** Type of the event */
    agentType?: AgentType;
    /** Version of the object */
    version?: string;
    /** Extra metadata for the object */
    [key: string] : any
};

/**
 * Represents a document identifier in the document database.
 * This field is system generated and not present in the document data as a field.
 * Generally, JSON files and document write operations should not include this field.
 */
export type DocId = string;

/**
 * Represents a document data with an identifier.
 * We add the document Id to the data to make it easier to work with.
 * Not to be used in writing to the database—the database should not have a duplicate Id.
 */
export type DocDataWithId<T extends DocumentData = DocumentData> = T & { docId?: DocId };

/**
 * Represents a UI element in the editor with drag and drop capabilities.
 * Extends UIElement with drag and drop specific properties.
 */
export type EditorUIElement = UIElement & {
    dndId: string;
    children?: EditorUIElement[];
};

/**
 * Represents a container element that can hold other UI elements.
 * Extends UIContainer with drag and drop specific properties.
 */
export type EditorUIContainer = UIContainer & {
    dndId: string;
    children: EditorUIElement[];
};

/**
 * Represents the complete layout structure with drag and drop enabled elements.
 */
export type EditorUILayout = UILayout & {
    structure: EditorUIElement;
};

/**
 * Represents the type of layout editor (form or entity).
 */
export type LayoutEditorType = 'form' | 'entity';

/**
 * Represents a recipe item in a sequence format.
 * Is a map field.
 * Defines how each part of the sequence should be formatted.
 */
export type SequenceRecipeItem = {
    /** The format string or number for this part of the sequence.
     * For TimeType, supports Day.js format tokens:
     * - YY: Two-digit year (23)
     * - YYYY: Full year (2023)
     * - M: Month as 1-12
     * - MM: Month as 01-12
     * - D: Day of month as 1-31
     * - DD: Day of month as 01-31
     * For sequencePadding type:
     * - Number indicating total width after padding (e.g., 5 for 00234)
     * For string type:
     * - Use literal string value
     */
    format: string | number;
    /** The type of the sequence part:
     * - string: literal string value
     * - TimeType: date/time format using Day.js tokens
     * - sequencePadding: auto-incrementing number with zero padding to specified width
     */
    type: 'string' | 'TimeType' | 'sequencePadding';
};


/**
 * Reset rule configuration for sequences.
 * Add a new time unit to the enum if you want to add a new reset rule.
 */
export type SequenceResetRule = {
    /** Time unit to check for reset */
    timeUnit: 'day' | 'month' | 'year';
    /** What number to reset the sequence to */
    resetTo: number;
};

/**
 * Represents a sequence configuration for generating sequential numbers.
 * Is a DB document.
 * Stored in the collection `sequences/`.
 * Contains both the recipe and current sequence state.
 */
export type Sequence = {
    /** User roles associated with the sequence */
    userRoles?: { 
        [key: string]: UserDBRole 
    };
    /** User group associated with the sequence */
    userGroup?: string;
    /** Description of the sequence */
    description: Description;
    /** Metadata for the sequence */
    meta: Meta;
    /** Last generated sequence */
    lastSequence: string;
    /** Timestamp of last sequence reset */
    lastReset?: TimeType;
    /** Previous sequence before reset */
    lastSequenceBeforeReset?: string;
    /** Current sequence number */
    sequenceNumber: number;
    /** Separator between sequence parts */
    separator: string;
    /** Recipe for generating the sequence */
    recipe: SequenceRecipeItem[];
    /** Optional reset rule */
    resetRule?: SequenceResetRule;
};
