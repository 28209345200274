import React, { useContext, useMemo } from 'react';
import { Timestamp } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import {
  Button,
  Divider,
  Empty,
  Spin,
  Typography,
  Tag,
} from 'antd';

import { UserContext } from '../../providers/UserProvider';
import FlexCard from '../atoms/FlexCard';
import FlexCol from '../atoms/FlexCol';
import Icon from '../atoms/Icon';
import EmailAnalysisNotificationCard from './EmailAnalysisNotificationCard';
import { NotificationChangeType, Notification, FormType, EmailAnalysisNotificationData } from '../../types/System.types';

const { Text } = Typography;

const changeTypeColors: Record<NotificationChangeType, string> = {
  'STATE_CHANGE': 'cyan',
  'NEW_VERSION': 'green',
  'NEW_FORM': 'blue',
  'EMAIL_ANALYSIS': 'purple',
  'ENTITY_UPDATE': 'orange',
  'SET_CURRENT_VERSION': 'yellow',
};

const changeTypeLabels: Record<NotificationChangeType, string> = {
  'STATE_CHANGE': 'Form Status Updated',
  'NEW_VERSION': 'New Form Version',
  'NEW_FORM': 'New Form',
  'EMAIL_ANALYSIS': 'Email Analysis',
  'ENTITY_UPDATE': 'Entity Updated',
  'SET_CURRENT_VERSION': 'Current Form Version Set',
};

type ViewType = 'Unread' | 'All';

interface NotificationsTableProps {
  view: ViewType;
}

const NotificationsTable: React.FC<NotificationsTableProps> = ({ view }) => {
  const {
    unreadNotifications,
    readNotifications,
    toggleNotificationRead
  } = useContext(UserContext);

  const notifications = useMemo(() => {
    if (!unreadNotifications && !readNotifications) return null;

    if (view === 'Unread') {
      return [...(unreadNotifications || [])].sort((a, b) =>
        (b.timestamp as Timestamp).toMillis() - (a.timestamp as Timestamp).toMillis()
      );
    }

    const allNotifications = [
      ...(unreadNotifications || []),
      ...(readNotifications || [])
    ].sort((a, b) =>
      (b.timestamp as Timestamp).toMillis() - (a.timestamp as Timestamp).toMillis()
    );

    return allNotifications;
  }, [unreadNotifications, readNotifications, view]);

  if (!notifications) {
    return <Spin />;
  }

  if (notifications.length === 0) {
    return (
      <FlexCard style={{ width: '100%' }} alignCenter>
        <Empty
          description={`No ${view === 'Unread' ? 'unread' : ''} notifications`}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 30 }}
          style={{ margin: 5 }}
        />
      </FlexCard>
    );
  }

  const renderNotification = (notification: Notification, index: number) => {
    const {
      notificationEvent,
      timestamp
    } = notification;
    const {
      docId,
      relatedUri,
      message,
      changeType,
      meta,
      data
    } = notificationEvent;
    const isRead = readNotifications?.some(n => n.notificationEvent.docId === docId);
    const isApproved = changeType === 'EMAIL_ANALYSIS' && 
      (data as EmailAnalysisNotificationData)?.approvedBy !== undefined;
    const isDismissed = changeType === 'EMAIL_ANALYSIS' && 
      (data as EmailAnalysisNotificationData)?.dismissedBy !== undefined;

    if (changeType === 'EMAIL_ANALYSIS' && data) {
      return (
        <div key={docId} style={{ width: '100%' }}>
          <EmailAnalysisNotificationCard
            notification={notification}
            isApproved={isApproved}
          />
          {index < notifications.length - 1 && <Divider style={{ margin: 0 }} />}
        </div>
      );
    }

    const {
      userName,
      userId
    } = meta || {};
    const userIdentifier = userName || userId || '';

    return (
      <div key={docId} style={{ width: '100%' }}>
        <Button
          type="text"
          style={{
            width: '100%',
            height: 'auto',
            paddingTop: 5,
            paddingBottom: 10,
            borderRadius: 0,
            whiteSpace: 'normal',
            textAlign: 'left',
            ...(isDismissed && {
              backgroundColor: '#fff7e6',
              borderLeft: '3px solid #faad14'
            })
          }}
          onClick={(e) => {
            if (!(e.target as HTMLElement).closest('a')) {
              toggleNotificationRead(notification);
            }
          }}
        >
          {isRead ?
            <Icon.CheckCircle /> :
            <Icon.Circle />
          }
          <FlexCol 
            stretch 
            gap={0} 
            style={{ 
              marginLeft: 10, 
              opacity: isRead ? 0.6 : 1 
            }}
          >
            <Text
              delete={isRead}
              type={isRead ? "secondary" : undefined}
            >
              {relatedUri ? (
                <Link to={relatedUri}>
                  {message}
                </Link>
              ) : (
                <>
                  {message}
                </>
              )}
              &nbsp;by {userIdentifier}
            </Text>
            <Text type="secondary">
              <Tag color={changeTypeColors[changeType]}>
                {changeTypeLabels[changeType] || changeType}
              </Tag>
              {isDismissed && (
                <Tag color="warning">Dismissed</Tag>
              )}
              {(timestamp as Timestamp).toDate().toLocaleString()}
            </Text>
          </FlexCol>
        </Button>
        {index < notifications.length - 1 && <Divider style={{ margin: 0 }} />}
      </div>
    );
  };

  return (
    <FlexCard
      style={{ width: '100%' }}
      gap={0}
    >
      {notifications.map((notification, index) => 
        renderNotification(notification, index)
      )}
    </FlexCard>
  );
};

export default NotificationsTable; 