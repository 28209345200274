import { useContext, useEffect } from 'react';
import { Input, Button, Grid, message, theme } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

import FlexBox from '../atoms/FlexBox';
import { AIContext } from '../../providers/AIProvider';

const { useToken } = theme;

export default function ModelInput() {
    const [messageApi, contextHolder] = message.useMessage();
    const screen = Grid.useBreakpoint();
    const { token } = useToken();
    
    const {
        streamModel,
        inputPrompt,
        setInputPrompt,
        isLoading,
        setIsLoading,
        formId,
        versionId,
    } = useContext(AIContext);

    const handleEnter = async () => {
        if (inputPrompt.trim() === '') return;

        setIsLoading(true);

        try {
            await streamModel(inputPrompt);
            setInputPrompt('');
        } catch (e) {
            if (e instanceof Error) {
                messageApi.error(e.message);
            }
            console.error('Error in model input:', e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <FlexBox
            gap={5}
            alignStart
            style={{
                padding: 20,
                marginBottom: 20,
            }}
        >
            <FlexBox 
                column 
                gap={0}
            >
                {contextHolder}
                <Input.TextArea
                    id="inputPrompt"
                    placeholder={formId ? "Modify form..." : "Let's talk..."}
                    allowClear
                    maxLength={8000}
                    value={inputPrompt}
                    onChange={(e) => setInputPrompt(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleEnter();
                        }
                    }}
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    style={{
                        borderColor: token.colorPrimaryBorder,
                        boxShadow: token.boxShadow,
                    }}
                />
            </FlexBox>
            <Button
                icon={<DoubleRightOutlined />}
                onClick={handleEnter}
                loading={isLoading}
                style={{
                    boxShadow: token.boxShadow,
                }}
            >
                {screen.sm && 'ENTER'}
            </Button>
        </FlexBox>
    );
}