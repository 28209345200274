import { FC, useContext, useState } from 'react';
import { Select, Tooltip, message, theme } from 'antd';
import { BellOutlined, BellFilled, UserAddOutlined, DownOutlined } from '@ant-design/icons';
import { UserContext } from '../../../providers/UserProvider';
import { FormContext } from '../../../providers/artifacts/FormProvider';

const { useToken } = theme;

/** Control for managing form notification subscribers */
const FormSubscriberControl: FC = () => {
    const { token } = useToken();
    const [isUpdating, setIsUpdating] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { selectedForm } = useContext(FormContext);
    const { user, users, updateFormSubscribers } = useContext(UserContext);

    const handleChange = async (values: string[]) => {
        if (!selectedForm.data?.docId) return;

        setIsUpdating(true);

        try {
            await updateFormSubscribers(selectedForm.data.docId, values);
            messageApi.success('Notification subscribers updated');
        } catch (err) {
            messageApi.error(`Failed to update subscribers: ${err instanceof Error ? err.message : String(err)}`);
        } finally {
            setIsUpdating(false);
        }
    };

    const currentSubscribers = selectedForm.data?.notificationsSubscribers ?? [];
    const isSubscribed = user?.uid ? currentSubscribers.includes(user.uid) : false;

    const quickActions = [{
        value: user?.uid ?? '',
        label: (
            <span style={{ color: token.colorPrimary }}>
                {isSubscribed ? <BellFilled /> : <BellOutlined />}
                {isSubscribed ? ' Unsubscribe me' : ' Subscribe me'}
            </span>
        ),
        title: '',
        disabled: !user?.uid
    }];

    const userOptions = users
        ?.filter(u => u.docId !== user?.uid)
        .map(u => ({
            value: u.docId,
            label: (
                <>
                    <UserAddOutlined /> {u.name || u.email?.split('@')[0] || u.docId} {<span>{u?.email}</span>}
                </>
            ),
            title: `${u.name || ''} ${u.email}`.trim()
        })) ?? [];

    const getSubscriberName = (userId: string) => {
        const user = users?.find(u => u.docId === userId);
        return (user?.name || user?.email || userId)?.split(/[ @.]/)[0];
    };

    return (
        <Tooltip title="Manage notification subscribers">
            {contextHolder}
            <Select
                mode="multiple"
                value={currentSubscribers}
                onChange={handleChange}
                loading={isUpdating}
                style={{
                    width: 210,
                    height: token.controlHeight,
                    padding: 0
                }}
                prefix={
                    currentSubscribers.length === 0 ?
                        <BellOutlined /> :
                        <BellFilled />
                }
                suffixIcon={<DownOutlined style={{ pointerEvents: 'none' }} />}
                dropdownStyle={{ minWidth: 320 }}
                optionFilterProp='title'
                showSearch
                options={[
                    ...quickActions,
                    ...userOptions
                ]}
                placeholder={isDropdownOpen ?
                    <div style={{ marginTop: -2 }}>
                        Search users...
                    </div> :
                    <div style={{ marginTop: -2 }}>
                        No subscribers
                    </div> 
                }
                onDropdownVisibleChange={setIsDropdownOpen}
                maxTagCount={0}
                filterOption={(input, option) =>
                    option?.title?.toLowerCase().includes(input.toLowerCase()) ?? false
                }
                tagRender={({ }) => {
                    const tagSelectionStyle = {
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: -1,
                        marginRight: 4
                    };
                    if (currentSubscribers.length === 1) {
                        if (currentSubscribers[0] === user?.uid) {
                            return (
                                <div style={{ ...tagSelectionStyle }}>
                                    Subscribed
                                </div>
                            );
                        }
                        return (
                            <div style={{ ...tagSelectionStyle }}>
                                {getSubscriberName(currentSubscribers[0])}
                            </div>
                        );
                    }
                    return (
                        <div style={{ ...tagSelectionStyle }}>
                            Multiple
                        </div>
                    );
                }}
            />
        </Tooltip>
    );
};

export default FormSubscriberControl; 