import { FC } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { EmailRecord } from './emailParser';

/** Form data structure for email creation/editing */
interface EmailFormData {
    /** Email sender address */
    from?: string;
    /** List of recipient email addresses */
    to?: string[];
    /** List of CC recipient email addresses */
    cc?: string[];
    /** List of BCC recipient email addresses */
    bcc?: string[];
    /** Email subject line */
    subject?: string;
    /** Email message content */
    body?: string;
}

interface EmailFormProps {
    /** Whether the modal is visible */
    open: boolean;
    /** Callback when modal is cancelled */
    onCancel: () => void;
    /** Callback when form is saved */
    onSave: (data: EmailFormData) => void;
    /** Initial values for the form */
    initialValues?: EmailRecord;
}

export const EmailForm: FC<EmailFormProps> = ({
    open,
    onCancel,
    onSave,
    initialValues
}) => {
    const [form] = Form.useForm<EmailFormData>();

    const handleOk = async () => {
        try {
            const formData = await form.validateFields();
            onSave(formData);
            form.resetFields();
        } catch (error) {
            console.error('Failed to save email form:', error);
        }
    };

    return (
        <Modal
            title={initialValues ? 'Edit Email' : 'Add Email'}
            open={open}
            onOk={handleOk}
            onCancel={() => {
                onCancel();
                form.resetFields();
            }}
            width={800}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues || { to: [], cc: [], bcc: [] }}
            >
                <Form.Item
                    name="from"
                    label="From"
                    rules={[
                        { type: 'email', message: 'Please enter a valid email' }
                    ]}
                >
                    <Input placeholder="Sender email address" />
                </Form.Item>
                <Form.Item
                    name="to"
                    label="To"
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Enter or paste email addresses"
                        tokenSeparators={[',', ' ', ';']}
                    />
                </Form.Item>
                <Form.Item 
                    name="cc" 
                    label="CC"
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Enter or paste email addresses"
                        tokenSeparators={[',', ' ', ';']}
                    />
                </Form.Item>
                <Form.Item 
                    name="bcc" 
                    label="BCC"
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Enter or paste email addresses"
                        tokenSeparators={[',', ' ', ';']}
                    />
                </Form.Item>
                <Form.Item
                    name="subject"
                    label="Subject"
                >
                    <Input placeholder="Email subject" />
                </Form.Item>
                <Form.Item 
                    name="body" 
                    label="Message"
                >
                    <Input.TextArea 
                        rows={4} 
                        placeholder="Email message"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}; 