import React, {
  useState,
  useContext,
  useEffect,
  useRef
} from 'react';
import {
  Spin,
  Form,
  Button,
  Alert,
  Divider,
  Input,
  Modal,
  Typography,
} from 'antd';
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';
import {
  useNavigate,
  useLocation
} from 'react-router-dom';
import { AuthCredential } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';

import useFirebaseAuth from '../use/useFirebaseAuth';
import { ThemeContext } from '../providers/ThemeProvider';
import FlexBox from '../components/atoms/FlexBox';
import FlexContent from '../components/atoms/FlexContent';
import FlexPage from '../components/atoms/FlexPage';

const { Text } = Typography;

interface AuthError extends Error {
  code: string;
  email?: string;
  customData?: {
    email?: string;
  };
  errorInfo?: {
    email: string;
    providerId: string;
    errorCode: string;
    credential?: AuthCredential;
  };
}

const LoginPage: React.FC = () => {
  const {
    user,
    login,
    signup,
    signInWithGoogle,
    signInWithMicrosoft,
    getAuthRedirectResult
  } = useFirebaseAuth();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [registerMode, setRegisterMode] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [linkedEmail, setLinkedEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { darkMode } = useContext(ThemeContext);
  const location = useLocation();
  const [linkForm] = Form.useForm();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get('mode');
    setRegisterMode(mode === 'signup');
  }, [location]);

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        await getAuthRedirectResult();
      } catch (e) {
        const err = e as AuthError | FirebaseError;
        const errorCode = 'code' in err ? err.code : undefined;

        let emailValue = '';
        if ('email' in err && err.email) {
          emailValue = err.email as string;
        } else if ('customData' in err && err.customData?.email) {
          emailValue = err.customData.email as string;
        } else if ('errorInfo' in err && err.errorInfo?.email) {
          emailValue = err.errorInfo.email;
        }

        if (isMounted.current) {
          if (errorCode === 'auth/account-exists-with-different-credential' && emailValue) {
            setLinkedEmail(emailValue);
            setIsModalVisible(true);
          } else {
            setError(`Sorry, that didn't work, please try again.`);
          }
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    handleRedirectResult();
  }, [getAuthRedirectResult]);

  const handleLogin = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await login(email, password);
    } catch (e) {
      if (!isMounted.current) return;
      
      const err = e as any;
      const errorCode = err.code || err.errorInfo?.errorCode;
      const email = err.email || err.customData?.email || err.errorInfo?.email;
      if (errorCode === 'auth/account-exists-with-different-credential' && email) {
        setLinkedEmail(email);
        setIsModalVisible(true);
      } else {
        setError(`Sorry, that didn't work, please try again.`);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  const handleSignup = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await signup(email, password);
    } catch (e) {
      if (!isMounted.current) return;
      
      const err = e as any;
      const errorCode = err.code || err.errorInfo?.errorCode;
      const email = err.email || err.customData?.email || err.errorInfo?.email;
      if (errorCode === 'auth/account-exists-with-different-credential' && email) {
        setLinkedEmail(email);
        setIsModalVisible(true);
      } else {
        setError(`Sorry, that didn't work, please try again.`);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  const handleSocialSignIn = async (provider: () => Promise<void>) => {
    setIsLoading(true);
    try {
      await provider();
    } catch (e) {
      if (isMounted.current) {
        setError(`Sorry, that didn't work, please try again.`);
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  const toggleRegisterMode = () => {
    setError(null);
    setRegisterMode(!registerMode);
  };

  const handleLinkFormSubmit = async (values: { email: string; password: string }) => {
    setEmail(values.email);
    setPassword(values.password);
    await handleLogin();
  };

  return (
    <FlexPage>
      <FlexContent style={{
        alignContent: 'center',
        justifyContent: 'center'
      }}>
        {user ? (
          <Spin size='large' />
        ) : (
          <FlexBox
            column
            noGrow
            gap={0}
            style={{
              maxWidth: '290px'
            }}
          >
            <img
              src={darkMode ? '/art/Syncretic-logo-vertical-negative.svg' : '/art/Syncretic-logo-vertical.svg'}
              alt='Syncretic'
              style={{
                marginTop: 20,
                marginBottom: 50,
                width: '100%',
              }}
            />
            <FlexBox
              column
              style={{
                textAlign: 'center',
                width: '100%',
              }}
            >
              <Button
                onClick={() => handleSocialSignIn(signInWithGoogle)}
                size='large'
                style={{
                  width: '100%',
                  backgroundColor: '#444444',
                  color: '#fff',
                  border: 'none',
                  textAlign: 'center',
                }}
              >
                <img
                  src='/art/Google__G__Logo.svg'
                  alt="Google sign-in"
                  style={{
                    paddingRight: 10,
                    height: 20,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                />
                Sign in with Google
              </Button>
              <Button
                onClick={() => handleSocialSignIn(signInWithMicrosoft)}
                size='large'
                style={{
                  width: '100%',
                  backgroundColor: '#444444',
                  color: '#fff',
                  border: 'none',
                  textAlign: 'center',
                }}
              >
                <img
                  src='/art/Microsoft_logo.svg'
                  alt="Microsoft sign-in"
                  style={{
                    paddingRight: 10,
                    height: 20,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                />
                Sign in with Microsoft
              </Button>
              <Divider>
                OR
              </Divider>
              <Form
                name="login_form"
                onFinish={() => registerMode ? handleSignup() : handleLogin()}
                style={{ width: '100%' }}
                initialValues={{ email, password }}
              >
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Item>
                <Form.Item>
                  <Alert
                    message={error}
                    type='error'
                    style={{
                      visibility: error ? 'visible' : 'hidden',
                      height: 40,
                      fontSize: '14px',
                    }}
                  />
                </Form.Item>
                {/* Signup button hidden for CLW, for now */}
                {false && <Button 
                    type="link" 
                    onClick={toggleRegisterMode} 
                    style={{ width: '100%' }}
                  >
                    {registerMode ? 'Already have an account? Log-in.' : "Don't have an account? Register."}
                  </Button>
                }
                <Form.Item>
                  <Button
                    style={{ width: '100%' }}
                    loading={isLoading}
                    htmlType="submit"
                    disabled={!email || !password}
                    icon={<LoginOutlined />}
                  >
                    {registerMode ? 'Register' : 'Log in'}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="link"
                    size='small'
                    onClick={() => navigate('/password-reset')}
                    style={{ width: '100%' }}
                  >
                    Reset password
                  </Button>
                </Form.Item>
              </Form>
            </FlexBox>
          </FlexBox>
        )}
        <Modal
          title="Link Your Accounts"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Text style={{ display: 'block', marginBottom: 20 }}>
            An account already exists with the email {linkedEmail}. Please sign in with your credentials to link the accounts.
          </Text>

          <Form
            form={linkForm}
            name="link_account"
            onFinish={handleLinkFormSubmit}
            initialValues={{ email: linkedEmail }}
            layout="vertical"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Email"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0, textAlign: 'right' }}>
              <Button
                onClick={() => setIsModalVisible(false)}
                style={{ marginRight: 10 }}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                Link Accounts
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </FlexContent>
    </FlexPage>
  );
}

export default LoginPage;
