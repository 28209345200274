import { FC, ReactNode } from 'react';
import { Typography, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface FieldLabelProps {
  label: ReactNode;
  required?: boolean;
  isSaving?: boolean;
  tooltip?: ReactNode;
}

const FieldLabel: FC<FieldLabelProps> = ({
  label,
  required,
  isSaving,
  tooltip
}) => {
  const labelContent = (
    <Text style={{ marginRight: 15 }}>
      {label}
      {required && (
        <Text type='secondary'>
          *
        </Text>
      )}
      {isSaving && (
        <Text 
          type='success' 
          style={{ 
            position: 'absolute',
            marginLeft: 3,
          }}
        >
          <LoadingOutlined />
        </Text>
      )}
    </Text>
  );

  return tooltip ? (
    <Tooltip title={tooltip}>
      {labelContent}
    </Tooltip>
  ) : labelContent;
};

export default FieldLabel; 