import PostalMime, { Address } from 'postal-mime';
import { Timestamp } from 'firebase/firestore';

/** Email data structure after parsing an EML file */
export interface ParsedEmail {
    /** Email sender address */
    from: string;
    /** List of recipient email addresses */
    to: string[];
    /** List of CC recipient email addresses */
    cc?: string[];
    /** List of BCC recipient email addresses */
    bcc?: string[];
    /** Email subject line */
    subject: string;
    /** Email message content */
    body?: string;
    /** Timestamp when the email was sent */
    timestamp: Timestamp;
    /** Whether the email contains attachments */
    hasAttachments: boolean;
    /** List of attachment references */
    attachments?: string[];
    /** Original email headers */
    headers?: Record<string, string>;
    /** Creation timestamp */
    createdAt: Timestamp;
    /** Last update timestamp */
    updatedAt: Timestamp;
}

/** Email record with unique identifier */
export interface EmailRecord extends ParsedEmail {
    /** Unique identifier for the email record */
    id: string;
}

export const parseEML = async (file: File): Promise<EmailRecord> => {
    const parser = new PostalMime();
    const buffer = await file.arrayBuffer();
    const email = await parser.parse(buffer);
    
    // Convert email addresses to strings and filter out invalid ones
    const getEmailList = (addresses: Address[] | undefined): string[] => 
        (addresses || [])
            .map(addr => addr.address || '')
            .filter(Boolean);

    // Store headers as a record of strings, properly handling header objects
    const headers: Record<string, string> = {};
    if (email.headers) {
        for (const [key, value] of Object.entries(email.headers)) {
            // Handle different types of header values
            if (typeof value === 'object' && value !== null) {
                if ('text' in value) {
                    headers[key] = value.text;
                } else if ('value' in value) {
                    headers[key] = value.value;
                } else {
                    headers[key] = JSON.stringify(value);
                }
            } else {
                headers[key] = String(value || '');
            }
        }
    }

    const now = Timestamp.now();
    const emailDate = email.date ? new Date(email.date) : new Date();

    return {
        id: crypto.randomUUID(),
        from: email.from?.address || '',
        to: getEmailList(email.to),
        cc: getEmailList(email.cc),
        bcc: getEmailList(email.bcc),
        subject: email.subject || '',
        body: email.text || email.html || '',
        timestamp: Timestamp.fromDate(emailDate),
        hasAttachments: Boolean(email.attachments?.length),
        attachments: [], // Placeholder for future attachment handling
        headers,
        createdAt: now,
        updatedAt: now
    };
}; 