import { useCallback, useContext } from 'react';
import { collection, query, where, limit, getDocs, QueryConstraint } from 'firebase/firestore';
import { Entity, EntityRecord, FieldQueryCondition, RecordGroup } from '../types/System.types';
import { DataContext } from '../providers/DataProvider';

interface EntityGroupSelection {
    entityId: Entity['docId'];
    groupId: RecordGroup['groupId'] | null;
}

interface UseGetEntityGroupRecordsProps {
    entities: Entity[] | null;
    recordLimit?: number;
}

export const useGetEntityGroupRecords = ({
    entities,
    recordLimit = 30
}: UseGetEntityGroupRecordsProps) => {
    const { firestore } = useContext(DataContext);

    return useCallback(async (selection: EntityGroupSelection): Promise<EntityRecord[] | null> => {
        const { entityId, groupId } = selection;

        if (!groupId) {
            // Entity-only selection
            try {
                const q = query(collection(firestore, `entities/${entityId}/records`), limit(recordLimit));
                const snapshot = await getDocs(q);
                return snapshot.docs.map(doc => ({
                    docId: doc.id,
                    ...doc.data()
                } as EntityRecord));
            } catch (error) {
                console.error('Error fetching entity records:', error);
                return null;
            }
        }

        // Group selection
        const parentEntity = entities?.find(e => e.recordGroups?.[groupId]);
        if (!parentEntity?.docId || !parentEntity.recordGroups) return null;

        const groupDefinition = parentEntity.recordGroups[groupId];
        const queryConditions = groupDefinition.queryConditions;

        try {
            const queryConstraints: QueryConstraint[] = [];

            // Apply each query condition as a where clause
            queryConditions.forEach((condition: FieldQueryCondition) => {
                queryConstraints.push(where(condition.fieldPath, condition.operator, condition.value));
            });

            queryConstraints.push(limit(recordLimit));

            const q = query(
                collection(firestore, `entities/${parentEntity.docId}/records`),
                ...queryConstraints
            );

            const snapshot = await getDocs(q);
            return snapshot.docs.map(doc => ({
                docId: doc.id,
                ...doc.data()
            } as EntityRecord));
        } catch (error) {
            console.error('Error in query conditions:', error);
            return null;
        }
    }, [entities, firestore, recordLimit]);
};
