import React from 'react';

import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import Navb from '../components/Navb';
import EmailAnalyzer from '../components/EmailAnalyzer';

const EmailAnalyzerPage: React.FC = () => {
    return (
        <FlexPage>
            <Navb />
            <FlexContent style={{ maxWidth: 1280 }}>
                <EmailAnalyzer />
            </FlexContent>
        </FlexPage>
    );
};

export default EmailAnalyzerPage; 