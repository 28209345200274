import { 
    TableColumnType, 
    TableAggregationType, 
    SystemFieldTypeId 
} from '../../../types/System.types';
import { fieldDataType } from '../../../types/System.FieldTypes.types';
import type { ReactNode } from 'react';

export const calculateColumnAggregations = (
    dataSource: any[] | null | undefined,
    columns: TableColumnType[],
    showColumnAggregations: boolean
): Record<string, number> | null => {
    if (!showColumnAggregations || !dataSource?.length) return null;

    return columns.reduce((acc, col) => {
        if (!col.aggregationType) {
            return acc;
        }

        const columnKey = col.dataIndex;
        const values = dataSource
            .map(row => row[columnKey])
            .filter(val => val !== null && val !== undefined)
            .map(val => Number(val))
            .filter(val => !isNaN(val));

        if (values.length === 0) {
            return acc;
        }

        let aggregationResult: number | null = null;
        switch (col.aggregationType) {
            case 'sum':
                aggregationResult = values.reduce((a, b) => a + b, 0);
                break;
            case 'avg':
                aggregationResult = values.reduce((a, b) => a + b, 0) / values.length;
                break;
            case 'min':
                aggregationResult = Math.min(...values);
                break;
            case 'max':
                aggregationResult = Math.max(...values);
                break;
            case 'count':
                aggregationResult = values.length;
                break;
            default:
                return acc;
        }

        if (aggregationResult !== null) {
            acc[columnKey] = aggregationResult;
        }
        return acc;
    }, {} as Record<string, number>);
};

export const formatAggregation = (
    value: number, 
    fieldTypeId: SystemFieldTypeId, 
    aggregationType?: TableAggregationType
): number => {
    const fieldType = fieldDataType[fieldTypeId];
    
    // Use the field type's formatter if available, otherwise return the raw value
    if (fieldType?.formatter) {
        const formatted = fieldType.formatter(value);
        return typeof formatted === 'number' ? formatted : value;
    }
    
    return value;
}; 