import { useState, useEffect, useContext, useMemo } from 'react';
import dayjs from 'dayjs';

import { FormVersion, EntityRecord } from '../types/System.types';
import { FormStateId, formStateDescription, defaultMetricStartTimeWindow } from '../types/System.Parameters.types';
import { useFormTypes } from './useFormTypes';
import { UserContext } from '../providers/UserProvider';
import { useBoundDocs } from './data/useBoundDocs';
import { useBoundCollectionGroup } from './data/useBoundCollectionGroup';
import { createFirestoreFilters } from './formviews/formviewsFirestoreFilters';
import { groupFormviews } from './formviews/formviewsGrouping';
import { formviewsClientSideFilters } from './formviews/formviewsClientSideFilters';

export type FormViewMode = 'all' | 'subscribed' | 'assigned';

/**
 * Hook for managing form views data on the homepage
 */
export function useFormviews() {
  const { 
    user, 
    settings, 
    updateSettings 
  } = useContext(UserContext);
  const userId = user?.uid;
  const formTypesData = useFormTypes();
  
  const [entitySelection, setEntitySelection] = useState<{ entityId: string; groupId: string | null } | null>(null);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([dayjs(defaultMetricStartTimeWindow), dayjs()]);
  const [selectedFormTypes, setSelectedFormTypes] = useState<string[]>([]);
  const [selectedFormStates, setSelectedFormStates] = useState<FormStateId[]>([]);
  const [viewMode, setViewMode] = useState<FormViewMode>('assigned');
  
  const {
    docs: formVersionDocs,
    loading: formVersionsLoading,
    error: formVersionsError,
    setFilters,
    data: formVersions,
  } = useBoundCollectionGroup<FormVersion>({
    collectionId: 'formVersions',
    initialLimit: 100,
    initialOrderBy: [{ field: 'meta.lastModified', direction: 'desc' }],
    initialFilters: createFirestoreFilters(dateRange, entitySelection),
  });
  
  const { 
    filteredVersions: viewModeFilteredVersions,
    entityRefs 
  } = useMemo(() => formviewsClientSideFilters(
    formVersions,
    userId,
    viewMode,
    selectedFormTypes,
    selectedFormStates,
    entitySelection
  ), [formVersions, userId, viewMode, selectedFormTypes, selectedFormStates, entitySelection]);
  
  const {
    data: entityRecords,
    loading: entityRecordsLoading
  } = useBoundDocs<EntityRecord>({
    paths: entitySelection?.entityId 
      ? entityRefs.map((id: string) => `entities/${entitySelection.entityId}/records/${id}`)
      : [],
    enabled: entityRefs.length > 0 && !!entitySelection?.entityId
  });
  
  const entityRecordMap = useMemo(() => {
    if (!entityRecords) return new Map<string, EntityRecord>();
    return new Map(entityRecords.map(record => [record.docId || '', record]));
  }, [entityRecords]);
  
  const groupedResults = useMemo(() => groupFormviews(
    viewModeFilteredVersions,
    entitySelection,
    entityRecordMap
  ), [viewModeFilteredVersions, entitySelection, entityRecordMap]);

  useEffect(() => {
    if (!settings) return;

    const newSettings = {
      ...settings,
      homePageFilters: {
        entitySelection: {
          entityId: entitySelection?.entityId || '',
          groupId: entitySelection?.groupId || '',
        },
        dateRange: [
          dateRange[0].format('YYYY-MM-DD'),
          dateRange[1].format('YYYY-MM-DD'),
        ],
        selectedFormTypes,
        selectedFormStates,
        filterMode: viewMode,
      },
    };

    updateSettings(newSettings);
  }, [
    settings,
    entitySelection,
    dateRange,
    selectedFormTypes,
    selectedFormStates,
    viewMode,
    updateSettings,
  ]);
  
  /**
   * Handles changes to the entity selection
   */
  const handleEntitySelectionChange = (selection: { entityId: string; groupId: string | null } | null) => {
    setEntitySelection(selection);
    const conditions = createFirestoreFilters(dateRange, selection);
    setFilters(conditions);
  };
  
  /**
   * Handles changes to the form type filter selection
   */
  const handleTypeFilterChange = (values: string[]) => {
    let filteredValues: string[] = [];
    
    if (values.includes('__select_all__')) {
      filteredValues = formTypesData.data?.map(type => type.docId || '') || [];
    } else if (values.includes('__clear_all__')) {
      filteredValues = [];
    } else {
      filteredValues = values.filter(v => v !== '__select_all__' && v !== '__clear_all__');
    }
    
    setSelectedFormTypes(filteredValues);
  };

  /**
   * Handles changes to the form state filter selection
   */
  const handleStateFilterChange = (values: string[]) => {
    let filteredValues: FormStateId[] = [];
    
    if (values.includes('__select_all__')) {
      filteredValues = Object.keys(formStateDescription) as FormStateId[];
    } else if (values.includes('__clear_all__')) {
      filteredValues = [];
    } else {
      filteredValues = values.filter(v => v !== '__select_all__' && v !== '__clear_all__') as FormStateId[];
    }
    
    setSelectedFormStates(filteredValues);
  };

  /**
   * Handles changes to the date range filter
   */
  const handleDateFilterChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      const newDateRange: [dayjs.Dayjs, dayjs.Dayjs] = [dates[0], dates[1]];
      setDateRange(newDateRange);
      const conditions = createFirestoreFilters(newDateRange, entitySelection);
      setFilters(conditions);
    }
  };
  
  /**
   * Handles changes to the view mode
   */
  const handleViewModeChange = (mode: FormViewMode) => {
    setViewMode(mode);
  };
  
  const hasReachedLimit = formVersionDocs?.length === 100;
  const isAllFormsSelected = selectedFormTypes.length === formTypesData.data?.length;
  const isAllStatesSelected = selectedFormStates.length === Object.keys(formStateDescription).length;
  
  /**
   * Initializes filter state from saved preferences or defaults
   */
  useEffect(() => {
    const savedFilters = (settings as any)?.homePageFilters;
    if (savedFilters) {
      if (savedFilters.entitySelection?.entityId) {
        setEntitySelection({
          entityId: savedFilters.entitySelection.entityId,
          groupId: savedFilters.entitySelection.groupId || null,
        });
      }
      
      if (savedFilters.dateRange) {
        setDateRange([
          dayjs(savedFilters.dateRange[0]),
          dayjs(savedFilters.dateRange[1]),
        ]);
      }
      
      if (savedFilters.selectedFormTypes) {
        setSelectedFormTypes(savedFilters.selectedFormTypes);
      }
      
      if (savedFilters.selectedFormStates) {
        setSelectedFormStates(savedFilters.selectedFormStates as FormStateId[]);
      }
      
      if (savedFilters.filterMode) {
        setViewMode(savedFilters.filterMode as FormViewMode);
      }
    } else {
      if (formTypesData.data && !selectedFormTypes.length) {
        const formTypeIds = formTypesData.data.map(formType => formType.docId || '');
        setSelectedFormTypes(formTypeIds);
      }
      
      if (!selectedFormStates.length) {
        const allStates = Object.keys(formStateDescription) as FormStateId[];
        setSelectedFormStates(allStates);
      }
      
      if (!entitySelection) {
        setEntitySelection({
          entityId: 'Projects',
          groupId: null,
        });
      }
    }
    
    const conditions = createFirestoreFilters(dateRange, entitySelection);
    setFilters(conditions);
  }, [formTypesData.data]);
  
  return {
    filters: {
      entitySelection,
      dateRange,
      selectedFormTypes,
      selectedFormStates,
      viewMode,
    },
    
    filterActions: {
      handleEntitySelectionChange,
      handleDateFilterChange,
      handleTypeFilterChange,
      handleStateFilterChange,
      handleViewModeChange,
    },
    
    results: {
      groupedResults,
      isLoading: formVersionsLoading || entityRecordsLoading,
      error: formVersionsError,
      hasReachedLimit,
    },
    
    utils: {
      isAllFormsSelected,
      isAllStatesSelected,
    }
  };
} 