import { FormVersion } from '../../types/System.types';
import { FormStateId } from '../../types/System.Parameters.types';
import { FormViewMode } from '../useFormviews';

/**
 * Filters form versions based on view mode and other criteria
 */
export function formviewsClientSideFilters(
  formVersions: FormVersion[] | undefined,
  userId: string | undefined,
  viewMode: FormViewMode,
  selectedFormTypes: string[],
  selectedFormStates: FormStateId[],
  entitySelection: { entityId: string; groupId: string | null } | null
) {
  if (!formVersions) return { filteredVersions: [], entityRefs: [] };
  
  let filtered = formVersions;
  
  if (viewMode !== 'all' && userId) {
    filtered = formVersions.filter(version => {
      const parentFormData = version.parentFormData;
      if (!parentFormData) return false;
      
      if (viewMode === 'assigned') {
        return parentFormData.assignedTo === userId;
      }
      
      if (viewMode === 'subscribed') {
        return parentFormData.notificationsSubscribers?.includes(userId) ?? false;
      }
      
      return false;
    });
  }
  
  if (selectedFormTypes.length > 0) {
    filtered = filtered.filter(version => 
      version.parentFormType && selectedFormTypes.includes(version.parentFormType)
    );
  }
  
  if (selectedFormStates.length > 0) {
    filtered = filtered.filter(version => 
      selectedFormStates.includes(version.state as FormStateId)
    );
  }
  
  // Extract entity references from filtered versions
  const refs = new Set<string>();
  if (entitySelection?.entityId) {
    filtered.forEach(version => {
      if (version?.entityReferences) {
        const ref = version.entityReferences[entitySelection.entityId];
        if (ref) refs.add(ref);
      }
    });
  }
  
  return {
    filteredVersions: filtered,
    entityRefs: Array.from(refs)
  };
} 