import { useContext } from 'react';
import { FormVersionContext } from '../providers/artifacts/FormVersionProvider';
import { EntityRecordContext } from '../providers/artifacts/EntityRecordProvider';

/**
 * Hook that provides path information for both form and entity contexts
 * Centralizes the logic for generating paths used across the application
 * @returns Object containing paths and context flags
 */
export const useContextPaths = () => {
    const formVersionContext = useContext(FormVersionContext);
    const entityRecordContext = useContext(EntityRecordContext);

    /**
     * Determines if we're in an entity context
     */
    const isInEntityContext = !!entityRecordContext.entityId;
    const isInFormContext = !!formVersionContext.formId;

    /**
     * Compute the form version path
     */
    let formVersionPath = '';
    if (!isInEntityContext && formVersionContext.formId) {
        const formId = formVersionContext.formId;
        const versionId = formVersionContext.selectedFormVersion?.data?.docId;
        
        if (formId && versionId) {
            formVersionPath = `/forms/${formId}/versions/${versionId}`;
        } else if (formId) {
            formVersionPath = `/forms/${formId}`;
        }
    }

    /**
     * Compute the entity record path
     */
    let entityRecordPath = '';
    if (isInEntityContext) {
        const entityId = entityRecordContext.entityId;
        const recordId = entityRecordContext.selectedEntityRecord?.docId;
        
        if (entityId && recordId) {
            entityRecordPath = `/entities/${entityId}/records/${recordId}`;
        } else if (entityId) {
            entityRecordPath = `/entities/${entityId}`;
        }
    }

    return {
        formVersionPath,
        entityRecordPath,
        isInEntityContext,
        isInFormContext,
    };
}; 