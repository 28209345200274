import { FC } from 'react';
import { 
    Table, 
    Button, 
    Popconfirm, 
    Grid, 
    List, 
    Typography,
    theme,
    Collapse 
} from 'antd';
import { 
    DeleteOutlined, 
    EditOutlined, 
    PaperClipOutlined,
    DownOutlined 
} from '@ant-design/icons';
import type { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { EmailRecord } from './emailParser';
import { EmailDetails } from './EmailDetails';
import { TableColumnType } from '../../../../types/System.types';
import FlexBox from '../../../atoms/FlexBox';
import FlexCard from '../../../atoms/FlexCard';
import FlexCol from '../../../atoms/FlexCol';

const { useBreakpoint } = Grid;
const { Text } = Typography;

interface EmailTableProps {
    /** List of emails to display */
    emails: EmailRecord[];
    /** Table column configuration */
    columns: TableColumnType[];
    /** Whether the table is disabled */
    disabled?: boolean;
    /** Callback when an email is edited */
    onEdit?: (email: EmailRecord) => void;
    /** Callback when an email is deleted */
    onDelete?: (email: EmailRecord) => void;
    /** Callback when attachments are changed */
    onAttachmentsChange?: (email: EmailRecord, attachments: string[]) => void;
}

export const EmailTable: FC<EmailTableProps> = ({
    emails,
    columns,
    disabled = false,
    onEdit,
    onDelete,
    onAttachmentsChange
}) => {
    const screens = useBreakpoint();
    const { token } = theme.useToken();
    const isMobile = !screens.lg;

    const getColumns = (): ColumnType<EmailRecord>[] => {
        const baseColumns = columns?.map((col: TableColumnType): ColumnType<EmailRecord> => ({
            ...col,
            render: (text: any, record: EmailRecord) => {
                switch (col.dataIndex) {
                    case 'timestamp':
                        return text ? dayjs(text.toDate()).format('YYYY-MM-DD HH:mm') : null;
                    case 'to':
                        return Array.isArray(text) ? text.join(', ') : text;
                    default:
                        return text;
                }
            }
        })).filter(col => col.dataIndex !== 'hasAttachments') || [];

        if (disabled) {
            return baseColumns;
        }

        return [
            ...baseColumns,
            {
                title: '',
                key: 'actions',
                width: 120,
                render: (_: any, record: EmailRecord) => (
                    <FlexBox justifyEnd gap={5} stretch>
                        {record.hasAttachments && (
                            <PaperClipOutlined style={{ color: token.colorTextSecondary }} />
                        )}
                        <Button
                            type="text"
                            icon={<EditOutlined />}
                            onClick={() => onEdit?.(record)}
                        />
                        <Popconfirm
                            title="Delete this email?"
                            onConfirm={() => onDelete?.(record)}
                        >
                            <Button
                                type="text"
                                danger
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    </FlexBox>
                )
            }
        ];
    };

    const renderValue = (text: any, col: TableColumnType, record: EmailRecord) => {
        switch (col.dataIndex) {
            case 'timestamp':
                return text ? dayjs(text.toDate()).format('YYYY-MM-DD HH:mm') : null;
            case 'to':
                return Array.isArray(text) ? text.join(', ') : text;
            default:
                return text;
        }
    };

    const renderActions = (record: EmailRecord) => (
        <FlexBox justifyEnd gap={5} stretch>
            {record.hasAttachments && (
                <PaperClipOutlined style={{ color: token.colorTextSecondary }} />
            )}
            <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => onEdit?.(record)}
                disabled={disabled}
            />
            <Popconfirm
                title="Delete this email?"
                onConfirm={() => onDelete?.(record)}
            >
                <Button
                    type="text"
                    danger
                    icon={<DeleteOutlined />}
                    disabled={disabled}
                />
            </Popconfirm>
        </FlexBox>
    );

    const renderExpandedContent = (record: EmailRecord) => (
        <EmailDetails
            email={record}
            disabled={disabled}
            onAttachmentsChange={
                onAttachmentsChange 
                    ? (attachments) => onAttachmentsChange(record, attachments)
                    : undefined
            }
        />
    );

    if (isMobile) {
        return (
            <List
                dataSource={emails}
                renderItem={(record) => (
                    <FlexCard 
                        style={{
                            width: '100%',
                            marginBottom: token.marginXS
                        }}
                    >
                        <List.Item style={{ padding: 0 }}>
                            <FlexCol gap={10} style={{ width: '100%' }}>
                                {columns.map((col, index) => (
                                    <FlexBox 
                                        key={index} 
                                        gap={10}
                                        stretch
                                    >
                                        <Text type="secondary">
                                            {col.title}
                                        </Text>
                                        <Text>
                                            {renderValue(record[col.dataIndex as keyof EmailRecord], col, record)}
                                        </Text>
                                    </FlexBox>
                                ))}
                                {!disabled && renderActions(record)}
                            </FlexCol>
                        </List.Item>
                        {(record.body || 
                          record.hasAttachments || 
                          (record.cc && record.cc.length > 0) || 
                          (record.bcc && record.bcc.length > 0)) && (
                            <Collapse
                                ghost
                                style={{ marginTop: token.marginXS }}
                                expandIcon={({ isActive }) => (
                                    <DownOutlined rotate={isActive ? 180 : 0} />
                                )}
                                items={[
                                    {
                                        key: '1',
                                        label: 'Show Details',
                                        children: renderExpandedContent(record),
                                        style: { padding: 0 }
                                    }
                                ]}
                            />
                        )}
                    </FlexCard>
                )}
            />
        );
    }

    return (
        <Table<EmailRecord>
            dataSource={emails}
            columns={getColumns()}
            expandable={{
                expandedRowRender: renderExpandedContent,
                rowExpandable: (record): boolean => 
                    Boolean(
                        record.body || 
                        record.hasAttachments || 
                        (record.cc && record.cc.length > 0) || 
                        (record.bcc && record.bcc.length > 0)
                    )
            }}
            pagination={false}
            style={{
                width: '100%'
            }}
            size="small"
            rowKey="id"
        />
    );
}; 