import { FC, useState } from 'react';
import { 
    Grid, 
    Button, 
    theme 
} from 'antd';
import { DeploymentUnitOutlined } from '@ant-design/icons';

import FlexBox from '../atoms/FlexBox';
import MessagesList from './MessagesList';
import ModelInput from './ModelInput';
import FlexCard from '../atoms/FlexCard';

interface AgentProps {
    position?: 'left' | 'right';
    className?: string;
}

const Agent: FC<AgentProps> = ({ 
    position = 'right',
    className 
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const screens = Grid.useBreakpoint();
    const { token } = theme.useToken();

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const transition = 'transform 0.3s ease';

    return (
        <>
            <Button 
                icon={<DeploymentUnitOutlined />}
                onClick={handleToggle}
                type={isOpen ? 'primary' : 'default'}
                style={{
                    position: 'fixed',
                    bottom: -2,
                    left: 20,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    zIndex: 10000,
                    boxShadow: token.boxShadow,
                }}
            />
            <FlexCard
                alignEnd
                variant='outlined'
                style={{
                    position: 'fixed',
                    [position]: screens.md ? 20 : 20,
                    top: 20,
                    width: screens.md ? 320 : 'calc(100% - 40px)',
                    height: `calc(100vh - 105px)`,
                    transition,
                    transform: isOpen ? 'translateX(0)' : `translateX(${position === 'right' ? '120%' : '-120%'})`,
                    zIndex: token.zIndexPopupBase + 1,
                    boxShadow: token.boxShadow,
                }}
            >
                <MessagesList />
            </FlexCard>
            <FlexBox
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    transition,
                    transform: isOpen ? 'translateY(0)' : 'translateY(200%)',
                    zIndex: token.zIndexPopupBase + 1
                }}
            >
                <ModelInput />
            </FlexBox>
        </>
    );
};

export default Agent; 