import { TableColumnType } from '../../../types/System.types';
import { FormulaContext, FormulaResult } from '../../../use/useFormulaParser';

/**
 * Calculates values for a row based on formulas
 */
export const calculateRow = (
    row: Record<string, any>,
    columns: TableColumnType[],
    evaluateFormula: (formula: string, context: FormulaContext) => FormulaResult
): Record<string, any> => {
    const newRow = { ...row };

    columns.forEach(column => {
        if (column.formula) {
            const context: FormulaContext = { row: newRow };
            const result = evaluateFormula(column.formula, context);
            
            // Only update if result is not null
            if (result !== null) {
                newRow[column.dataIndex] = result;
            }
        }
    });

    return newRow;
};
