import React, { useState } from 'react';
import { Input, Button, Typography, Spin, Alert } from 'antd';

import FlexBox from './atoms/FlexBox';
import FlexCard from './atoms/FlexCard';
import FlexCol from './atoms/FlexCol';
import { callAnalyzeEmail } from '../services/emailAnalysisService';

const { TextArea } = Input;
const { Text } = Typography;

const EmailAnalyzer: React.FC = () => {
  const [emailText, setEmailText] = useState<string>('');
  const [analysisResult, setAnalysisResult] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleAnalyze = async () => {
    setLoading(true);
    setError(null);
    setAnalysisResult(null);
    try {
      const result = await callAnalyzeEmail({ emailText });
      setAnalysisResult(result.parsedAnalysis);
    } catch (err: any) {
      console.error(err);
      setError(err.message || 'Analysis failed');
    }
    setLoading(false);
  };

  return (
    <FlexCol
      stretch
    >
      <Text>Raw Email Input</Text>
      <FlexCol noGrow>
        <TextArea
          rows={15}
          value={emailText}
          onChange={(e) => setEmailText(e.target.value)}
          placeholder="Paste your raw email here..."
        />
        <Button
          type="primary"
          onClick={handleAnalyze}
        >
          Analyze
        </Button>
      </FlexCol>

      <FlexCol stretch>
        <Text>Analysis Result</Text>
        {loading && (
          <FlexBox justifyCenter style={{ minHeight: 200 }}>
            <Spin tip="Analyzing..." />
          </FlexBox>
        )}

        {error && (
          <Alert message={error} type="error" />
        )}

        {!loading && !error && analysisResult && (
          <pre style={{
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
            maxHeight: '500px'
          }}>
            {JSON.stringify(analysisResult, null, 2)}
          </pre>
        )}

        {!loading && !error && !analysisResult && (
          <FlexBox justifyCenter style={{ minHeight: 200 }}>
            <Text type="secondary">
              The analysis result will be displayed here.
            </Text>
          </FlexBox>
        )}
      </FlexCol>
    </FlexCol>
  );
};

export default EmailAnalyzer; 