import { useState, useEffect } from 'react';
import type { UploadFile } from 'antd/es/upload/interface';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../services/startFirestore';
import { useAttachmentUpload } from './attachments/useAttachmentUpload';
import { useAttachmentDownload } from './attachments/useAttachmentDownload';
import { useAttachmentDelete } from './attachments/useAttachmentDelete';

/**
 * Props for the useAttachments hook
 */
interface UseAttachmentUIProps {
    /** Array of attachment IDs */
    value?: string[];
    /** Callback triggered when attachments change */
    onChange?: (value: string[]) => void;
    /** Whether attachment operations are disabled */
    disabled?: boolean;
    /** Storage folder path */
    folder?: string;
    /** Callback triggered on error */
    onError?: (error: Error) => void;
    /** Callback triggered on successful operation */
    onSuccess?: (message: string) => void;
}

/**
 * Hook for managing file attachments with Firebase storage integration.
 * Combines upload, download, and delete operations while maintaining the file list state.
 */
export const useAttachments = ({
    value = [],
    onChange,
    disabled = false,
    folder = 'attachments',
    onError,
    onSuccess
}: UseAttachmentUIProps) => {
    const [fileList, setFileList] = useState<UploadFile[]>(() =>
        (value ?? []).map((url, index) => ({
            uid: `-${index}`,
            name: url.split('/').pop()?.split('?')[0].split('%2F').pop() || '',
            status: 'done',
            url: url,
            type: 'file'
        }))
    );

    const { 
        uploading,
        uploadProgress,
        isOverwriteModalOpen,
        pendingUpload,
        handleOverwriteConfirm,
        handleOverwriteCancel,
        handleBatchUpload,
        handleBatchFiles,
        batchFiles
    } = useAttachmentUpload({ 
        value, 
        onChange, 
        folder,
        onSuccess,
        onError
    });

    const {
        handleDownload
    } = useAttachmentDownload({
        onSuccess,
        onError
    });

    const {
        isDeleteModalOpen,
        pendingDelete,
        handleDeleteConfirm, 
        handleDeleteCancel,
        handleDelete
    } = useAttachmentDelete({ 
        value, 
        onChange,
        onSuccess,
        onError
    });

    /**
     * Fetches attachment metadata from Firestore and updates file list
     */
    useEffect(() => {
        const loadAttachments = async () => {
            try {
                const attachments = await Promise.all(
                    (value ?? []).map(async (attachmentId) => {
                        try {
                            const attachmentRef = doc(firestore, 'attachments', attachmentId);
                            const attachmentDoc = await getDoc(attachmentRef);
                            if (attachmentDoc.exists()) {
                                const data = attachmentDoc.data();
                                return {
                                    uid: attachmentId,
                                    name: data.name,
                                    status: 'done' as const,
                                    url: attachmentId,
                                    type: data.type
                                };
                            }
                            return null;
                        } catch (error) {
                            console.error('Error loading attachment:', error);
                            return null;
                        }
                    })
                );

                setFileList(attachments.filter(Boolean) as UploadFile[]);
            } catch (error) {
                console.error('Error loading attachments:', error);
                onError?.(error instanceof Error ? error : new Error('Failed to load attachments'));
            }
        };

        if (value?.length > 0) {
            loadAttachments();
        } else {
            setFileList([]);
        }
    // DO NOT REMOVE THIS ESLINT-DISABLE, IT IS INTENTIONAL (should contain `value`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    /**
     * Processes batch file uploads when batchFiles state changes
     */
    useEffect(() => {
        if (batchFiles.length > 0) {
            handleBatchUpload(batchFiles, fileList).then(newFiles => {
                if (newFiles) {
                    setFileList(prev => [...prev, ...newFiles]);
                }
            });
            handleBatchFiles([]);
        }
    // DO NOT REMOVE THIS ESLINT-DISABLE, IT IS INTENTIONAL (should contain `batchFiles`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batchFiles]);

    /**
     * Wrapper for handleOverwriteConfirm that updates fileList after confirmation
     */
    const processOverwriteConfirm = async () => {
        const result = await handleOverwriteConfirm(fileList);
        if (result) {
            const { newFiles, removedIds } = result;
            setFileList(prev => [
                ...prev.filter(f => !removedIds.includes(f.uid)),
                ...newFiles
            ]);
        }
    };

    return {
        fileList,
        uploading,
        uploadProgress,
        isOverwriteModalOpen,
        isDeleteModalOpen,
        pendingUpload,
        pendingDelete,
        handleOverwriteConfirm: processOverwriteConfirm,
        handleOverwriteCancel,
        handleDeleteConfirm,
        handleDeleteCancel,
        handleDelete,
        handleDownload,
        handleBatchFiles,
        disabled
    };
}; 