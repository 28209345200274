import { useState } from 'react';
import { attachmentStorageService } from './attachmentStorageService';
import { attachmentDbService } from './attachmentDbService';

/**
 * Information about a file pending deletion
 */
interface PendingDelete {
    /** Attachment document ID */
    fileUid: string;
    /** Name of the file to be deleted */
    fileName: string;
}

/**
 * Props for the useAttachmentDelete hook
 */
interface UseAttachmentDeleteProps {
    /** Array of attachment IDs */
    value?: string[];
    /** Callback triggered when attachments change */
    onChange?: (value: string[]) => void;
    /** Callback triggered on error */
    onError?: (error: Error) => void;
    /** Callback triggered on successful operation */
    onSuccess?: (message: string) => void;
}

/**
 * Hook for managing attachment deletion operations from Firebase Storage and Firestore
 */
export const useAttachmentDelete = ({
    value = [],
    onChange,
    onError,
    onSuccess
}: UseAttachmentDeleteProps) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [pendingDelete, setPendingDelete] = useState<PendingDelete | null>(null);

    /**
     * Handles confirmation of file deletion
     * @returns Deleted file ID or null if deletion failed
     */
    const handleDeleteConfirm = async () => {
        if (!pendingDelete) return;

        try {
            const { fileUid } = pendingDelete;
            const attachmentData = await attachmentDbService.getAttachmentDoc(fileUid);

            if (attachmentData.storagePath) {
                await attachmentStorageService.deleteFromStorage(attachmentData.storagePath);
            } else {
                console.warn('No storage path found for attachment:', fileUid);
            }

            await attachmentDbService.deleteAttachmentDoc(fileUid);

            const newUids = value.filter(uid => uid !== fileUid);
            onChange?.(newUids);

            onSuccess?.('File deleted successfully');
            return fileUid;
        } catch (error) {
            console.error('Delete error:', error);
            onError?.(error instanceof Error ? error : new Error('Delete failed'));
            return null;
        } finally {
            setIsDeleteModalOpen(false);
            setPendingDelete(null);
        }
    };

    /**
     * Handles cancellation of file deletion
     */
    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
        setPendingDelete(null);
    };

    /**
     * Initiates file deletion process
     * @param fileUid Attachment document ID
     * @param fileName Name of the file to be deleted
     */
    const handleDelete = async (fileUid: string, fileName: string) => {
        setPendingDelete({ fileUid, fileName });
        setIsDeleteModalOpen(true);
    };

    return {
        isDeleteModalOpen,
        pendingDelete,
        handleDeleteConfirm,
        handleDeleteCancel,
        handleDelete
    };
}; 